/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */

.list {
  --s-row-gap: var(--gap-row-small);
  --m-row-gap: var(--gap-row-medium);
  --l-row-gap: var(--gap-row-large);

  @nest html[data-theme='lightning'] & {
    --s-row-gap: var(--offset-medium);
    --m-row-gap: var(--offset-large);
    --l-row-gap: var(--offset-x-large);
  }
}

.listSideBySide {
  composes: list;
  display: grid;
  align-items: baseline;

  > .row {
    display: contents; /* Row doesn't exists for style in grid version */

    > .key {
      text-align: right;
    }

    > .keyWithPadding {
      padding-left: var(--attribute-list-key-padding, var(--0px));
    }

    > .value,
    > .hint {
      padding-left: var(--attribute-list-column-gap, var(--20px));
    }

    > .hintWithIcon {
      padding-left: var(--7px);
    }
  }

  @nest html[data-theme='lightning'] & {
    > .row {
      > .key {
        text-align: left;
      }

      > .keyWithPadding {
        padding-left: var(--0px);
      }
    }
  }
}

.divider {
  border-top: 1px solid var(--blue-gray-100);
}

.title {
  composes: bold from 'utils.css';
  font-size: var(--16px);
  color: var(--blue-gray-400);

  @nest html[data-theme='lightning'] & {
    font-size: var(--15px);
    line-height: var(--18px);
    color: var(--lightning--blue-800);
    text-transform: uppercase;
    letter-spacing: 0.03em;
  }
}

.extraTableRowTitle {
  /* On legacy table title is wrapped into <tr><td> to be able to use colspan, in that case reset title's margin. */
  /* In case of small stacked view margin will be added back to title according to extraRow position. */
  /* In case of sideBySide view title should not have margin, */
  /* because border-spacing adds gap before first line also, so we just need to add extra 10px if it's not first */
  .title,
  .title:not(:first-child) {
    margin-top: 0;
  }
}

.key {
  composes: bold from 'utils.css';
  color: var(--gray-700);
  word-break: break-word; /* To make .key column collapsable down to keyColumnWidth */
  overflow-wrap: break-word;

  @nest html[data-theme='lightning'] & {
    line-height: var(--16px);
    color: var(--lightning--blue-gray-700);
  }
}

.value,
.hint {
  min-width: 0; /* https://css-tricks.com/flexbox-truncated-text */
  color: var(--gray-800);
  word-break: break-word;
  overflow-wrap: break-word;

  @nest html[data-theme='lightning'] & {
    font-size: var(--13px);
    font-weight: var(--fontWeight);
    line-height: var(--16px);
    color: var(--lightning--blue-gray-900);
  }
}

.secondary {
  composes: light from 'utils.css';
  font-size: var(--13px);
  color: var(--gray-550);

  @nest html[data-theme='lightning'] & {
    padding-top: var(--4px);
  }
}

.icon {
  padding-left: var(--5px);
}

.sizeXS {
  composes: list;
  composes: gap from 'utils.css'; /* Make wrapped attribute list inherit parent container gap size */

  > .row {
    display: grid;
    grid-template-areas:
      'key icon'
      'value value'
      'hint hint';

    > .key {
      grid-area: key;
    }

    > .icon {
      display: flex;
      grid-area: icon;
      justify-content: flex-end;
      align-items: center;
    }

    > .value {
      grid-area: value;
    }

    > .hint {
      grid-area: hint;
    }
  }
}

.sizeS {
  composes: listSideBySide;
  grid-row-gap: var(--s-row-gap);

  > .row {
    --attribute-list-column-gap: var(--20px);
  }
}

.sizeM {
  composes: listSideBySide;
  grid-row-gap: var(--m-row-gap);

  > .row {
    --attribute-list-key-padding: var(--5px);
    --attribute-list-column-gap: var(--20px);
  }
}

.sizeL {
  composes: listSideBySide;
  grid-row-gap: var(--l-row-gap);

  > .row {
    --attribute-list-key-padding: var(--10px);
    --attribute-list-column-gap: var(--22px);
  }
}
