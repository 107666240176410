/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

@keyframes backgroundMove {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: var(--50px) var(--50px);
  }
}

.barFill {
  border-radius: var(--2px);
  background: var(--blue-600)
    linear-gradient(
      -45deg,
      rgb(var(--white-rgb) / 0.2) 25%,
      transparent 25%,
      transparent 50%,
      rgb(var(--white-rgb) / 0.2) 50%,
      rgb(var(--white-rgb) / 0.2) 75%,
      transparent 75%,
      transparent
    )
    0 0 / var(--50px) var(--50px);
  animation: backgroundMove 2s linear infinite;
}

.progressBar {
  display: flex;
  flex: 1 1 auto;
  max-width: calc(6 * var(--100px));
  border-radius: var(--2px);
  box-shadow: inset 0 0 var(--1px) rgb(var(--black-rgb) / 0.3);
  background: var(--gray-50);
}

.xSmall {
  composes: progressBar;
  height: var(--5px);
  border-radius: unset;
  .barFill {
    border-radius: unset;
    background-size: var(--20px) var(--20px);
    animation: backgroundMove 3s linear infinite;
  }
}

.small {
  composes: progressBar;
  height: var(--8px);

  .barFill {
    background-size: var(--20px) var(--20px);
    animation: backgroundMove 1.5s linear infinite;
  }
}

.medium {
  composes: progressBar;
  height: var(--10px);
}

.large {
  composes: progressBar;
  height: var(--12px);
}

.xLarge {
  composes: progressBar;
  height: var(--14px);
}
