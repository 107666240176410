/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.reminder {
  width: calc(4 * var(--100px));
}

.withCounter {
  width: calc(3 * var(--100px));
}

.counter {
  background: var(--blue-gray-500);
}

.body,
.title,
.counter {
  font-size: var(--14px);
}

.withCounter {
  flex-direction: column;
}

.message {
  display: flex;
  font-size: var(--20px);
  font-weight: var(--fontWeightBold);
}

.title,
.message {
  margin-bottom: var(--5px);
}

.completed {
  margin-right: var(--20px);
  color: var(--green-700);
}

.pending {
  color: var(--orange-700);
}
