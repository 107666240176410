/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
:root {
  --input-placeholder-color: var(--gray-550);
  --selector-items-padding-h: var(--3px);
  --searchBar-border-width: var(--1px);
  --search-bar-container-padding-h: var(--12px);

  @nest html[data-theme='lightning'] & {
    --input-placeholder-color: var(--lightning--blue-gray-500);
  }
}

.standard.styled:not(.disabled) {
  --button-color-no-fill: var(--searchBar-button-color);
  --button-color-no-fill-hover: var(--searchBar-button-color-hover);
  --button-color-no-fill-active: var(--searchBar-button-color-active);

  @nest html[data-theme='lightning'] & {
    --button-color-no-fill: var(--searchBar-button-color);
    --button-color-no-fill-hover: var(--searchBar-button-color-hover);
    --button-color-no-fill-active: var(--searchBar-button-color-active);
  }
}

.small {
  --button-min-height: var(--optionSelector-line-height);

  @nest html[data-theme='lightning'] & {
    --button-min-height: var(--optionSelector-line-height);
  }
}

.formatOption {
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
}

.hintTextStyle {
  --hintText-font-style: italic;
  --hintText-color: var(--gray-500);

  composes: light from 'utils.css';
  min-width: var(--90px);
  padding: 0 var(--5px);
  font-style: var(--hintText-font-style);
  color: var(--hintText-color);
  text-align: right;

  @nest html[data-theme='lightning'] & {
    --hintText-font-style: normal;
    --hintText-color: var(--lightning--blue-gray-600);
  }
}

.categoryInfo {
  composes: hintTextStyle;
  margin-block-start: auto;
  padding: var(--offset-small);
  text-align: left;
}

.selectorContainer {
  composes: gapXSmall gap from 'utils.css';
  position: relative;
  display: flex;
  flex-direction: column;
}

.label {
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-right: var(--4px);
  cursor: pointer;
}

.selector {
  composes: gapSmall gapHorizontal from 'utils.css';
  flex-grow: 1;
  align-items: start;
  height: 100%;
}

.selectorAlignedIcon-icon {
  composes: selectorAligned from 'components/Form/OptionSelector/OptionSelector.css';
  font-size: 1.2em;
}

.searchBarContainer {
  /* Search bar */
  --searchBar-border-color: var(--gray-100);
  --searchBar-border-color-disabled: var(--gray-100);
  --searchBar-border-color-focused: var(--blue-700);
  --searchBar-border-color-error: var(--form-error-border-color);
  --searchBar-border-color-warning: var(--yellow-100);
  --searchBar-background-color: var(--white);
  --searchBar-background-color-focused: var(--white);
  --searchBar-background-color-disabled: var(--blue-gray-50);
  /* Input */
  --input-suggestion-color: var(--gray-400);
  --searchBar-button-color: var(--gray-400);

  position: relative;
  display: flex;
  flex: 1 1 auto;
  min-height: calc(var(--2px) + 2 * var(--optionSelector-vertical-padding) + var(--optionSelector-line-height));
  padding: var(--optionSelector-vertical-padding) var(--search-bar-container-padding-h);

  &:focus-within:not(.error, .warning, .expander) {
    --searchBar-border-color: var(--searchBar-border-color-focused);
    --searchBar-background-color: var(--searchBar-background-color-focused);
  }

  &:focus-within {
    --searchBar-border-color: var(--lightning--blue-200);

    .inputPanel {
      max-height: var(--optionSelector-line-height);
    }
  }

  &.error {
    --searchBar-border-color: var(--searchBar-border-color-error);

    @nest html[data-theme='lightning'] & {
      --searchBar-border-color: var(--lightning--red-500);
      --searchBar-button-color: var(--lightning--red-500);
      --searchBar-button-color-hover: var(--lightning--red-400);
      --searchBar-button-color-active: var(--lightning--red-600);
    }
  }

  &.warning {
    --searchBar-border-color: var(--searchBar-border-color-warning);

    @nest html[data-theme='lightning'] & {
      --searchBar-border-color: var(--lightning--orange-500);
      --searchBar-button-color: var(--lightning--orange-500);
      --searchBar-button-color-hover: var(--lightning--orange-400);
      --searchBar-button-color-active: var(--lightning--orange-600);
    }
  }

  &.disabled {
    --searchBar-background-color: var(--searchBar-background-color-disabled);
    --searchBar-button-color: var(--lightning--blue-gray-300);
  }

  @nest html[data-theme='lightning'] & {
    --search-bar-container-padding-h: var(--12px);
    --searchBar-border-color: var(--lightning--blue-gray-400);
    --searchBar-border-color-disabled: var(--lightning--blue-gray-200);
    --searchBar-border-color-focused: var(--lightning--blue-500);
    --searchBar-border-color-error: var(--lightning--red-500);
    --searchBar-border-color-warning: var(--lightning--orange-500);
    --searchBar-background-color-disabled: var(--lightning--gray-25);
    --searchBar-button-color: var(--lightning--blue-gray-500);
    --searchBar-button-color-hover: var(--lightning--blue-gray-400);
    --searchBar-button-color-active: var(--lightning--blue-gray-600);
  }
}

.searchBar {
  position: absolute;
  inset: calc(-1 * var(--10px)) 0 0 0;
  border: var(--searchBar-border-width) solid var(--searchBar-border-color);
  background-color: var(--searchBar-background-color);

  &.focused {
    border: var(--1px) solid var(--searchBar-border-color-focused);
  }

  @nest html[data-theme='lightning'] & {
    border-radius: var(--8px);
  }
}

.legend {
  display: block;
  visibility: hidden;
  width: auto;
  max-width: 0;
  height: var(--20px);
  padding: 0;
  font-weight: var(--fontWeightBold);
  -webkit-font-smoothing: antialiased;
  line-height: 1;
  color: var(--searchBar-legend-color, var(--searchBar-border-color-focused));
  transition: margin-left 0.2s linear;
  mix-blend-mode: darken;

  &.show {
    visibility: visible;
    max-width: unset;
  }

  &.expander {
    visibility: hidden;
  }

  > span {
    display: inline-block;
    padding: 0 var(--2px);
  }

  @nest html[data-theme='lightning'] & {
    --searchBar-legend-color: var(--lightning--blue-600);

    margin-left: calc(var(--search-bar-container-padding-h) + var(--selector-items-padding-h));
  }
}

.searchBar:not(.focused) .legend.title {
  color: var(--text-color);
}

.icon {
  --button-icon-size: 0.9em;

  @nest html[data-theme='lightning'] & {
    --button-icon-size: 0.8em;
  }
}

.items {
  composes: gapXSmall gapHorizontalWrap from 'utils.css';
  position: relative;
  flex: 1 1 auto;
  padding: 0 var(--3px);

  &.expander {
    flex-flow: row-reverse wrap-reverse;
  }
}

.inputPanel {
  composes: gapXSmall gapHorizontal from 'utils.css';
  position: relative;
  flex: 1 1 auto;
  max-height: 0;
  text-overflow: ellipsis;
  overflow: hidden;

  &.show {
    max-height: var(--optionSelector-line-height);
  }
}

.input {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: var(--optionSelector-line-height);
  color: var(--text-color);
  outline: none;
  border: none;
  background-color: transparent;
}

input::placeholder {
  font-weight: var(--fontWeightLight);
  color: var(--input-placeholder-color);
  user-select: none;
}

.suggestion {
  composes: input;
  position: absolute;
  color: var(--input-suggestion-color);
}

.highlightedText {
  composes: bold from 'utils.css';
  color: var(--blue-700);
}

.valuePanel {
  --valuePanel-maxHeight: 100vh;

  max-height: min(var(--valuePanel-maxHeight), calc(30vh - var(--header-height)));
  padding: var(--1px);
  overflow-y: auto;
  overscroll-behavior-y: auto;
}

.valuePanel,
.selectedResource {
  composes: gapXSmall gapAlignBaseline gapHorizontalWrap from 'utils.css';
  position: relative;
  max-width: 100%;

  > * {
    max-width: 100%;
  }
}

/* keep selectedValueText in case we need to compose different style from page */
.selectedValueText {
  word-break: break-all;

  &.clickable {
    cursor: pointer;
  }
}

.selectedValueError {
  --option-background-color-error: var(--red-50);
  --option-text-color-error: var(--red-800);

  color: var(--option-text-color-error);
  background-color: var(--option-background-color-error);
}

.joiner {
  align-self: flex-start;
  color: var(--gray-400);
  word-break: normal;
  user-select: none;

  @nest html[data-theme='lightning'] & {
    color: var(--lightning--blue-gray-600);
  }
}

.pillDisabled-fill {
  .pillDisabled-pill.pillDisabled-disabled {
    .pillDisabled-content {
      text-decoration: none;
      border-color: var(--gray-900);
    }
  }
}

.joinerPill-pill {
  .joinerPill-text {
    --pill-text-horizontal-padding: var(--1px);
  }
  .joinerPill-content {
    padding: 0;
  }
}

.joinerPill-pill.joinerPill-disabled {
  &.joinerPill-fill {
    .joinerPill-content {
      text-decoration: none;
      border-color: var(--gray-100);
    }
  }
}

/* useful when value inside joiner pill is a pill */
.joinerValuePill-pill,
.joinerValuePill-pill.joinerValuePill-disabled {
  &.joinerValuePill-fill {
    .joinerValuePill-content {
      text-decoration: none;
    }
  }

  .joinerValuePill-content {
    outline: none;
    border: none;
  }
}

.close-icon {
  display: inline-block;
  align-self: flex-start;
  transform: scale(0.6);
}

.clickable {
  .close-icon {
    cursor: pointer;

    &:hover {
      color: var(--blue-gray-900);
    }
  }
}

/* Dropdown */
.dropdown-tooltip .dropdown-content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: inherit;
  max-height: inherit;
  padding: 0;

  .searchBarContainer {
    flex: 0 0 auto;
  }

  @nest html[data-theme='lightning'] & {
    padding: 0;
  }
}

.dropdown-top {
  .dropdown-content {
    flex-direction: column-reverse;
  }
}

.drawer-text {
  composes: normal from 'utils.css';
  font-size: var(--14px);
}

.drawer-icon {
  font-size: 0.7em;
}

.infoPanel {
  position: sticky;
  z-index: var(--shadow-depth-3z);
  top: 0;
  padding: var(--offset-xsmall);
  background-color: var(--white);
}

.infoPanelContent {
  padding: var(--5px);
}

.dropdown-bottom {
  margin-top: calc(-1 * var(--offset-xsmall));
}

.dropdown {
  --dropdown-grid-template: 'categoryPanel optionPanel' 1fr 'footer footer' minmax(25px, min-content) / 180px
    minmax(180px, auto);

  display: grid;
  flex: 1 1 100%;
  grid-template: var(--dropdown-grid-template);
  width: 100%;
  list-style: none;
  overflow-y: auto;
  pointer-events: auto;
  box-shadow: var(--shadow-depth-4);

  &.noCategoryPanel {
    grid-template:
      'optionPanel' auto
      'footer' minmax(25px, min-content);

    &.noFooter {
      grid-template: 'optionPanel' auto;
    }

    .optionPanel {
      max-width: 96vw;
    }
  }

  @nest html[data-theme='lightning'] & {
    border-radius: var(--8px);
  }
}

.categoryPanel {
  --categoryPanel-background-color: var(--blue-gray-50);
  --categoryPanel-background-color-active: var(--white);

  grid-area: categoryPanel;
  flex: 1 1 auto;
  flex-direction: column;
  padding: var(--20px) 0;
  overflow-y: auto;
  user-select: none;
  background-color: var(--categoryPanel-background-color);

  &.hide {
    display: none;
  }

  .optionPanel {
    background-color: var(--categoryPanel-background-color);

    @nest html[data-theme='lightning'] & {
      padding: 0;
    }
  }

  .highlighted {
    @nest html[data-theme='lightning'] & {
      --option-background-color-highlighted: var(--lightning--blue-gray-25);

      margin-right: var(--offset-medium);
      padding-left: var(--8px);
      border: var(--2px) solid;
      border-radius: 0 var(--8px) var(--8px) 0;
      border-top: 0;
      border-right: 0;
      border-bottom: 0;
      border-image: linear-gradient(rgba(0 221 251 /0.15), rgba(0 178 255 /0.15)) 1;
    }
  }

  .infoPanel {
    background-color: var(--categoryPanel-background-color);
  }

  .resources {
    grid-template: unset;
  }

  @nest html[data-theme='lightning'] & {
    --categoryPanel-background-color: var(--lightning--blue-gray-50);
  }
}

.divider {
  border-top: var(--1px) solid var(--blue-gray-200);

  &.shadowUp {
    box-shadow: var(--shadow-depth-2onlyUp);
  }

  &.shadowDown {
    box-shadow: var(--shadow-depth-2onlyDown);
  }

  &.margin {
    margin: var(--offset-xsmall);
  }
}

.option {
  --option-text-color-active: var(--blue-700);
  --option-background-color-highlighted: var(--blue-100);
  --option-text-color-highlighted: var(--blue-900);
  --selector-background-color-disabled: var(--blue-gray-50);

  composes: ellipsisLines from 'utils.css';
  padding: var(--5px) var(--5px) var(--5px) var(--10px);
  outline: none;

  &.disabled {
    background-color: var(--selector-background-color-disabled);
  }

  &.active {
    color: var(--option-text-color-active);
    background-color: var(--white);
    /* Use stroke-width instead of bold font-weight to prevent active category name from resizing */
    -webkit-text-stroke-width: var(--1px);

    @nest html[data-theme='lightning'] & {
      margin-right: var(--offset-medium);
      padding-left: var(--8px);
      border: var(--2px) solid;
      border-radius: 0 var(--8px) var(--8px) 0;
      border-top: 0;
      border-right: 0;
      border-bottom: 0;
      border-image: linear-gradient(rgba(0 198 251 /1), rgba(0 91 234 /1)) 1;
    }
  }

  &.clickable {
    cursor: pointer;
  }

  &.highlighted {
    color: var(--option-text-color-highlighted);
    background-color: var(--option-background-color-highlighted);

    @nest html[data-theme='lightning'] & {
      --option-background-color-highlighted: var(--lightning--blue-50);
      --option-text-color-highlighted: var(--lightning--blue-900);

      border-radius: var(--4px);
    }
  }
}

.option-checkbox {
  align-items: center;
}

.optionPanel {
  display: flex;
  grid-area: optionPanel;
  flex-direction: column;
  max-width: calc(96vw - 180px);
  overflow-y: auto;

  @nest html[data-theme='lightning'] & {
    padding: var(--offset-medium);
  }
}

.resources {
  --resources-template-wide-screen: auto / repeat(auto-fit, minmax(180px, 1fr));
  --resources-template-small-screen: auto / minmax(180px, 1fr);

  display: grid;
  grid-template: var(--resources-template-wide-screen);
  max-width: inherit;
  height: 100%;
  overflow-y: auto;

  > * {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    &:empty {
      display: none;
    }
  }

  @media (width <= 600px) {
    grid-template: var(--resources-template-small-screen);
  }
}

.stickyResources {
  overflow-y: scroll;

  &.paddingBottom {
    padding-bottom: var(--offset-xsmall);
  }

  &.paddingTop {
    padding-top: var(--offset-xsmall);
  }
}

.listResource {
  overflow-y: auto;
  overscroll-behavior-y: none;
}

.resourceSubtitle {
  composes: light from 'utils.css';
  margin: var(--5px);
  font-style: italic;
}

.containerResource {
  composes: paddingSmallRight paddingSmallLeft from 'utils.css';
  height: 100%;
}

.createOption {
  composes: gapXSmall gapHorizontal from 'utils.css';
}

.createOption-icon {
  color: var(--blue-800);
  transform: scale(0.7);
}

.partialOption {
  font-style: italic;
}

.footer {
  --footer-background-color: var(--gray-100);

  display: flex;
  grid-area: footer;
  background-color: var(--footer-background-color);

  @nest html[data-theme='lightning'] & {
    --footer-background-color: unset;

    border-top: var(--1px) solid var(--lightning--blue-gray-300);
  }
}

.footerContent {
  composes: gapSmall gapHorizontalWrap paddingSmallRight paddingSmallLeft from 'utils.css';
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
  &.advancedEnabled {
    justify-content: space-between;
  }
}

.shortcut {
  @nest html[data-theme='lightning'] & {
    padding: var(--2px) var(--4px);
    border-radius: var(--2px);
    background-color: var(--lightning--blue-gray-50);
  }
}

.shortcutText {
  composes: semiBold from 'utils.css';
  line-height: 1.8;

  @nest html[data-theme='lightning'] & {
    color: var(--lightning--blue-gray-800);
  }
}

.shortcut-icon {
  @nest html[data-theme='lightning'] & {
    color: var(--lightning--blue-gray-800);
  }
}

.advancedOptions-labelText {
  padding-right: 0;
}

.filteringTips {
  composes: bold gapXSmall gapHorizontalWrap from 'utils.css';
  color: var(--blue-800);
}

.highlightTextUnderline {
  composes: highlightedText;
  text-decoration: underline;
}
/* Loading skeleton */
.loadingLine {
  flex: 1 0 auto;
  height: var(--18px);
  margin: var(--10px) var(--42px) var(--offset-x-large) var(--offset-small);
  opacity: 0.6;
  border-radius: var(--10px);
  background: var(--blue-gray-200);
}

.loadingLineLong {
  composes: loadingLine;
  margin-right: var(--10px);
}

.emptyMessage-centerer .emptyMessage-main {
  width: 100%;
  min-width: unset;
  margin: var(--20px) 0;

  @nest html[data-theme='lightning'] & {
    margin: 0;
  }
}

.emptyMessage-banner {
  --banner-width-max: 100%;

  margin: var(--10px);
}

.emptyMessage-plain {
  .emptyMessage-core {
    min-height: var(--60px);
    font-size: var(--16px);
  }
}

.key {
  composes: paddingSmallLeft from 'utils.css';
}

.showAll {
  composes: centerFlexContent from 'utils.css';
  composes: link from 'components/Link/Link.css';
  cursor: pointer;
}
