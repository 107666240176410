/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.summary {
  display: flex;
  width: 100%;
  padding: var(--10px);
  border: 1px solid var(--yellow-100);
  background: var(--yellow-50);
}

.item {
  padding-left: var(--10px);
}

.item {
  display: flex;
}

.itemLabel {
  min-width: var(--80px);
}

.itemValue {
  padding-left: var(--10px);
}
