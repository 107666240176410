/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */

.infoCard {
  overflow-y: hidden;
}

.card {
  color: var(--gray-800);
  border: 1px solid var(--blue-gray-100);
  border-left: 3px solid var(--blue-400);
  background-color: var(--blue-gray-50);

  @nest html[data-theme='lightning'] & {
    position: relative;
    border: 1px solid var(--lightning--blue-gray-100);
    border-radius: var(--6px);
    background-color: var(--lightning--blue-gray-25);

    &::before {
      position: absolute;
      content: '';
      bottom: calc(-1 * var(--1px));
      left: calc(-1 * var(--1px));
      width: var(--4px);
      height: calc(100% + var(--2px));
      border-top-left-radius: var(--6px);
      border-bottom-left-radius: var(--6px);
      background-color: var(--lightning--blue-500);
    }
  }
}

.title {
  composes: bold from 'utils.css';
  font-size: var(--16px);
  color: var(--blue-gray-400);
}

.message {
  font-size: var(--13px);
}

.header {
  composes: bold from 'utils.css';
  font-size: var(--14px);
}

.helpIcon-icon {
  cursor: pointer;
  user-select: none;
}

.helpIconInput-icon {
  composes: helpIcon-icon;
  composes: inputAligned from 'components/Form/Input/Input.css';
}

.helpIconSelector-icon {
  composes: helpIcon-icon;
  composes: selectorAligned from 'components/Form/OptionSelector/OptionSelector.css';
}
