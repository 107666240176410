/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
.footer {
  composes: fixedHeight from './Modal.css';
  display: flex;
  flex-direction: column;
  min-height: var(--51px); /* 50px + 1px border */
  padding: 0 var(--offset-medium) 0 var(--modal-content-h-padding);
  overflow: unset;
  border-top: 1px solid var(--modal-separator-color);

  @nest html[data-theme='lightning'] & {
    padding: var(--16px) var(--32px);
  }
}

.actions {
  composes: gapMedium gapHorizontalWrap from 'utils.css';
  flex: 1 1 auto;
  justify-content: flex-end; /* Stick buttons to right side */
  align-items: center;
}
