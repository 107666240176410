/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

.installed {
  color: var(--green-700);
}

.missing {
  color: var(--red-700);
}
