/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */

.section {
  --typed-message-icon-padding: var(--10px);
  --typed-message-color: inherit;

  display: flex;
  align-items: flex-start;

  @nest html[data-theme='lightning'] & {
    --typed-message-icon-padding: var(--12px);
  }
}

.icon {
  flex: 0 0 auto;
  padding-right: var(--typed-message-icon-padding);
  color: var(--typed-message-color);

  @nest html[data-theme='lightning'] & {
    font-size: var(--20px);
  }
}

.offsetIconPadding {
  padding-left: calc(1em + var(--10px));
}

.message {
  flex: 1 1 auto;
  color: var(--typed-message-color);
  overflow-wrap: break-word;

  @nest html[data-theme='lightning'] & {
    font-weight: var(--fontWeightSemiBold);
  }
}

.info {
  --typed-message-color: var(--blue-600);
}

.success {
  --typed-message-color: var(--green-700);

  @nest html[data-theme='lightning'] & {
    --typed-message-color: var(--lightning--green-600);
  }
}

.warning {
  --typed-message-color: var(--yellow-800);

  @nest html[data-theme='lightning'] & {
    --typed-message-color: var(--lightning--orange-500);
  }
}

.error {
  --typed-message-color: var(--red-700);

  @nest html[data-theme='lightning'] & {
    --typed-message-color: var(--lightning--red-500);
  }
}

.gray {
  --typed-message-color: var(--gray-550);
}
