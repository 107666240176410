/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
.before {
  margin-right: 0;
}

.warning {
  color: var(--orange-800);
}

.exclusion {
  color: var(--purple-500);
}

.duplicate {
  color: var(--yellow-800);
}

.total {
  color: var(--gray-800);
}

.error {
  color: var(--red-700);
}

.main {
  font-size: var(--form-input-text);
}
