/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */

/* We need will-change on three moving parts of menu: dropdown, container and items list to
   1. Prapare for animation, creating composition layer in advancced
   2. To reduce number of paint counts, which you can track on devtools layer panel:
      https://googlechrome.github.io/samples/css-will-change-transform-rasterization/index.html */

:root {
  --menu-list-padding-v: var(--6px);
  --menu-list-padding-h: var(--0px);
  --menu-item-margin-v: var(--0px);
  --menu-item-margin-h: var(--6px);
  --menu-item-padding-v: var(--0px);
  --menu-item-padding-h: var(--10px);
  --menu-item-content-padding-v: var(--6px);
  --menu-item-content-padding-h: var(--0px);
  --menu-item-border-radius: var(--offset-xsmall);
  --menu-item-focus-background-color: var(--gray-100);
  --menu-info-padding-v: var(--6px);
  --menu-info-padding-h: var(--10px);
  --menu-dropdown-margin-top: var(--3px);
  --menu-dropdown-color: var(--text-color);
  --menu-dropdown-radius: var(--5px);
  --menu-dropdown-border: var(--1px) solid var(--gray-200);
  --menu-dropdown-backdrop: none;
  --menu-dropdown-background: var(--white);
  --menu-dropdown-shadow-up: 0 0 var(--6px) var(--1px) rgb(var(--gray-400-rgb) / 0.7);
  --menu-dropdown-shadow: 0 var(--2px) var(--6px) var(--1px) rgb(var(--gray-400-rgb) / 0.7);
  --menu-dropdown-arrow-left: auto;
}

.menu {
  position: relative;
  display: inline-block;
  user-select: none;
}

.trigger {
  cursor: pointer;
  border: none;
}

.openedTrigger {
  pointer-events: auto;
} /* Trigger style when menu is opened */

.triggerLabel {
  display: inline-block;
}

.dropdown {
  position: absolute;
  z-index: var(--shadow-above-all-z);
  top: 100%;
  left: auto;
  width: auto;
  height: auto;
  margin-top: var(--menu-dropdown-margin-top);
  color: var(--menu-dropdown-color);
  opacity: 0;
  pointer-events: none;
  border: var(--menu-dropdown-border);
  border-radius: var(--menu-dropdown-radius);
  box-shadow: var(--menu-dropdown-shadow);
  background: var(--menu-dropdown-background);
  backdrop-filter: var(--menu-dropdown-backdrop);
  transform: translate3d(0, 0, 0);
  will-change: transform, opacity;

  &.dropdownUp {
    box-shadow: var(--menu-dropdown-shadow-up);
  }

  @nest html[data-theme='lightning'] & {
    --menu-dropdown-background: linear-gradient(
      90.4deg,
      rgb(var(--white-rgb) / 0.9) 0.96%,
      rgb(var(--white-rgb) / 0.8) 99.91%
    );
    --menu-dropdown-backdrop: blur(var(--offset-xsmall));
    --menu-dropdown-border: none;
    --menu-dropdown-radius: var(--offset-small);
    --menu-dropdown-margin-top: var(--offset-small);
    --menu-dropdown-shadow: var(--1px) var(--2px) var(--8px) rgb(var(--lightning--blue-gray-700-rgb) / 0.4);
  }
}

.dropdownActive {
  pointer-events: auto;
}

.subDropdown {
  top: 0;
  left: 99%;
  margin-top: calc(-1 * var(--menu-list-padding-v));
}

.dropdownWithArrow {
  margin-top: var(--7px);

  &::before {
    position: absolute;
    display: block;
    content: '';
    top: -7px; /* stylelint-disable-line declaration-property-unit-disallowed-list */
    right: auto;
    left: var(--menu-dropdown-arrow-left);
    width: 0;
    height: 0;
    border-right: 7px solid transparent;
    border-bottom: 7px solid var(--menu-dropdown-background);
    border-left: 7px solid transparent;
  }

  /* Overlay above arrow pseudo to prevent triggering mouseleave event when user moves mouse from trigger to dropdown */
  &::after {
    position: absolute;
    display: block;
    content: '';
    inset: -7px 0 0;
  }
}

.itemsContainer {
  position: relative;
  margin: 0;
  overflow: hidden;
  will-change: width, height;
}

/* Parent should have equal or more width than absolute child to alow that child allocate own max-width */
.itemsExtender {
  position: relative;
  width: 100vw;
  pointer-events: none;
}

.itemsList {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: auto;
  height: auto;
  margin: 0;
  padding: var(--menu-list-padding-v) var(--menu-list-padding-h);
  list-style: none;
  opacity: 0;
  pointer-events: none;
  transform: translate3d(0, 0, 0);
  will-change: transform, opacity;
}

.itemsListActive {
  z-index: 2;
  pointer-events: auto;
}

.delimiter {
  margin: var(--menu-list-padding-v) 0;
  cursor: pointer; /* to prevent cursor blinking between elements */
  border-top: 1px solid var(--gray-200);
}

/* If delimiter is the first child of dropdown (or right after focuser), collapse dropdown's top padding */
.delimiter:first-child,
.focuser + .delimiter {
  margin-top: calc(-1 * var(--menu-list-padding-v));
}

.item {
  composes: centeredFlex gapLarge gapHorizontal from 'utils.css';
  position: relative;
  margin: var(--menu-item-margin-v) var(--menu-item-margin-h);
  padding: var(--menu-item-padding-v) var(--menu-item-padding-h);
  color: var(--text-color);
  border-radius: var(--menu-item-border-radius);

  @nest html[data-theme='lightning'] & {
    --menu-item-border-radius: var(--offset-small);
    --menu-item-padding-v: var(--1px);
    --menu-item-padding-h: var(--0px);

    background-clip: content-box;
  }
}

.itemSelectable {
  cursor: pointer;

  &:focus {
    background-color: var(--menu-item-focus-background-color);
  }

  @nest html[data-theme='lightning'] & {
    --menu-item-focus-background-color: rgb(var(--lightning--blue-100-rgb) / 0.38);
  }
}

.itemWithDropdown {
}

.itemContent {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  padding: var(--menu-item-content-padding-v) var(--menu-item-content-padding-h);
  line-height: var(--20px);
  text-decoration: none;

  @nest html[data-theme='lightning'] & {
    --menu-item-content-padding-h: var(--10px);
  }
}

.itemContentWithCounter {
  padding-right: var(--5px);
}

.item,
.focuser,
.dropdown {
  outline: none; /* To remove focus glow */
}

.drillIcon {
  margin-right: calc(-1 * var(--menu-item-padding-h) / 2);
  font-size: 0.6em;
  text-align: center;
}

.text {
  flex: 0 0 auto;
}

.extender {
  flex: 1 1 auto;
}

.badge {
  min-width: unset;
  margin-left: var(--10px);
  vertical-align: text-bottom;
  font-size: var(--9px);
  line-height: var(--15px);
}

.counter {
  position: static;
  flex: 0 0 auto;
  margin: 0 0 0 var(--10px);
}

.disabled {
  color: var(--blue-gray-200);
  pointer-events: none;
  border-color: transparent;
}

.menuInfo {
  composes: gapSmall from 'utils.css';
  padding: var(--menu-info-padding-v) var(--menu-info-padding-h);

  @nest html[data-theme='lightning'] & {
    --menu-info-padding-h: calc(
      var(--menu-item-margin-h) + var(--menu-item-content-padding-h) + var(--menu-item-padding-h)
    );
  }
}

.menuInfoEmpty {
  color: var(--gray-400);
}

.menuInfoSidebar {
  padding-left: var(--8px);
  border-left: 3px solid var(--gray-200);
}
