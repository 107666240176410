/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */

.link {
  color: var(--lightning--blue-600);

  &:focus {
    outline: none;
  }

  &:hover,
  &:focus-visible {
    text-decoration: underline;
    text-decoration-skip-ink: auto;
  }

  &:focus-visible {
    box-shadow: 0 0 2px 2px rgb(var(--blue-400-rgb) / 0.5);
  }

  &:active {
    text-decoration: none;
    box-shadow: none;
  }
}

.link[aria-disabled='true'] {
  color: var(--blue-gray-200);
  pointer-events: none;
}

.active {
}
