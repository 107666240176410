/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */

.checkIcon {
  @nest html[data-theme='lightning'] & {
    font-size: var(--12px);
    color: var(--lightning--blue-600);
  }
}

.checkShow {
  visibility: visible;
}

.checkHide {
  visibility: hidden;
}

.optionDescription {
  --optionDesc-font-size: 12pt;
  --optionDesc-font-style: italic;
  --optionDesc-color: var(--gray-500);
  --optionDesc-text-align: left;

  composes: light from 'utils.css';
  font-size: var(--optionDesc-font-size);
  font-style: var(--optionDesc-font-style);
  color: var(--optionDesc-color);
  text-align: var(--optionDesc-text-align);

  @nest html[data-theme='lightning'] & {
    --optionDesc-color: var(--lightning--blue-gray-700);
  }
}

.dropdownLabel {
  --dropdownLabel-font-size: var(--14px);

  padding-left: var(--10px);
  font-size: var(--dropdownLabel-font-size);

  &.optionDescription {
    --dropdownLabel-font-size: var(--12px);

    font-style: var(--optionDesc-font-style);
    color: var(--optionDesc-color);
    text-align: var(--optionDesc-text-align);
  }

  @nest html[data-theme='lightning'] & {
    padding-left: var(--4px);
  }
}

.options {
  display: flex;
  padding: var(--5px) var(--16px);
  text-overflow: ellipsis;
  cursor: pointer;
  overflow: hidden;

  &:focus {
    outline: none;
    background-color: var(--blue-gray-100);

    @nest html[data-theme='lightning'] & {
      background-color: var(--lightning--blue-50);
    }
  }

  &.notSelectable {
    &:focus {
      background-color: transparent;
    }
  }

  &.optionActive {
    @nest html[data-theme='lightning'] & {
      color: var(--lightning--blue-800);

      .dropdownLabel {
        font-weight: var(--fontWeightSemiBold);
      }

      .optionDescription {
        color: var(--lightning--blue-700);
      }
    }
  }

  @nest html[data-theme='lightning'] & {
    padding: var(--8px);
    border-radius: var(--4px);
  }
}
