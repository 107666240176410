/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */

.radio {
  position: relative;
  display: inline-flex;
  align-items: baseline; /* To align radio with the first line of an optional label */
}

.hidden {
  .box {
    visibility: hidden;
  }
}

.noText::before {
  /* In case of lonely radio without a text add unicode zero width space character to keep inline height */
  content: '\200b';
}

.input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.labelBox,
.labelText,
.labelSubText {
  cursor: pointer;
  outline: none;
  user-select: none;
}

.labelBox {
  position: relative;
}

.labelText,
.labelSubText {
  display: block;
  font-size: var(--13px);
}

.side {
  flex: 1 1 auto;
}

.nestedElements {
  composes: gapLarge paddingMediumTop from 'utils.css';
}

.labelText,
.labelSubText,
.nestedElements {
  padding-right: var(--14px);
  padding-left: var(--5px);
}

/* Circular box */
.box {
  --box-size: 1em;
  --box-border-color: var(--gray-400);
  --box-border-width: var(--1px);
  --box-fill-color: var(--blue-700);
  --box-fill-size: 0;
  --box-fill-full-size: calc(var(--box-size) / 2);

  composes: centeredFlex from 'utils.css';
  position: relative;
  display: inline-flex;
  width: var(--box-size);
  height: var(--box-size);
  vertical-align: -0.175em;
  border-radius: 50%;
  box-shadow: inset 0 0 0 var(--box-border-width) var(--box-border-color),
    inset 0 0 0 var(--box-fill-size) var(--box-fill-color);
  background-color: var(--white);

  /* When radio selector doesnt' have checked, insensitive and disabled classes during a hover.
     It is possible that radio is disabled, not checked but allow mouse event.
     .insensitive uses 'pointer-events: none' to disallow mouse event but in some cases
     mouse event is needed even though radio is disabled and not checked */
  .radio:not(.checked, .insensitive, .disabled):hover & {
    --box-border-color: var(--blue-400);
  }

  .radio &.boxFilled {
    --box-border-width: var(--5px);
    --box-border-color: var(--blue-700);
  }

  .radio.error:not(:hover) & {
    --box-border-width: var(--1px);
    --box-border-color: var(--red-600);
  }

  .radio.disabled & {
    --box-border-color: var(--gray-100);
    --box-fill-color: var(--blue-gray-50);
    --box-fill-size: var(--box-fill-full-size);
  }

  .radio.disabled &.boxFilled {
    --box-fill-color: var(--gray-200);
    --box-fill-size: var(--box-fill-full-size);
  }

  &.boxFilled {
    opacity: 1;
  }

  &.boxHidden {
    visibility: hidden;
  }

  /* Align box slightly differently in Safari */
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    vertical-align: -0.125em;
  }
}

/* Always animate focus glow */
.box {
  transition: filter 0.2s ease-out;

  .labelBox:focus-within & {
    filter: drop-shadow(0 0 2px var(--blue-500));
  }
}

/* Animate status change only if user clicks on this radio with mouse (by hovering) or with keyboard (by focusing) */
/* To prevent animating many other controlled radios that can be affected by this radio change or other events */
.radio:hover,
.radio:focus-within {
  .box {
    transition: filter 0.2s ease-out, box-shadow 0.2s ease-out;
  }

  /* On check start size change animation after box-shadow animatiion is almost done, don't animate opacity */
  &.checked {
    .box {
      transition: filter 0.2s ease-out, box-shadow 0.15s linear;
    }
  }
}
