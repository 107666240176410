/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.deny,
.error,
.removed,
.stopped {
  color: var(--red-700);

  @nest html[data-theme='lightning'] & {
    color: var(--lightning--red-600);
  }
}

.warning,
.suspended,
.staged,
.pending,
.disabled,
.unlocked {
  color: var(--orange-700);

  @nest html[data-theme='lightning'] & {
    color: var(--lightning--orange-600);
  }
}

.unpaired,
.disabled {
  color: var(--gray-200);

  @nest html[data-theme='lightning'] & {
    color: var(--lightning--gray-500);
  }
}

.disabled-status {
  color: var(--gray-400);

  @nest html[data-theme='lightning'] & {
    color: var(--lightning--blue-gray-600);
  }
}

.syncing,
.help,
.info {
  color: var(--blue-800);

  @nest html[data-theme='lightning'] & {
    color: var(--lightning--blue-700);
  }
}

.added,
.paired,
.check,
.inuse,
.inuse-classic,
.online,
.enabled {
  color: var(--green-800);

  @nest html[data-theme='lightning'] & {
    color: var(--lightning--green-600);
  }
}

.none {
  color: var(--gray-800);

  @nest html[data-theme='lightning'] & {
    color: var(--lightning--gray-600);
  }
}

.progress,
.modified {
  color: var(--blue-700);

  @nest html[data-theme='lightning'] & {
    color: var(--lightning--blue-700);
  }
}

.offline {
  .icon {
    color: var(--gray-100);
  }

  @nest html[data-theme='lightning'] & {
    .icon {
      color: var(--lightning--blue-gray-600);
    }
  }
}

.unmanaged,
.none {
  .icon {
    color: var(--gray-400);
  }

  @nest html[data-theme='lightning'] & {
    .icon {
      color: var(--lightning--blue-gray-600);
    }
  }
}

.noTextColor {
  color: initial;
}

.added {
  .svg {
    color: var(--green-800);
    transform: scale(0.6);
  }

  @nest html[data-theme='lightning'] & {
    font-size: var(--13px);
    .svg {
      color: var(--lightning--green-600);
      transform: scale(1);
    }
  }
}

.removed {
  .svg {
    color: var(--red-700);
    transform: scale(0.6);
  }

  @nest html[data-theme='lightning'] & {
    font-size: var(--13px);
    .svg {
      color: var(--lightning--red-600);
      transform: scale(1);
    }
  }
}

.progress,
.modified {
  .svg {
    color: var(--blue-700);
    transform: scale(0.6);
  }

  @nest html[data-theme='lightning'] & {
    font-size: var(--13px);
    .svg {
      color: var(--lightning--blue-600);
      transform: scale(1);
    }
  }
}
