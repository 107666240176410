/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */

:root {
  --app-flex-shrink: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.app {
  /* Support displaying line breaks, all spaces, etc in user defined data. https://jira.illum.io/browse/EYE-68589 */
  composes: breakSpaces from 'utils.css';
  display: flex;
  min-height: 100vh;

  @nest html[data-theme='lightning'] & {
    display: flex;
    /* App is equal to window height, independent scrollbars are inside side nav and container */
    height: 100vh;
    /* Make app appear smoothly */
    animation: fadeIn 0.25s linear forwards;
  }
}

.container {
  /* Make content div take as much place as possible to fill the page using flex */
  display: flex;
  flex: 1 var(--app-flex-shrink) auto;
  flex-direction: column;
  width: 100%;

  @nest html[data-theme='lightning'] & {
    --app-flex-shrink: 1;
    /* We now do scrolling inside the main container */
    overflow: auto;
  }
}

.containerFrame {
  border-radius: var(--app-border-radius) 0 0 var(--app-border-radius);
  box-shadow: -2px 0 8px rgb(186 213 222 / 0.12); /* TODO: Update with --blue-500-rgb */
  background-color: var(--white);
}

.content {
  composes: gapMedium paddingMediumTop from 'utils.css';

  /*
   * Set `flex-shrink` to `0` to prevent some browsers from
   * letting these items shrink to smaller than their content's default
   * minimum size. See http://bit.ly/1Mn35US for details.
   */
  flex: 1 0 auto;

  /* Add more padding to the content */
  padding-inline-start: var(--page-horizontal-padding);
  padding-inline-end: var(--page-horizontal-padding);

  /* Make content appear smoothly too */
  animation: fadeIn 0.25s linear forwards;

  @nest html[data-theme='lightning'] & {
    margin-bottom: var(--48px);
  }
}

.contentFrame {
  background-color: var(--white);

  /* On tablets and bigger, content should have margin from body and rounded borders */
  @media screen and (width >= 768px) {
    margin: 0 var(--9px) var(--20px); /* add 1 px for border - e.g. var(--9px) becomes ~10px */
    border: var(--1px) solid var(--blue-gray-100);
    border-radius: 0 0 var(--4px) var(--4px); /* Round bottom corners */
    border-top: none;
  }

  @media screen and (width >= 1200px) {
    margin: 0 var(--9px) var(--9px);
  }
}
