/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */
.card {
  border-radius: var(--8px);
  background: var(--lightning--gray-25);
  transition: background-color 100ms linear;
  &:hover {
    cursor: pointer;
    background-color: var(--gray-50);
  }
}

.selectedCard {
  border-radius: var(--8px);
  background-color: var(--lightning--blue-50);
  transition: background-color 100ms linear;
}

.rulesPreviewContainer {
  width: 98%;
  padding-left: var(--22px);
}

.scopePicker {
  width: 50%;
}

.pillGap {
  composes: gapXSmall gapHorizontalWrap from 'utils.css';
}

.iconStyle {
  color: var(--green-600);
}

.title {
  composes: bold from 'utils.css';
  font-size: var(--14px);
  font-style: normal;
  line-height: var(--17px);
  color: var(--lightning--blue-gray-900);
}

.description {
  margin: unset;
  font-size: var(--13px);
  font-style: normal;
  line-height: var(--16px);
  color: var(--lightning--blue-gray-900);
}

.cardsContainer {
  composes: gapMedium from 'utils.css';
  max-height: calc(var(--100px) * 4);
  margin: 0;
  padding: 0;
  list-style: none;
}

.areaHead .destination,
.areaHead .services {
  color: var(--blue-gray-800);
  background-color: var(--lightning--purple-100);
}

.contentPadding {
  padding: var(--offset-x-large) var(--offset-medium);

  @nest html[data-theme='lightning'] & {
    padding: var(--offset-x-large) var(--offset-medium);
  }
}
