/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
:root {
  --metrics-card-bg-color: var(--white);
  --metrics-card-font-color: initial;
  --metrics-card-highlight-bg-color: var(--white);
  --metrics-card-box-shadow: var(--shadow-depth-2);
  --metrics-card-border-width: 1px;
  --metrics-card-border-style: solid;
  --metrics-card-border-color: transparent;
  --metrics-card-border-radius: var(--0px);
  --metrics-card-padding-v: var(--0px);
  --metrics-card-padding-h: var(--0px);
  --metrics-card-header-padding: var(--card-padding);
  --metric-card-body-padding: var(--card-padding);
  --metric-card-body-gap: var(--offset-medium);
}

.card {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  padding: var(--metrics-card-padding-v) var(--metrics-card-padding-h);
  color: var(--metrics-card-font-color);
  border: var(--metrics-card-border-width) var(--metrics-card-border-style) var(--metrics-card-border-color);
  border-radius: var(--metrics-card-border-radius);
  box-shadow: var(--metrics-card-box-shadow);
  background-color: var(--metrics-card-bg-color);

  @nest html[data-theme='lightning'] & {
    --metrics-card-border-color: var(--lightning--blue-gray-200);
    --metrics-card-border-radius: 12px;
    --metrics-card-box-shadow: none;
    --metrics-card-padding-v: var(--offset-large);
    --metrics-card-padding-h: var(--offset-x-large);

    transition-duration: 0.15s;
    transition-property: background-color, border-color, font-color, box-shadow;
    transition-timing-function: ease;

    & .title {
      transition: color 0.15s ease;
    }
  }

  @nest html[data-theme='classic'] & {
    --metrics-card-highlight-bg-color: var(--gray-50);
  }
}

.displayInRow {
  flex-flow: row nowrap;

  & .header {
    align-self: flex-start;
    max-width: 50%;

    & .title {
      white-space: break-spaces;
    }
  }
}

.body {
  display: flex;
  flex-grow: 1;
  flex-flow: column nowrap;
  justify-content: center;
  overflow-x: hidden;
  overflow-y: hidden;
  gap: var(--metric-card-body-gap);

  & > .center {
    height: 100%;
    margin: auto 0;
  }

  @nest html[data-theme='lightning'] & {
    --metric-card-body-padding: var(--0px);
    --metric-card-body-gap: var(--0px);

    padding: var(--metric-card-body-padding);
    padding-top: 0;
    /* need for the scrollable detection */
    padding-bottom: var(--1px);
  }

  @nest html[data-theme='classic'] & {
    padding: 0 var(--metric-card-body-padding) 0 var(--metric-card-body-padding);
    gap: 0;
  }
}

.scrollable {
  overflow-y: scroll;
}

.extraBottomMargin {
  margin-bottom: calc(
    2 * var(--form-input-border-width) + 2 * var(--inputAndtextArea-vertical-padding) +
      var(--inputAndtextArea-line-height) + var(--metric-card-body-padding)
  );
}

.lightBlue {
  --metrics-card-bg-color: var(--lightning--blue-50);
  --metrics-card-highlight-bg-color: var(--lightning--blue-50);
  --metrics-card-font-color: var(--lightning--blue-900);
  --metrics-card-title-font-color: var(--lightning--blue-800);
  --metrics-card-suffix-font-color: var(--lightning--blue-700);

  @nest html[data-theme='lightning'] & {
    --metrics-card-border: calc(1.5 * var(--1px)) solid transparent;
    --metrics-card-title-font-color: var(--lightning--blue-800);
  }
}

.clickableLightBlue {
  composes: lightBlue;

  &:hover {
    cursor: pointer;
    border: calc(1.5 * var(--1px)) solid var(--lightning--blue-100);
    box-shadow: var(--1px) var(--2px) rgb(var(--lightning--blue-gray-700-rgb) / 0.02),
      var(--3px) var(--3px) var(--6px) 0 rgb(var(--lightning--blue-gray-700-rgb) / 0.02),
      var(--15px) var(--15px) var(--25px) 0 rgb(var(--lightning--blue-gray-700-rgb) / 0.04);

    & .title {
      color: var(--lightning--blue-gray-700);
    }
  }

  &:active {
    --metrics-card-bg-color: var(--lightning--blue-900);
  }

  &:focus {
    outline: var(--5px) solid var(--lightning--blue-50);
    border: var(--1px) solid var(--lightning--blue-500);
  }
}

.blue {
  --metrics-card-highlight-bg-color: var(--blue-500);

  @nest html[data-theme='lightning'] & {
    --metrics-card-bg-color: rgb(227 242 252);
    --metrics-card-border: none;
    --metrics-card-border-color: 'transparent';
    --metrics-card-font-color: rgb(6 83 163);
  }

  @nest html[data-theme='classic'] & {
    --metrics-card-font-color: var(--white);
    --metrics-card-bg-color: var(--blue-600);
    --metrics-card-highlight-bg-color: var(--blue-500);
  }
}

.green {
  --metrics-card-bg-color: var(--lightning--green-500);
  --metrics-card-font-color: var(--white);
  --metrics-card-title-font-color: var(--white);
  --metrics-card-highlight-bg-color: var(--green-400);

  @nest html[data-theme='lightning'] & {
    --metrics-card-bg-color: var(--lightning--green-50);
    --metrics-card-font-color: var(--lightning--green-700);
    --metrics-card-title-font-color: var(--lightning--green-900);
    --metrics-card-suffix-font-color: var(--lightning--green-700);
    --metrics-card-sideNumberTitle-font-color: var(--lightning--green-500);
    --metrics-card-leftSideNumberBox-border-color: var(--lightning--green-200);
    --metrics-card-highlight-bg-color: var(--lightning--green-50);
    --metrics-card-border: none;
    --metrics-card-border-color: 'transparent';
  }
}

.orange {
  --metrics-card-bg-color: var(--lightning--orange-500);
  --metrics-card-font-color: var(--white);
  --metrics-card-highlight-bg-color: var(--orange-300);

  @nest html[data-theme='lightning'] & {
    --metrics-card-bg-color: var(--lightning--orange-50);
    --metrics-card-font-color: var(--lightning--orange-900);
    --metrics-card-sideNumberTitle-font-color: var(--lightning--orange-500);
    --metrics-card-leftSideNumberBox-border-color: var(--lightning--orange-200);
    --metrics-card-highlight-bg-color: var(--lightning--orange-50);
    --metrics-card-border: none;
    --metrics-card-border-color: 'transparent';
  }
}

.red {
  --metrics-card-bg-color: var(--lightning--red-500);
  --metrics-card-font-color: var(--white);
  --metrics-card-highlight-bg-color: var(--red-300);

  @nest html[data-theme='lightning'] & {
    --metrics-card-bg-color: var(--lightning--red-50);
    --metrics-card-font-color: var(--lightning--red-700);
    --metrics-card-sideNumberTitle-font-color: var(--lightning--red-400);
    --metrics-card-leftSideNumberBox-border-color: var(--lightning--red-200);
    --metrics-card-highlight-bg-color: var(--lightning--red-50);
    --metrics-card-border: none;
    --metrics-card-border-color: 'transparent';
  }
}

.gray {
  --metrics-card-bg-color: var(--gray-400);
  --metrics-card-font-color: var(--white);
  --metrics-card-highlight-bg-color: var(--gray-300);

  @nest html[data-theme='lightning'] & {
    --metrics-card-border: none;
    --metrics-card-border-color: 'transparent';
  }
}

.header {
  display: flex;
  flex-flow: row nowrap;
  padding: var(--metrics-card-header-padding);

  & .title {
    font-size: var(--15px);
    font-weight: var(--fontWeightBold);
    text-overflow: ellipsis;
    white-space: var(--metric-card-title-white-space, nowrap);
    cursor: default;

    @nest html[data-theme='lightning'] & {
      --metric-card-title-white-space: break-spaces;

      font-size: var(--15px);
    }

    @nest html[data-theme='classic'] & {
      --metric-card-title-white-space: break-spaces;

      font-size: var(--20px);
    }
  }

  & .clickable {
    cursor: pointer;
  }

  & .hoverable {
    cursor: help;
  }

  & .action {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-left: auto;
    font-size: var(--12px);

    & > * {
      margin-left: var(--5px);
    }

    & .triggerLabel {
      color: var(--blue-gray-700);
      transform: rotate(90deg);
    }
  }

  @nest html[data-theme='lightning'] & {
    --metrics-card-header-padding: var(--0px);
    --metrics-card-title-font-color: var(--lightning--blue-gray-900);
  }
}

.dropdown {
  z-index: var(--shadow-above-all-z);
  right: calc(-1 * var(--7px));
}

.dropdownWithArrow::before {
  right: var(--6px);
}

.scrollDown {
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: var(--5px);
  width: 100%;
}

.scrollUp {
  position: absolute;
  display: flex;
  justify-content: center;
  top: var(--55px);
  width: 100%;
  rotate: 180deg;
}

.metrics-icon {
  color: var(--gray-700);
  cursor: pointer;
  border: var(--gray-400) solid 1px;
  border-radius: 100%;
  background-color: var(--white);
}

.metrics-svg {
  width: 1.5em;
  height: 1em;
  padding: var(--2px);
  padding-bottom: 0;
}

.close-icon {
  padding-left: var(--10px);
  cursor: pointer;
}

.timerIconWrapper {
  display: flex;
  justify-content: end;
  align-items: end;

  @nest html[data-theme='classic'] & {
    margin: 0 var(--metric-card-body-padding) var(--metric-card-body-padding) var(--metric-card-body-padding);
  }
}

.timer-icon {
  margin-left: var(--4px);
  color: var(--lightning--gray-600);

  & :hover {
    cursor: pointer;
  }

  @nest html[data-theme='classic'] & {
    font-size: 1.2em;
  }
}

.timer-white-icon {
  composes: timer-icon;
  color: var(--white);
}

.timer-red-icon {
  composes: timer-icon;
  color: var(--lightning--red-400);
}

.timer-orange-icon {
  composes: timer-icon;
  color: var(--lightning--orange-400);
}

.timer-green-icon {
  composes: timer-icon;
  color: var(--lightning--green-400);
}

.buttonMenu-menu-itemContent {
  justify-content: flex-end;
}

.buttonMenu-menu-triggerLabel {
  composes: semiBold from 'utils.css';
}
