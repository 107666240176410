/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */

.whatsnew {
  composes: gapMedium from 'utils.css';
  position: relative;
  float: right;
  min-width: calc(var(--100px) * 2);
  max-width: calc(3.2 * var(--100px));
  padding: var(--10px) var(--25px) var(--10px) var(--10px);
  border-radius: var(--5px);
  box-shadow: var(--shadow-depth-2);
  background-color: var(--blue-gray-700);

  .content {
    font-size: var(--16px);
    color: var(--white);
  }

  .secondary {
    font-size: var(--13px);
    font-style: italic;
    color: var(--white);
  }

  .link {
    color: var(--blue-300);
  }

  .title {
    font-size: var(--18px);
    color: var(--orange-a200);
  }
}

.close-icon {
  position: absolute;
  top: var(--5px);
  right: var(--5px);
  font-size: var(--10px);
  color: var(--gray-400);
  cursor: pointer;
}
