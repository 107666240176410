/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */
.container {
  composes: centerMarginBlock gapSmall from 'utils.css';

  @nest html[data-theme='lightning'] & {
    margin-top: var(--32px);
  }
}

.status {
  padding-top: var(--20px);
}
