/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */

:root {
  --pill-line-height: var(--18px);
  --pill-content-vertical-padding: var(--2px);
  --pill-content-horizontal-padding: var(--2px);
  --pill-extra-vertical-padding: var(--3px);
  --pill-extra-horizontal-padding: var(--5px);
  --pill-text-horizontal-padding: var(--4px);
  /* Variable for overall height of one line of normal and extra scoped pills */
  --pill-normal-total-height: calc(var(--pill-line-height) + 2 * var(--pill-content-vertical-padding));
  --pill-extra-total-height: calc(var(--pill-normal-total-height) + 2 * var(--pill-extra-vertical-padding));
}

.pill {
  --pill-outline-color: transparent;
  --pill-box-shadow-hover: none;
  --content-color: var(--blue-850);
  --content-color-active: var(--white);
  --content-color-hover: var(--blue-850);
  --content-color-focus: var(--blue-850);
  --content-background-color: var(--blue-gray-50);
  --content-background-color-active: var(--blue-gray-500);
  --content-background-color-hover: var(--blue-100);
  --content-background-color-focus: var(--blue-200);

  position: relative;
  display: inline-flex;
  max-width: 100%;
  user-select: none;
  border: var(--1px) solid transparent;

  @nest html[data-theme='lightning'] & {
    --pill-line-height: normal;
    --pill-outline-color: transparent;
    --pill-outline-color-active: var(--lightning--blue-500);
    --pill-outline-color-hover: var(--lightning--blue-200);
    --pill-outline-color-focus: var(--lightning--blue-100);
    --pill-box-shadow-hover: none;
    --content-color: var(--lightning--blue-gray-900);
    --content-color-active: var(--lightning--blue-gray-900);
    --content-color-hover: var(--lightning--blue-gray-700);
    --content-color-focus: var(--lightning--blue-gray-900);
    --content-background-color: var(--lightning--blue-gray-50);
    --content-background-color-active: var(--lightning--blue-100);
    --content-background-color-hover: var(--lightning--blue-50);
    --content-background-color-focus: var(--lightning--blue-gray-50);
    --exclusion-color: var(--lightning--white);
    --exclusion-color-active: var(--lightning--white);
    --exclusion-color-hover: var(--lightning--white);
    --exclusion-color-focus: var(--lightning--white);
    --exclusion-background-color: var(--lightning--blue-gray-700);
    --exclusion-background-color-active: var(--lightning--blue-gray-700);
    --exclusion-background-color-hover: var(--lightning--blue-gray-700);
    --exclusion-background-color-focus: var(--lightning--blue-gray-700);

    outline: var(--1px) solid var(--pill-outline-color);
    border: unset;
  }
}

.exclusionArea {
  display: flex;
  align-items: center;
  min-width: 50%;
  padding: 0 var(--pill-text-horizontal-padding);
  color: var(--content-color);
}

.content {
  display: inline-flex;
  padding: var(--pill-content-vertical-padding) var(--pill-content-horizontal-padding);
  line-height: var(--pill-line-height);
  color: var(--content-color);
  outline: var(--1px) solid transparent;
  border: var(--1px) solid;
  border-color: var(--content-border-color);
  border-radius: var(--11px); /* Overall height is 22px (2px + 18px + 2px) */

  @nest html:not([data-theme='lightning']) & {
    --content-color: var(--gray-900);
    --content-border-color: transparent;
  }

  @nest html[data-theme='lightning'] & {
    --pill-content-horizontal-padding: var(--4px);

    display: flex;
    align-items: center;
    box-sizing: border-box;
    outline: none;
    border: none;
    border-radius: 0;
  }
}

.fill {
  .content {
    background-color: var(--content-background-color);
  }

  .exclusionArea {
    color: var(--exclusion-color);
    background-color: var(--exclusion-background-color);
  }
}

.pill {
  border-radius: var(--12px); /* Make label radius equal to content's radius to make focus glow rounded */

  .content {
    border-radius: var(--12px); /* Extra 1px border makes label 2px taller, so need to increase border-radius as well */

    @nest html[data-theme='lightning'] & {
      border-radius: 0;
    }
  }

  &.fill {
    .content {
      /* Pill has border (for new, disabled and deleted labels border color is overridden below) */
      --content-border-color: var(--blue-gray-150);
    }
  }

  @nest html[data-theme='lightning'] & {
    outline-offset: calc(-1 * var(--1px));
    overflow: hidden;
  }
}

.text {
  flex: 1 1 auto;
  align-self: baseline;
  min-width: 0; /* Very important to allow label text to wrap in GRID, https://css-tricks.com/flexbox-truncated-text */
  padding: 0 var(--pill-text-horizontal-padding);
  font-size: var(--13px);
  text-align: initial;
  word-break: break-word;
  overflow-wrap: break-word;

  @nest html[data-theme='lightning'] & {
    /* stylelint-disable-next-line value-no-vendor-prefix */
    display: -webkit-box;
    align-self: auto;
    align-items: center;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.exclusionLabel {
  padding: 0 var(--pill-text-horizontal-padding);
}

.pillSymbol-pillSymbol {
  flex: 0 0 auto;
}

.pillSymbol-group .pillSymbol-icon {
  box-shadow: 0 0 0 var(--1px) var(--content-background-color);
}

.pill.created {
  &.exclusion {
    &.fill {
      .content {
        outline-color: var(--green-700);
        border-color: var(--white);

        @nest html[data-theme='lightning'] & {
          outline-color: transparent;
        }
      }
    }

    .content {
      --content-color: var(--green-300);
    }
  }

  &.fill {
    .content {
      --content-background-color: var(--white);
      --content-border-color: var(--green-700);

      @nest html[data-theme='lightning'] & {
        --content-background-color: var(--lightning--green-100);
      }
    }
  }

  .content {
    --content-color: var(--green-700);

    @nest html[data-theme='lightning'] & {
      --content-color: var(--lightning--blue-gray-900);
    }
  }
}

.pill.warning {
  &.exclusion {
    &.fill {
      .content {
        outline-color: var(--yellow-600);
        border-color: var(--white);

        @nest html[data-theme='lightning'] & {
          outline-color: transparent;
        }
      }
    }

    .content {
      --content-color: var(--yellow-500);
    }
  }

  &.fill {
    .content {
      --content-background-color: var(--yellow-50);
      --content-border-color: var(--yellow-600);

      @nest html[data-theme='lightning'] & {
        --content-background-color: var(--lightning--orange-100);
      }
    }
  }

  .content {
    --content-color: var(--yellow-900);

    @nest html[data-theme='lightning'] & {
      --content-color: var(--lightning--blue-gray-900);
    }
  }
}

.pill.deleted {
  &.exclusion {
    &.fill {
      .content {
        outline-color: var(--red-600);
        border-color: var(--white);

        @nest html[data-theme='lightning'] & {
          outline-color: transparent;
        }
      }
    }

    .content {
      --content-color: var(--red-300);

      text-decoration: line-through;

      @nest html[data-theme='lightning'] & {
        text-decoration: none;
      }
    }
  }

  &.fill {
    .content {
      --content-background-color: var(--white);
      --content-border-color: var(--red-600);

      @nest html[data-theme='lightning'] & {
        --content-background-color: var(--lightning--red-100);
      }
    }
  }

  .content {
    --content-color: var(--red-700);

    text-decoration: line-through;

    @nest html[data-theme='lightning'] & {
      --content-color: var(--lightning--red-700);
    }
  }
}

.pill.error {
  &.fill {
    .content {
      --content-background-color: var(--red-50);
      --content-border-color: var(--red-600);

      @nest html[data-theme='lightning'] & {
        --content-background-color: var(--lightning--red-100);
      }
    }
  }
}

.pill.exclusion {
  &.fill {
    .content {
      @nest html:not([data-theme='lightning']) & {
        --content-background-color: var(--gray-800);
      }
    }
  }

  .content {
    --content-color: var(--white);

    @nest html[data-theme='lightning'] & {
      --content-color: var(--lightning--blue-gray-900);
    }
  }
}

.pill.disabled {
  &.fill {
    background-color: var(--content-background-color);

    .content {
      border-color: var(--blue-gray-50);
    }
  }

  .content {
    color: var(--gray-400);
    text-decoration: line-through;
  }
}

.pill.added,
.pill.modified,
.pill.deleted,
.pill.allowed,
.pill.blocked,
.pill.unknown,
.pill.potentiallyBlocked {
  .status-icon {
    padding: 0 var(--pill-text-horizontal-padding);
    font-size: var(--9px);

    @nest html[data-theme='lightning'] & {
      align-self: center;
      padding-right: 0;
    }
  }
}

.pill.deleted .status-icon {
  color: var(--red-600);
}

.pill.added .status-icon {
  color: var(--green-700);
}

.pill.modified .status-icon {
  color: var(--blue-700);
}

.pill.allowed .status-icon {
  color: var(--green-700);
}

.pill.blocked .status-icon {
  color: var(--red-400);
}

.pill.unknown .status-icon {
  color: var(--gray-300);
}

.pill.potentiallyBlocked .status-icon {
  color: var(--orange-a200);
}

.updateTypeIcon {
  position: absolute;
  top: calc(-1 * var(--6px));
  right: calc(-1 * var(--4px));
  font-size: var(--10px);
}

.create-svg,
.update-svg,
.delete-svg {
  width: var(--12px); /* To fit stroke */
  stroke: var(--white);
  stroke-width: var(--3px);
  stroke-linejoin: round;
}

.create-icon {
  composes: updateTypeIcon;
  color: var(--green-700);
}

.update-icon {
  composes: updateTypeIcon;
  color: var(--blue-700);
}

.delete-icon {
  composes: updateTypeIcon;
  color: var(--red-600);
}

.close {
  display: inline-block;
  align-self: stretch; /* To make it height take up whole label's content height */
  margin: calc(-1 * var(--pill-content-vertical-padding)) calc(-1 * var(--pill-content-horizontal-padding))
    calc(-1 * var(--pill-content-vertical-padding)) calc(-1 * var(--pill-text-horizontal-padding) / 2);
  padding: var(--pill-content-vertical-padding)
    calc(var(--pill-content-horizontal-padding) + var(--pill-text-horizontal-padding))
    var(--pill-content-vertical-padding) calc(var(--pill-text-horizontal-padding) / 2);
  font-size: var(--13px);
  cursor: pointer;

  &:hover {
    color: var(--blue-gray-900);
  }

  @nest html[data-theme='lightning'] & {
    align-self: center;
  }
}

.close-icon {
  display: inline-block;
  transform: scale(0.6);
}

.pill.pinned {
  .close-icon {
    display: none;
  }

  .pinned-icon {
    display: inline-block;
    transform: scale(0.8);
  }

  .close:hover {
    .close-icon {
      display: inline-block;
    }

    .pinned-icon {
      display: none;
    }
  }
}

.interactive {
  &:focus {
    outline: none;
  }

  &:focus-visible:not(:active) {
    /* Add custom focus outline */
    box-shadow: 0 0 var(--3px) var(--2px) rgb(var(--blue-400-rgb) / 0.5);

    @nest html[data-theme='lightning'] & {
      box-shadow: var(--2px) 0 0 var(--1px) var(--lightning--blue-200),
        calc(-1 * var(--2px)) 0 0 var(--1px) var(--lightning--blue-200);
    }
  }

  .content {
    border: var(--1px) solid var(--content-border-color);

    @nest html[data-theme='lightning'] & {
      border: unset;
    }
  }

  &:not(:active):hover,
  &:not(:active):focus {
    &.fill {
      .content {
        color: var(--content-color-hover);
        background-color: var(--content-background-color-hover);
      }
    }

    .content {
      color: var(--content-color-hover);
      border-color: var(--blue-500);
    }

    .pillSymbol-group .pillSymbol-icon {
      box-shadow: 0 0 0 var(--1px) var(--blue-100);
    }

    &.created {
      &.fill {
        .content {
          --content-color-hover: var(--green-900);
          --content-background-color-hover: var(--green-200);
        }
      }

      .content {
        --content-color-hover: var(--green-900);
        --content-border-color: var(--green-600);
      }

      .pillSymbol-group .pillSymbol-icon {
        box-shadow: 0 0 0 var(--1px) var(--green-200);
      }
    }

    &.deleted {
      &.fill {
        .content {
          --content-color-hover: var(--red-800);
          --content-background-color-hover: var(--red-100);
        }
      }

      .content {
        --content-color-hover: var(--red-800);
        --content-border-color: var(--red-700);
      }

      .pillSymbol-group .pillSymbol-icon {
        box-shadow: 0 0 0 var(--1px) var(--red-100);
      }
    }

    @nest html[data-theme='lightning'] & {
      --pill-outline-color: var(--lightning--blue-200);
    }
  }

  @nest html[data-theme='lightning'] & {
    &:not(:active):hover {
      &.created {
        --pill-outline-color: var(--lightning--green-200);

        &.fill {
          .content {
            --content-background-color-hover: var(--lightning--green-50);
            --content-color-hover: var(--lightning--green-700);
          }
        }

        .content {
          --content-color-hover: var(--lightning--green-700);
        }
      }

      &.warning {
        --pill-outline-color: var(--lightning--orange-200);

        &.fill {
          .content {
            --content-background-color-hover: var(--lightning--orange-50);
          }
        }

        .content {
          --content-color-hover: var(--lightning--orange-700);
        }
      }

      &.deleted {
        --pill-outline-color: var(--lightning--red-200);

        &.fill {
          .content {
            --content-background-color-hover: var(--lightning--red-50);
          }
        }

        .content {
          --content-color-hover: var(--lightning--red-700);
        }
      }

      &.error {
        --pill-outline-color: var(--lightning--red-200);

        &.fill {
          .content {
            --content-background-color-hover: var(--lightning--red-50);
          }
        }

        .content {
          --content-color-hover: var(--lightning--red-700);
        }
      }

      &.exclusion {
        .exclusionArea {
          --exclusion-background-color: var(--lightning--blue-gray-600);
        }
      }
    }
  }

  @nest html[data-theme='lightning'] & {
    --pill-box-shadow: none;

    &:not(:active):focus {
      outline-color: var(--pill-outline-color-active);

      &.fill {
        .content {
          background-color: var(--content-background-color-focus);
        }
      }

      .content {
        color: var(--content-color-active);
      }
    }
  }
}

.clickable {
  cursor: pointer;

  &:active {
    .content {
      color: var(--content-color-active);
      border-color: var(--blue-gray-600);
      background-color: var(--content-background-color-active);
    }

    .pillSymbol-group .pillSymbol-icon {
      box-shadow: 0 0 0 var(--1px) var(--blue-gray-500);
    }

    @nest html[data-theme='lightning'] & {
      --pill-outline-color-active: transparent;

      &.created {
        &.fill {
          .content {
            --content-background-color-active: var(--lightning--green-200);
            --content-color-active: var(--lightning--green-800);
          }
        }

        .color {
          --content-color-active: var(--lightning--green-800);
        }
      }

      &.warning {
        &.fill {
          .content {
            --content-background-color-active: var(--lightning--orange-200);
            --content-color-active: var(--lightning--orange-800);
          }
        }

        .color {
          --content-color-active: var(--lightning--orange-800);
        }
      }

      &.error {
        &.fill {
          .content {
            --content-background-color-active: var(--lightning--red-200);
            --content-color-active: var(--lightning--red-800);
          }
        }

        .color {
          --content-color-active: var(--lightning--red-800);
        }
      }

      &.deleted {
        &.fill {
          .content {
            --content-background-color-active: var(--lightning--red-200);
            --content-color-active: var(--lightning--red-900);
          }
        }

        .color {
          --content-color-active: var(--lightning--red-900);
        }
      }

      &.exclusion {
        .exclusionArea {
          --exclusion-background-color: var(--lightning--blue-gray-800);
        }
      }
    }
  }
}

.highlighted {
  .content {
    color: var(--content-color-hover);
    border: var(--1px) solid var(--blue-500);

    @nest html[data-theme='lightning'] & {
      border: unset;
    }
  }

  &.fill {
    .content {
      color: var(--content-color-hover);
      background-color: var(--content-background-color-hover);
    }
  }

  @nest html[data-theme='lightning'] & {
    --pill-outline-color: var(--lightning--blue-200);

    &.warning {
      --pill-outline-color: var(--lightning--orange-200);

      &.fill {
        .content {
          --content-background-color-hover: var(--lightning--orange-50);
        }
      }

      .content {
        --content-color-hover: var(--lightning--orange-700);
      }
    }

    &.error {
      --pill-outline-color: var(--lightning--red-200);

      &.fill {
        .content {
          --content-background-color-hover: var(--lightning--red-50);
        }
      }

      .content {
        --content-color-hover: var(--lightning--red-700);
      }
    }
  }
}
