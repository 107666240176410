/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */

.graphBar {
  position: relative;
  display: flex;
  width: 100%;
  overflow: hidden;
  border-radius: var(--2px);
  box-shadow: inset 0 0 var(--1px) rgb(var(--black-rgb) / 0.3);
  background: var(--gray-100);

  @nest html[data-theme='lightning'] & {
    --graph-bar-height: var(--4px);
  }
}

.disabled {
  opacity: 0.3;
  pointer-events: none;
}

.small {
  composes: graphBar;
  height: var(--graph-bar-height, var(--7px));

  @nest html[data-theme='lightning'] & {
    --graph-bar-height: var(--4px);
  }
}

.medium {
  composes: graphBar;
  height: var(--12px);
}

.large {
  composes: graphBar;
  height: var(--16px);
}

.bar {
  position: absolute;
  top: 0;
  bottom: 0;
  transform-origin: left;

  &:not(.first) {
    border-left: var(--3px) solid white;

    .small & {
      border-left-width: var(--2px);
    }
  }
}
