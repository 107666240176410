/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
.flex {
  display: flex;
}

.card {
  justify-content: space-between;
  align-items: flex-start;
}

.spaceBetween {
  composes: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.spinnerContainer {
  composes: flex;
  align-items: center;
}

.oldSpinnerContainer {
  composes: flex;
  align-items: center;

  .h1 {
    margin-right: var(--20px);
    font-size: var(--24px);
  }
}

.p {
  margin: 0;
  font-size: var(--16px);
  font-weight: var(--fontWeightBold);
}
