/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */

.selector {
  display: flex;
  flex: 1 0 auto;
  flex-wrap: wrap;
  align-items: center;
  min-height: var(--35px);
  margin-left: var(--10px);
  padding: var(--2px);
  overflow: hidden;
  border: var(--1px) solid var(--gray-100);
  background-color: var(--white);
}

.focused {
  border: var(--1px) solid var(--blue-700);
}

.item {
  margin: var(--2px);
}

.actions {
  composes: gapSmall gapHorizontal from 'utils.css';
  display: flex;
  justify-content: flex-end;
  background: var(--white);
}

.actionButton {
  padding: var(--10px);
}

.customPicker {
  width: calc(var(--2px) + var(--dt-picker-width));
  border: var(--1px) solid var(--gray-200);
  background: var(--white);
}

.errorMessage {
  padding-bottom: var(--10px);
  padding-left: var(--60px);
  color: var(--red-700);
}

.errorText {
  padding-left: var(--10px);
}

.inputHolder {
  display: inline-flex;
  align-items: center;
  width: calc(3 * var(--100px));
  padding: var(--12px);
}

.inputLabel {
  min-width: var(--35px);
}

.input {
  max-width: 100%; /* If placeholder is longer than input's parent */
  height: var(--24px);
  margin: var(--2px) var(--2px) var(--2px) var(--5px);
  outline: none;
  border: none;
}
