/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
.pageContainer {
  composes: pageContainer from 'antman/containers/styles.css';

  /* Need high specificity to override noFill `color` property */
  .toolBar .button:has(.progressWrapper) {
    color: var(--white);
  }
}
