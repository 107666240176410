/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
.serviceCard {
  composes: gapLarge gapHorizontal from 'utils.css';
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: var(--offset-large);
  border-bottom: var(--1px) solid var(--gray-50);

  &:hover {
    border-bottom: var(--1px) solid var(--gray-100);
    background-color: var(--gray-50);
  }
}

.serviceCardContent {
  display: flex;
  flex-direction: column;
}

.serviceCardDescription {
  composes: light from 'utils.css';
  font-size: var(--13px);
  color: var(--gray-550);
}
