/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */

.header {
  --header-itemWidth: var(--50px);
  --header-title-offset: 1vw; /* Left margin usually 1% of viewport, but not less than 5px and no more than 16px */
  --header-title-font-size: var(--18px);
  --header-subtitle-font-size: var(--16px);
  --header-divider-color: rgb(var(--blue-500-rgb) / 0.5);
  --header-alternate-color: var(--gray-200);

  /* Tablets */
  @media (768px <= width <= 960px) {
    --header-itemWidth: var(--37px);
    --header-title-font-size: var(--15px);
    --header-subtitle-font-size: var(--13px);
  }

  /* Small screens */
  @media (961px <= width <= 1024px) {
    --header-itemWidth: var(--40px);
    --header-title-font-size: var(--16px);
    --header-subtitle-font-size: var(--14px);
  }

  /* Normal screens */
  @media (1025px <= width <= 1439px) {
    --header-itemWidth: var(--45px);
    --header-title-font-size: var(--17px);
    --header-subtitle-font-size: var(--15px);
  }

  /* Big screens */
  @media (width >= 1440px) {
    --header-itemWidth: var(--50px);
    --header-title-offset: var(--16px);
    --header-title-font-size: var(--18px);
    --header-subtitle-font-size: var(--16px);
  }
}

.header {
  display: flex;
  flex: 0 0 var(--header-height); /* Fixed header height (not shrinkable) */
  align-items: stretch;
  height: var(--header-height);
  background-color: var(--header-color);
}

.headerNoContent {
  composes: header;
  justify-content: flex-end;
  align-items: center;
  border-bottom: var(--1px) solid var(--header-alternate-color);
  background-color: var(--white);
}

.headerItem {
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--header-height); /* Hack for Safari < 11, height: 100% should work fine */
  color: var(--white);
  background-color: transparent;
}

.headerItemClickable {
  cursor: pointer;
  user-select: none;
  transition: background-color 0.1s linear;

  &:hover {
    background-color: var(--blue-800);
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: 1px dotted rgb(var(--white-rgb) / 0.5);
    outline-offset: -4px;
  }
}

.headerItemStandardWidth {
  flex: 0 0 var(--header-itemWidth);
}

.headerItemHalfWidth {
  flex: 0 0 calc(var(--header-itemWidth) / 2);
}

.headerItemTwoThirdWidth {
  flex: 0 0 calc(2 * var(--header-itemWidth) / 3);
}

.headerItemLeftDivider {
  border-left: 1px solid var(--header-divider-color);
}

.headerItemRightDivider {
  border-right: 1px solid var(--header-divider-color);
}

.up-link {
  composes: headerItem headerItemClickable headerItemLeftDivider headerItemRightDivider;
  width: 0;
  opacity: 0;
  overflow: hidden;
  will-change: width, opacity;
  transition: width 0.15s linear, opacity 0.15s linear; /* Collapse transition */
}

.upShow-link {
  --fadeInOpacity: opacity 0.25s linear;

  composes: up-link;
  flex-shrink: 0; /* To prevent it from shrinking on small screen. Don't use min-width, it will prevent transition */
  width: var(--30px);
  opacity: 1;
  transition: width 0.25s ease-out, var(--fadeInOpacity); /* Expand transition */
}

.upIcon-icon {
  margin-left: var(--2px);
  font-size: var(--15px);
}

.headline {
  composes: headerItem;
  composes: bold from 'utils.css';
  flex: 1 1 auto;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: center;
  margin-left: var(--header-title-offset);
  overflow: hidden;
}

.headlineInner {
  flex-grow: 1;
  max-width: calc(100% + var(--28px));
  margin-left: calc(-1 * var(--28px));
  text-indent: var(--28px);
}

.title {
  composes: bold from 'utils.css';
  display: inline-block;
  font-size: var(--header-title-font-size);
  color: var(--white);
  text-indent: 0;
  white-space: nowrap;
}

.sublabel {
  display: inline-flex;
  max-width: 100%;
  font-size: var(--header-subtitle-font-size);
  text-indent: initial;
  white-space: nowrap;
}

.subtitle {
  color: var(--blue-gray-50);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &::before {
    display: inline-block;
    content: '\2013'; /* en dash in unicode */
    width: var(--28px);
    text-align: center;
  }
}

.label {
  color: var(--orange-a200);

  &::before {
    content: '\2008';
  }
}

.title,
.subtitle,
.label {
  animation: fadeIn 0.25s linear forwards;
}

.healthState,
.helpPopup,
.instantSearch {
  composes: headerItem headerItemClickable;
}

.healthState {
  composes: headerItemStandardWidth;
  position: relative;
  z-index: 1; /* To make counter overflow UserMenu trigger if counter is long */
  font-size: var(--21px);
}

.helpPopup,
.instantSearch {
  composes: headerItemTwoThirdWidth headerItemLeftDivider;
}

.instantSearch {
  padding: 0 var(--10px);
}

.link {
  padding: 0 var(--offset-small);
  font-size: var(--20px);
}

.uiToggle {
  composes: headerItemClickable headerItemLeftDivider;
  display: flex;
  align-items: center;
  height: 100%;
}

.svgWhiteFill {
  fill: var(--white);
}

.counter {
  top: var(--3px);
  right: auto;
  left: calc(50% + var(--2px));
  color: var(--black);
  box-shadow: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.header-label {
  composes: headerItemLeftDivider;
  margin-inline-end: var(--4px);
  padding-inline-start: var(--4px);
}
