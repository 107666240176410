/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
.selectedHeader {
  composes: semiBold from 'utils.css';
  display: flex;
  padding: var(--3px) 0;
  color: var(--blue-gray-500);
  border-bottom: 1px solid var(--blue-gray-100);
}

.selectedRow {
  display: flex;
  padding: var(--3px) 0;
  color: var(--gray-800);

  + .selectedRow {
    border-top: 1px solid var(--blue-gray-100);
  }
}

.selectedNumber {
  composes: bold from 'utils.css';
  min-width: var(--60px);
  text-align: center;
}

.selectedDes {
  width: calc(5 * var(--100px));
  padding: 0 var(--20px);
}

.selectedType {
  min-width: calc(2.25 * var(--100px));
  max-width: calc(5 * var(--100px));
  padding: 0 var(--20px);
}

.selectedAction {
  padding-bottom: var(--5px);
}

.radioGroupTitle {
  composes: bold from 'utils.css';
}

.formAttributes-list {
  margin-top: 0;
}

.form-radio {
  padding: var(--10px) var(--15px);
  border: 1px solid var(--blue-gray-100);
  background: var(--blue-gray-50);

  .formAttributes-key {
    padding-left: var(--1px);
  }
}

.close-icon {
  margin: 0 var(--10px);
  padding: var(--5px);
  font-size: var(--8px);
  color: var(--gray-500);
  cursor: pointer;
  &:hover {
    color: var(--black);
  }
}
