/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */

.svg {
  transform: scale(0.75);
}

.add-svg {
  composes: svg;
  color: var(--green-800);
}

.remove-svg {
  composes: svg;
  color: var(--red-700);
}
