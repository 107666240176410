/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */

.numberWidget {
  --number-widget-number-font-size: var(--64px);
  --number-widget-suffix-font-size: inherit;
  --number-widget-suffix-margin-l: var(--10px);
  --number-widget-number-block-padding-v: var(--offset-small);
  --number-widget-number-block-padding-h: var(--offset-large);
}

.container {
  composes: gapXLarge gapHorizontal from 'utils.css';
  margin-top: auto;

  @nest html[data-theme='classic'] & {
    margin-bottom: var(--27px);
  }
}

.sideNumberContainer {
  margin: auto;
}

.numberBlock {
  composes: gapSmall gap from 'utils.css';
  padding: var(--number-widget-number-block-padding-v) var(--number-widget-number-block-padding-h);
  cursor: default;
  border-radius: var(--offset-small);

  @nest html[data-theme='lightning'] & {
    --number-widget-number-block-padding-v: var(--0px);
    --number-widget-number-block-padding-h: var(--0px);
  }

  @nest html[data-theme='classic'] & {
    padding: var(--offset-small) var(--offset-large);
  }
}

.numberBlockHoverable {
  composes: numberBlock;
  cursor: pointer;

  @nest html[data-theme='classic'] & {
    &:hover {
      background-color: var(--metrics-card-highlight-bg-color);
    }
  }
}

.numberBlockClickable {
  composes: numberBlock;
  cursor: pointer;
}

.sideNumberBlock {
  display: flex;
  flex-direction: row;
}

.numberBox {
  vertical-align: baseline;
  line-height: 1;

  .number {
    font-size: var(--number-widget-number-font-size);
    font-weight: var(--fontWeightBold);
  }

  @nest html[data-theme='lightning'] & {
    --number-widget-number-font-size: var(--40px);
  }

  @nest html[data-theme='classic'] & {
    --number-widget-number-font-size: var(--64px);
  }
}

.centerAligned {
  display: flex;
  justify-content: center;
  align-items: center;
}

.leftSideNumberBox {
  padding-right: var(--36px);

  @nest html[data-theme='lightning'] & {
    border-right: var(--1px) solid var(--metrics-card-leftSideNumberBox-border-color);
  }
}

.rightSideNumberBox {
  padding-left: var(--36px);
}

.suffix {
  margin-left: var(--number-widget-suffix-margin-l);
  font-size: var(--number-widget-suffix-font-size);

  @nest html[data-theme='lightning'] & {
    --number-widget-suffix-font-size: var(--18px);
    --number-widget-suffix-margin-l: var(--offset-small);

    font-weight: var(--fontWeightBold);
    color: var(--metrics-card-suffix-font-color);
  }

  @nest html[data-theme='classic'] & {
    --number-widget-suffix-margin-l: var(--offset-small);
  }
}

.suffixAligned {
  display: flex;
  flex-direction: row;
  align-items: end;
}

.sideNumberSuffix {
  display: table-cell;
  height: var(--number-widget-number-font-size);
  vertical-align: middle;
}

.sideNumberTitle {
  margin-bottom: var(--6px);

  @nest html[data-theme='lightning'] & {
    --number-widget-sideNumberTitle-margin-l: var(--offset-small);

    font-weight: var(--fontWeightBold);
    color: var(--metrics-card-sideNumberTitle-font-color);
  }
}
