/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */

.selector {
  position: relative;
  font-size: var(--14px);
}

/* Search box and input styles */

.search {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 30px;
  border: 1px solid var(--gray-100);
  background-color: var(--white);

  &.error {
    border-color: var(--form-error-border-color);
  }
}

.items {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: center;
  padding: var(--1px);
  overflow: hidden;
}

.itemContainer {
  display: flex;
  flex: 0 1 auto;
  flex-wrap: wrap;
}

.item {
  max-width: 100%;
  margin: var(--2px);
}

.labelWrapper {
  display: flex;
}

.input {
  --input-min-width: var(--100px);

  flex: 1 1 auto;
  min-width: var(--input-min-width);
  height: var(--24px);
  margin: var(--2px) var(--2px) var(--2px) var(--5px);
  outline: none;
  border: none;
}

input::placeholder {
  font-weight: var(--fontWeightLight);
  color: var(--gray-550);
}

.close {
  display: flex;
  align-items: center;
  width: var(--25px);
  padding: 0 var(--5px);
  cursor: pointer;

  .icon {
    margin-left: var(--2px);
    font-size: 0.7em;
  }
}

.clearAll {
  cursor: pointer;

  .icon {
    font-size: 0.7em;
    color: var(--gray-400);
  }

  .icon:hover {
    color: var(--gray-200);
  }
}

.active {
  border: 1px solid var(--blue-700);
}

.disabled {
  background-color: var(--blue-gray-50);

  & input {
    background-color: var(--blue-gray-50);
  }
}

/* dropdown styles */

.dropdown {
  position: absolute;
  z-index: 120;
  margin-top: calc(-1 * var(--1px));
  list-style: none;
  box-shadow: 0 0 4px 0 rgb(var(--black-rgb) / 0.1);
}

.content {
  width: calc(27.5 * var(--10px));
  border: 1px solid var(--gray-200);
}

.categoryList {
  background-color: var(--gray-50);
}

.hidden {
  display: none;
}

.scroll {
  max-height: 250px;
  overflow-y: scroll;
}

.scrollCaret {
  font-size: 0.6em;
}

.scrollbar {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  transform: scaleY(0);
  transition: transform 150ms linear, margin 150ms linear;
}

.scrollbarUp {
  composes: scrollbar;
  margin-bottom: calc(-1 * var(--18px));
  transform-origin: top;
}

.scrollbarDown {
  composes: scrollbar;
  margin-top: calc(-1 * var(--18px));
  transform-origin: bottom;
}

.scrollbarUp::after,
.scrollbarDown::after {
  position: absolute;
  content: ' ';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.showScrollbarUp {
  margin-bottom: 0;
  box-shadow: 0 0 4px 0 rgb(var(--black-rgb) / 0.1);
  transform: scaleY(1);

  &::before {
    box-shadow: 0 0 20px rgb(var(--black-rgb) / 0.25);
  }

  &::after {
    border-bottom: 1px solid var(--gray-100);
  }
}

.showScrollbarDown {
  margin-top: 0;
  box-shadow: 0 0 4px 0 rgb(var(--black-rgb) / 0.1);
  transform: scaleY(1);

  &::before {
    box-shadow: 0 0 20px rgb(var(--black-rgb) / 0.25);
    transition: var(--shadow-depth-transition-out);
  }

  &::after {
    border-top: 1px solid var(--gray-100);
  }
}

/* option styles in dropdown */

.activeOptionContainer {
  border-bottom: 1px solid var(--gray-200);
  background-color: var(--white);

  &:only-child {
    border-bottom: none;
  }
}

.option {
  padding: var(--8px) var(--5px) var(--8px) var(--10px);
  overflow-wrap: break-word;
  cursor: pointer;
  overflow: hidden;

  &:focus {
    outline: none;
    background-color: var(--blue-gray-100);
  }

  &.doubleIndent {
    padding-left: var(--20px);
  }

  .text,
  .text > div {
    /* stylelint-disable-next-line value-no-vendor-prefix */
    display: -webkit-box;
    white-space: initial;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.labelOption {
  padding: var(--5px) var(--5px) var(--5px) var(--10px);
}

.category {
  composes: bold from 'utils.css';
  padding: var(--8px) 0 var(--8px) var(--10px);
  cursor: pointer;
  background-color: var(--gray-50);

  &:focus {
    outline: none;
    background-color: var(--blue-gray-100);
  }
}

.partial {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.disabled {
  color: var(--gray-300);
  user-select: none;
  pointer-events: none;
}

.optionContainer {
  padding: var(--8px) 0 var(--8px) var(--10px);
  border-top: 1px solid var(--gray-200);
}

.optionContainer:first-child {
  border-top: none;
}

.title {
  composes: bold from 'utils.css';
  padding: var(--8px) 0 var(--8px) var(--10px);
}

.titledOptionList {
  .option {
    padding-left: var(--20px);
  }

  .hint {
    padding-left: var(--20px);
  }

  .checkbox + .hint {
    padding-left: var(--37px);
  }
}

.title ~ .custom {
  padding-left: var(--20px);
}

.bold {
  composes: bold from 'utils.css';
}

.custom {
  composes: optionContainer;

  &.doubleIndent {
    padding-left: var(--20px);
  }
}

.checkbox {
  display: flex;
  align-items: center;

  & input {
    margin-right: var(--5px);
  }
}

.count {
  composes: optionContainer;
  composes: bold from 'utils.css';
  border-top: 1px solid var(--gray-200);
  background-color: var(--white);
}

.hint {
  padding: var(--8px) 0 var(--8px) var(--10px);
  font-style: italic;
  color: var(--gray-550);
}

.results {
  padding: var(--8px) 0 var(--8px) var(--20px);
}

.secondary {
  composes: light from 'utils.css';
  font-size: var(--12px);
  color: var(--gray-550);
}
