/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.table-minWidth_640 {
  .areaHead .cell {
    white-space: nowrap;
  }

  .areaBody .cell.ipVersion {
    text-align: right;
  }
}
