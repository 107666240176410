/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

.input {
  width: var(--50px);
  appearance: none;
  line-height: var(--inputAndtextArea-line-height);
  cursor: pointer;
  border: none;
  background-color: transparent;

  &::-webkit-color-swatch {
    border: var(--1px) solid var(--gray-400);
    border-radius: var(--10px);
  }

  &::-moz-color-swatch {
    height: var(--inputAndtextArea-line-height);
    border: var(--1px) solid var(--gray-400);
    border-radius: var(--10px);
  }

  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    height: var(--30px);
    border-radius: var(--5px);
  }
}
