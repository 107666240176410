/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
.wrapper {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.text {
  font-weight: var(--fontWeightBold);
  fill: var(--blue-900);
  text-anchor: middle;
}

.background {
  fill: var(--gray-100);
}

.foreground {
  &:focus {
    outline: none;
  }

  &:hover {
    .seg {
      opacity: 0.8;
    }

    .label {
      fill: var(--blue-700);
    }
  }
}

.label {
  fill: var(--gray-500);
}

.legend {
  margin: 0;
  padding: 0;
  list-style: none;

  & li {
    display: flex;
    align-items: center;
    margin-top: var(--8px);
    font-size: var(--12px);

    &:first-child {
      margin-top: 0;
    }
  }
}

.legendBullet {
  display: inline-block;
  width: var(--10px);
  height: var(--10px);
  margin-right: var(--5px);
  border-radius: var(--3px);
}
