/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
.header {
  --header-icon-color: var(--gray-500);
  --header-icon-color-hover: var(--text-color);

  composes: fixedHeight from './Modal.css';
  display: flex;
  z-index: var(--shadow-depth-2z);
  flex-direction: row;
  align-items: stretch;
  min-height: var(--51px); /* 50px + 1px border */
  padding: 0 var(--offset-medium) 0 var(--modal-content-h-padding);
  overflow: unset;
  border-top-left-radius: var(--modal-border-radius);
  border-top-right-radius: var(--modal-border-radius);
  border-bottom: 1px solid var(--modal-separator-color);
  transition: var(--shadow-depth-transition-out);

  @nest html:not([data-theme='lightning']) & {
    background-color: var(--blue-gray-50);
  }

  @nest html[data-theme='lightning'] & {
    --header-icon-color: var(--lightning--blue-gray-900);
    --header-icon-color-hover: var(--lightning--blue-gray-600);

    align-items: flex-start;
    padding: var(--24px) var(--34px) var(--18px);
    border: none;
  }
}

.headerShadow {
  box-shadow: var(--shadow-depth-2);
}

.title {
  composes: bold from 'utils.css';
  display: flex;
  flex: 1 1 auto; /* Make it grow to allocate whole header */
  align-items: center;
  min-width: 0;

  .titleText {
    font-size: var(--18px);
    color: var(--blue-gray-700);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @nest html[data-theme='lightning'] & {
      font-size: var(--20px);
      color: var(--lightning--blue-gray-900);
      white-space: normal;
    }
  }
}

.close {
  display: flex;
  flex: 0 0 auto; /* Fix its size */
  align-items: center; /* Put unterline icon into vertical center */
  margin-right: calc(-1 * var(--10px)); /* Hover area of close icon should spread until right side of modal */
  padding: 0 var(--10px);
  font-size: var(--10px);
  color: var(--header-icon-color);
  cursor: pointer;

  &:hover {
    color: var(--header-icon-color-hover);
  }

  @nest html[data-theme='lightning'] & {
    padding-top: var(--4px);
    font-size: var(--16px);
  }
}
