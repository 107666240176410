/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */

.checkbox {
  position: relative;
  display: inline-flex;
  align-items: baseline; /* To align checkbox with the first line of an optional label */
}

.hidden :is(.box, .customBox-icon) {
  visibility: hidden;
}

.noText::before {
  /* In case of lonely checkbox without a text add unicode zero width space character to keep inline height */
  content: '\200b';
}

.input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.labelBox,
.labelText,
.labelSubText {
  cursor: pointer;
  outline: none;
  user-select: none;
}

.labelBox {
  position: relative;
}

.labelText,
.labelSubText {
  display: block;
  font-size: var(--13px);
}

.side {
  flex: 1 1 auto;
}

.nestedElements {
  composes: gapLarge paddingMediumTop from 'utils.css';
}

.labelText,
.labelSubText,
.nestedElements {
  padding-right: var(--14px);
  padding-left: var(--5px);
}

/* Classic square box */
.box {
  --box-size: 1em;
  --box-border-color: var(--gray-400);
  --box-border-width: var(--1px);
  --box-fill-color: var(--blue-700);
  --box-fill-size: 0;
  --box-fill-full-size: calc(var(--box-size) / 2);

  composes: centeredFlex from 'utils.css';
  position: relative;
  display: inline-flex;
  width: var(--box-size);
  height: var(--box-size);
  vertical-align: -0.175em;
  box-shadow: inset 0 0 0 var(--box-border-width) var(--box-border-color),
    inset 0 0 0 var(--box-fill-size) var(--box-fill-color);
  background-color: var(--white);

  .checkbox:not(.checked):hover & {
    --box-border-color: var(--blue-400);
  }

  .checkbox &.boxFilled {
    --box-border-width: 0;
    --box-fill-size: var(--box-fill-full-size);
  }

  .checkbox.error:not(:hover) & {
    --box-border-width: var(--1px);
    --box-border-color: var(--red-600);
  }

  .checkbox.disabled & {
    --box-border-color: var(--gray-100);
    --box-fill-color: var(--blue-gray-50);
    --box-fill-size: var(--box-fill-full-size);
  }

  .checkbox.disabled &.boxFilled {
    --box-fill-color: var(--gray-200);
    --box-fill-size: var(--box-fill-full-size);
  }

  .box-icon {
    display: flex;
    line-height: 1;
    color: var(--white);
  }

  .box-svg {
    opacity: 0;
    transform: scale(0);
  }

  &.boxFilled .box-svg {
    opacity: 1;
    transform: scale(0.7);
  }

  &.boxHidden {
    visibility: hidden;
  }

  /* Align box slightly differently in Safari */
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    vertical-align: -0.125em;
  }
}

/* Custom icon instead of a classic box */
.customBox-svg {
  stroke: currentcolor;
  stroke-width: var(--1px);

  .checkbox:not(.checked):hover & {
    stroke: var(--blue-400);
  }

  .checkbox:not(.disabled).checked & {
    fill: var(--blue-700);
    stroke: var(--blue-700);
  }

  .checkbox.error:not(.disabled, .checked):hover &,
  .checkbox.error:not(.disabled, :hover).checked & {
    stroke: var(--red-600);
  }

  .checkbox.disabled & {
    stroke: var(--gray-300);
  }

  .checkbox.disabled.checked & {
    fill: var(--gray-300);
    stroke: var(--gray-300);
  }
}

.customBoxSingle-icon,
.customBoxSame-icon,
.customBoxDifferent-icon {
  composes: customBox-icon;
}

.customBoxSingle-svg,
.customBoxSame-svg,
.customBoxDifferent-svg {
  composes: customBox-svg;
}

.customBoxSingle-svg {
  fill: var(--white);
  stroke: var(--white);
}

.customBoxSame-svg {
  fill: var(--white);
  stroke: var(--gray-700);
}

.customBoxDifferent-svg {
  .checkbox.disabled & {
    fill: var(--gray-300);
  }
}

/* Always animate focus glow */
.box,
.customBox-svg {
  transition: filter 0.2s ease-out;

  .labelBox:focus-within & {
    filter: drop-shadow(0 0 2px var(--blue-500));
  }
}

/* Animate status change only if user clicks on this checkbox with mouse (by hovering) or with keyboard (by focusing) */
/* To prevent animating many other controlled checkboxes that can be affected by this checkbox change or other events */
.checkbox:is(:hover, :focus-within) {
  .box {
    transition: filter 0.2s ease-out, box-shadow 0.2s ease-out;
  }

  .box-svg {
    /* On uncheck fade out icon while box-shadow is animating, delaying size check */
    transition: transform 0.1s 0.1s ease-out, opacity 0.1s ease-out;
  }

  .customBox-icon .customBox-svg {
    transition: filter 0.2s ease-out, fill 0.2s ease-out, stroke 0.2s ease-out;
  }

  /* On check start size change animation after box-shadow animatiion is almost done, don't animate opacity */
  &.checked {
    .box {
      transition: filter 0.2s ease-out, box-shadow 0.15s linear;
    }

    .box-svg {
      transition: transform 0.15s 0.1s ease-out;
    }
  }
}
