/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */

.modal {
  flex-direction: row;
  overflow: hidden;
}

.timer {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: calc(1.25 * var(--100px));
  padding: var(--offset-medium) var(--offset-large);
  color: var(--white);
  background: var(--orange-500);
}

.number {
  composes: semiBold monoSpace from 'utils.css';
  font-size: var(--56px);
  line-height: 1;
}

.unit {
  font-size: var(--24px);
}

.body {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: var(--offset-medium) var(--offset-large);
}

.text {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
}

.title {
  composes: bold monoSpace from 'utils.css';
  font-size: var(--16px);
}

.buttons {
  composes: gapSmall gapHorizontal from 'utils.css';
  flex: 0 0 auto;
  justify-content: flex-end;
}
