/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */

.label {
  composes: gapInline gapXSmall gapHorizontal centerFlexAlign from 'utils.css';
  cursor: pointer;

  /* #FirefoxHAS - replace with &:has(.input:checked) */
  &.on {
    --switch-background-color: var(--lightning--blue-500);

    &:hover {
      --switch-background-color: var(--lightning--blue-400);
    }

    &:active,
    &:has(.input:active) {
      --switch-background-color: var(--lightning--blue-600);
    }

    &.disabled {
      --switch-background-color: var(--lightning--blue-300);
    }
  }

  /* #FirefoxHAS - replace with &:not(:has(.input:checked)) */
  &.off {
    --switch-background-color: var(--lightning--gray-300);

    &:hover {
      --switch-background-color: var(--lightning--gray-200);
    }

    &:active,
    &:has(.input:active) {
      --switch-background-color: var(--lightning--gray-400);
    }

    &.disabled {
      --switch-background-color: var(--lightning--gray-200);
    }
  }

  /* #FirefoxHAS - replace with &:has(.input:disabled) */
  &.disabled {
    --switch-label-color: var(--lightning--gray-600);
    --switch-handle-color: var(--lightning--gray-25);

    pointer-events: none;
  }

  &:not(.disabled):hover,
  &:has(.input:focus-visible) {
    --switch-handle-shadow: var(--1px) var(--1px) var(--2px) rgb(var(--lightning--gray-900-rgb) / 0.1),
      var(--2px) var(--2px) var(--4px) rgb(var(--lightning--gray-900-rgb) / 0.1);
  }
}

/* Sizes */
.small {
  --switch-width: var(--45px);
  --switch-height: var(--24px);
  --switch-handle-size: var(--18px);
  --switch-border-radius: var(--20px);
  --switch-text-inner-font-size: var(--10px);

  & svg {
    font-size: var(--10px);
  }
}

.large {
  --switch-width: var(--60px);
  --switch-height: var(--32px);
  --switch-handle-size: var(--24px);
  --switch-border-radius: var(--20px);
  --switch-text-inner-font-size: var(--14px);
}

/* Label text before/after the witch */
.text {
  color: var(--switch-label-color, currentColor);
}

/* The switch container itself */
.switch {
  --switch-handle-offset: calc((var(--switch-height) - var(--switch-handle-size)) / 2);
  --switch-outline-duration: 0.1s;
  --switch-flip-duration: 0.2s;
  --switch-stretch-delay: 0.1s; /* To make the stretching on press not to go off immediately on a quick click */

  position: relative;
  display: flex;
  align-items: center;
  min-width: var(--switch-width);
  height: var(--switch-height);
  line-height: 1.4;
  outline: var(--2px) solid transparent;
  outline-offset: var(--2px);
  user-select: none;
  border-radius: var(--switch-border-radius);
  background-color: var(--switch-background-color);
  transition: outline-color var(--switch-outline-duration) linear,
    background-color var(--switch-flip-duration) ease-in-out;

  &:has(.input:focus-visible) {
    outline-color: var(--lightning--blue-200);
  }
}

/* Hidden checkbox input */
.input {
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
  opacity: 0;
}

/* Handle element, which sets its size and position */
.handle {
  position: absolute;
  inset-block-start: var(--switch-handle-offset);
  inset-inline-start: var(--switch-handle-offset);
  width: var(--switch-handle-size);
  height: var(--switch-handle-size);
  transition: inset var(--switch-flip-duration) ease-in-out;

  /* Move handle to the right when on */
  .label.on & {
    inset-inline-start: calc(100% - var(--switch-handle-size) - var(--switch-handle-offset));
  }

  /* A pseudo-element which is the actual visible element relative to its parent */
  &::before {
    position: absolute;
    content: '';
    inset: 0;
    border-radius: calc(var(--switch-handle-size) / 2);
    box-shadow: var(--switch-handle-shadow, none);
    background-color: var(--switch-handle-color, var(--lightning--white));
    transition: box-shadow var(--switch-flip-duration) linear, inset var(--switch-flip-duration) ease-in-out;
  }

  .label:active &::before,
  .label:has(.input:active) &::before {
    transition-delay: var(--switch-stretch-delay);
  }

  /* Stretch the handle to the right when off switch is pressed  */
  .label.off:active &::before,
  .label.off:has(.input:active) &::before {
    inset-inline-end: -30%;
  }

  /* Stretch the handle to the left when on switch is pressed */
  .label.on:active &::before,
  .label.on:has(.input:active) &::before {
    inset-inline-start: -30%;
  }
}

/* The optional text inside the switch, before/after the handle */
.textInner {
  --switch-innerText-padding-from-border: calc(3 * var(--switch-handle-offset));
  --switch-innerText-padding-from-handle: calc(var(--switch-handle-size) + var(--switch-innerText-padding-from-border));
  --switch-innerText-margin-one: calc(-100% - var(--switch-innerText-padding-from-handle) - var(--4px));
  --switch-innerText-margin-two: calc(100% + var(--switch-innerText-padding-from-handle) + var(--4px));

  font-size: var(--switch-text-inner-font-size);
  text-align: center;
  white-space: nowrap; /* Only one line is allowed */
  overflow: hidden; /* Before/after text will be sliding withing this element */
  pointer-events: none;
  border-radius: var(--switch-border-radius);
  transition: padding-inline var(--switch-flip-duration) ease-in-out;

  .label.off & {
    padding-inline: var(--switch-innerText-padding-from-handle) var(--switch-innerText-padding-from-border);
  }

  .label.on & {
    padding-inline: var(--switch-innerText-padding-from-border) var(--switch-innerText-padding-from-handle);
  }
}

.textInnerOn,
.textInnerOff {
  transition: margin-inline var(--switch-flip-duration) ease-in-out;

  .label:active &,
  .label:has(.input:active) & {
    transition-delay: var(--switch-stretch-delay);
  }
}

.textInnerOn {
  color: var(--lightning--white);

  .label.off & {
    margin-inline: var(--switch-innerText-margin-one) var(--switch-innerText-margin-two);
  }

  .label.on & {
    margin-inline: 0;
  }

  .label.on:active &,
  .label.on:has(.input:active) & {
    margin-inline: calc(-1 * var(--4px)) var(--4px);
  }

  .label.disabled & {
    color: var(--lightning--gray-25);
  }
}

.textInnerOff {
  /* To make the whole textInner stretch to the width of the widest on/off element, */
  /* we need to make the second one go to the next line (both are blocks), but then lift it back up */
  margin-block-start: calc(-1.4 * var(--switch-text-inner-font-size));
  color: var(--lightning--blue-gray-700);

  .label.off & {
    margin-inline: 0;
  }

  .label.off:active &,
  .label.off:has(.input:active) & {
    margin-inline: var(--4px) calc(-1 * var(--4px));
  }

  .label.on & {
    margin-inline: var(--switch-innerText-margin-two) var(--switch-innerText-margin-one);
  }

  .label.disabled & {
    color: var(--lightning--gray-600);
  }
}

/* #FirefoxHAS - Fallback to focus-within from :has(:focus-visible) Remove when Firefox supports :has() */
@supports not selector(:has(+ *)) {
  .label:not(.disabled):hover,
  .switch:focus-within {
    --switch-handle-shadow: var(--1px) var(--1px) var(--2px) rgb(var(--lightning--gray-900-rgb) / 0.1),
      var(--2px) var(--2px) var(--4px) rgb(var(--lightning--gray-900-rgb) / 0.1);
  }

  .switch:focus-within {
    outline-color: var(--lightning--blue-200);
  }
}
