/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
.menu {
  --menu-item-margin-v: var(--0px);
  --menu-item-margin-h: var(--0px);
  --menu-item-content-padding-v: var(--0px);
  --menu-dropdown-background: var(--blue-gray-800);
  --menu-dropdown-shadow: var(--shadow-depth-6);
}

.trigger {
  composes: headerItem headerItemClickable from '../Header/HeaderOld.css';
}

.openedTrigger,
.openedTrigger:hover {
  background-color: var(--blue-800);
}

.dropdown {
  z-index: var(--shadow-depth-6z);
  margin-top: var(--0px);
  /* Only bottom corners on main dropdown should be rounded */
  border-color: var(--blue-gray-700);
  border-radius: 0 0 var(--menu-dropdown-radius) var(--menu-dropdown-radius);
}

.dropdownWithArrow {
  margin-top: 0;
}

.subDropdown {
  margin-top: calc(-1 * var(--6px));
  border-radius: var(--menu-dropdown-radius); /* All corners on sub dropdowns should be rounded */
}

.delimiter {
  margin: 0;
  border-color: var(--blue-gray-100);
}

.itemsList {
  /* Width 150px - 260px */
  min-width: calc(1.5 * var(--100px));
  max-width: calc(2.6 * var(--100px));
}

.item {
  padding-left: var(--0px);
  color: var(--blue-gray-200);

  &:focus {
    color: var(--blue-gray-100);
    background-color: var(--blue-gray-900);

    .itemContent {
      border-left-color: var(--cyan-a200);
    }
  }
}

.itemContent {
  padding: var(--7px) /* Full line is 34: 7+20+7 */ var(--0px) var(--7px) var(--12px);
  border-left: var(--4px) solid transparent;
}

.delimiter {
  border-top-color: var(--blue-gray-700);
}

.drillIcon {
  color: var(--blue-gray-400);
}

.activeItem:not(:focus) {
  color: var(--blue-300);
  .drillIcon {
    color: var(--blue-300);
  }
}

.activeItemContent:not(:focus) {
  border-left: var(--4px) solid var(--blue-300);
}

.activeDropdown:not(.activeItem) {
  color: var(--blue-gray-100);
  background-color: var(--blue-gray-900);
  .itemContent {
    border-left: var(--4px) solid var(--cyan-a200);
  }
}
