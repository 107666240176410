/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
/* providers and providingServices are the names of the property in Grid config */
.areaHead {
  .providers-customCellHeader,
  .providingServices-customCellHeader {
    color: var(--blue-gray-800);
    background-color: var(--indigo-200);

    &:hover {
      background-color: var(--indigo-300);
    }
  }
}

.diffRow {
  composes: row from 'components/Diff/Diff.css';
  /* To vertically align the +/- icons with the pills */
  line-height: var(--pill-normal-total-height);
}

.joinerPill-pill {
  .joinerPill-text {
    padding-left: var(--1px);
  }
  .joinerPill-content {
    --pill-content-vertical-padding: var(--1px);
    --pill-content-horizontal-padding: var(--2px);

    @nest html[data-theme='lightning'] & {
      --pill-content-horizontal-padding: var(--2px);
    }
  }
}

.valuePill-pill {
  .valuePill-content {
    outline: none;
    border: none;
  }
}

.filteringTips {
  composes: bold gapXSmall gapHorizontalWrap from 'utils.css';
  color: var(--blue-800);
}

.formatOption {
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
}

.buttons {
  composes: gapXSmall gapHorizontal from 'utils.css';
  display: flex;
  justify-content: flex-end;
}

.divider {
  margin-top: calc(-1 * var(--5px));
  border-top: var(--1px) solid var(--blue-gray-200);
}

.inspectIcon {
  font-size: 1.3em;
  color: var(--blue-gray-700);
}

.edit,
.more {
  font-size: 1em;
  color: var(--lightning--blue-gray-600);
  border: none;
}

.edit-icon,
.more-icon {
  font-size: 1.2em;
  color: var(--lightning--blue-gray-600);
  cursor: pointer;
}

.noIconMenuItem-text {
  margin-left: 1.4em;
}

.headerIcon-icon {
  color: var(--yellow-800);
}

.rowToEnable .areaBody {
  background-color: var(--blue-gray-150);
}

.widget {
  composes: gapHorizontal gapSmall centeredFlex from 'utils.css';
  justify-content: space-between;
}

.scopeWidget-pill {
  @nest html[data-theme='lightning'] & {
    --pill-outline-color: var(--lightning--blue-gray-400);
  }
}

.addNew-primary {
  --card-background-color: var(--green-a100);

  @nest html[data-theme='lightning'] & {
    --card-background-color: var(--lightning--green-50);

    border-radius: 0 var(--8px) var(--8px) 0;
    border-left: var(--2px) solid var(--lightning--green-300);
  }
}

.view-primary {
  border-left: var(--3px) solid var(--blue-200);

  @nest html[data-theme='lightning'] & {
    --card-background-color: var(--lightning--gray-25);

    border-radius: 0 var(--8px) var(--8px) 0;
    border-left: var(--2px) solid var(--lightning--blue-400);
  }
}

.scopeSubtitle {
  composes: light from 'utils.css';
  font-style: italic;
}

.addNewServiceText {
  color: var(--blue-800);
  user-select: none;
}

.svg {
  transform: scale(0.75);
}

.add-svg {
  composes: svg;
  color: var(--green-600);
}

.remove-svg {
  composes: svg;
  color: var(--red-600);
}

.tooltipMessageTitle {
  composes: semiBold from 'utils.css';
  text-decoration: underline;
}

.key,
.value {
  color: unset;
}

.key {
  composes: normal from 'utils.css';

  @nest html[data-theme='lightning'] & {
    color: var(--lightning--blue-gray-300);
  }
}

.value {
  composes: light from 'utils.css';

  @nest html[data-theme='lightning'] & {
    color: var(--lighting--white);
  }
}

.tooltip .content {
  min-width: calc(3 * var(--100px));
  padding: var(--20px);
}

.tooltipContentRowStatus {
  max-height: var(--100px);
  overflow-y: auto;
}

.note-icon {
  font-size: 1.2em;
  color: var(--gray-400);
  cursor: pointer;

  @nest html[data-theme='lightning'] & {
    color: var(--lightning--blue-gray-600);
  }
}

.selectorMain,
.statementsEditor {
  background-color: var(--white);
}

.link {
  color: var(--blue-300);
}

.disabledRules .areaBody .cell {
  &:not(.state, .buttons) {
    opacity: 0.5;
  }
}

.added,
.modified,
.removed {
  .svg {
    transform: scale(0.8);
  }
}

.menuItemsList {
  max-width: calc(4.8 * var(--100px));
}

.menuItem-disabled {
  cursor: initial;
  pointer-events: auto;
}

.menuItem-text {
  composes: noIconMenuItem-text;
}

.actions {
  display: flex;
}

.status-badge {
  @nest html[data-theme='lightning'] & {
    padding: var(--4px) var(--8px);
    text-transform: none;
  }
}

.status-created {
  @nest html[data-theme='lightning'] & {
    background: var(--lightning--green-50);
  }
}

.status-updated {
  @nest html[data-theme='lightning'] & {
    background: var(--lightning--blue-50);
  }
}

.status-deleted {
  @nest html[data-theme='lightning'] & {
    background: var(--lightning--red-50);
  }
}
