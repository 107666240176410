/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

.tile {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 3fr var(--40px);
  height: 100%;
}

.title {
  font-size: var(--20px);
  font-weight: var(--fontWeightBold);
}

.card {
  padding: 0;
  border-color: var(--gray-100);
  border-radius: var(--5px);
}

.row {
  --attribute-list-key-padding: var(--10px);
}

.key {
  padding-left: var(--5px);
}

.key,
.value {
  padding: var(--5px);
  background: var(--gray-50);
}

.contentSection,
.linkSection {
  padding: var(--card-padding);
}

.linkSection {
  border-top: var(--1px) solid var(--gray-100);
}

.placeholderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  & img {
    width: calc(3 * var(--100px));
  }
}
