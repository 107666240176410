/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */

@keyframes placeHolderShimmer {
  0% {
    opacity: 0.3;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.3;
  }
}

.skeleton {
  --skeleton-background: var(--blue-gray-100);

  max-width: 100%;
  max-height: 100%;
  color: transparent;
  user-select: none;
  pointer-events: none;
  animation: 1.5s linear 0s infinite forwards placeHolderShimmer;
}

.skeletonBlock {
  composes: gap from 'utils.css';
}

.xsmall {
  composes: gapXSmall from 'utils.css';
}

.small {
  composes: gapSmall from 'utils.css';
}

.medium {
  composes: gapMedium from 'utils.css';
}

.large {
  composes: gapLarge from 'utils.css';
}

.xlarge {
  composes: gapXLarge from 'utils.css';
}

.skeletonText {
  color: transparent;
  user-select: none;
  border-radius: var(--4px);
  background-color: var(--skeleton-background);
}

.skeletonLine {
  composes: gapSmall gapHorizontal from 'utils.css';
}

.skeletonBox {
  width: 100%;
  height: var(--20px);
  border-radius: var(--4px);
  background-color: var(--skeleton-background);
}
