/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */

.content {
  font-size: var(--13px);
  font-weight: var(--fontWeight);
  color: var(--gray-550);
}

.icon {
  composes: selectorAligned from 'components/Form/OptionSelector/OptionSelector.css';
}

.areaBody .checkboxCell-labelBox {
  display: inline-flex;
  align-items: center;
  height: calc(
    var(--optionSelector-height) - var(--1px) + var(--body-area-vertical-padding) + var(--body-cell-vertical-padding)
  );
}

.table {
  .cell.cellPadding.modified {
    padding-right: 0;
    padding-left: 0;
  }

  .cell.cellPadding.error {
    padding-right: 0;
  }
}
