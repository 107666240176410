/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */

.status-badge {
  min-width: var(--60px);
}

.message {
  display: flex;
  justify-content: center;
}

.confidence {
  font-size: var(--14px);
}

.icon {
  color: var(--blue-600);
}
