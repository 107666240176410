/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */

:root {
  --grid-manager-radius: 0;
  --grid-top-left-radius: var(--12px);
  --grid-top-right-radius: var(--12px);
}

.table {
  /* Header area doesn't have vertical padding, so header cell has bigger value, to hightligh sorting properly */
  --head-area-vertical-padding: 0;
  --head-cell-vertical-padding: var(--6px);
  /* Body area has vertical padding, but first/last cell will add it to themselves on top/bottom */
  --body-area-vertical-padding: var(--4px);
  --body-cell-vertical-padding: var(--4px);
  /* Cell usually should have the same left/right paddings to be alinged properly with header/body */
  --cell-horizontal-padding: var(--12px);

  display: grid;
  grid-auto-rows: min-content;

  @nest html[data-theme='lightning'] & {
    --cell-horizontal-padding: var(--16px);
  }
}

.row {
  display: contents;
}

.rowHead {
  composes: row;

  @nest html[data-theme='lightning'] & {
    .areaHead:nth-child(2) {
      border-left: var(--1px) solid var(--lightning--gray-200);
    }

    .areaHead:last-child {
      border-right: var(--1px) solid var(--lightning--gray-200);
    }

    .areaHeadRadius:nth-child(2) {
      border-top-left-radius: var(--grid-top-left-radius);
    }

    .areaHeadRadius:last-child {
      border-top-right-radius: var(--grid-top-right-radius);
    }
  }
}

.rowBody {
  composes: row;

  @nest html[data-theme='lightning'] & {
    .areaBody:nth-child(2) {
      border-left: var(--1px) solid var(--lightning--gray-200);
    }

    .areaBody:last-child {
      border-right: var(--1px) solid var(--lightning--gray-200);
    }
  }
}

/* Grid area that can contain several cells */
.area {
  display: flex;
  flex-direction: column;
}

.areaHead {
  composes: area;
  composes: semiBold from 'utils.css';
  padding: var(--head-area-vertical-padding) 0;
  color: var(--blue-gray-500);
  border-bottom: 2px solid var(--blue-gray-100);
  background-color: var(--white);

  .cellSortable {
    cursor: pointer;
    /* Sortable cell should have custom inset shadow on focus to prevent overlapping it by sibling cells */
    &:focus {
      outline: none;
      box-shadow: inset 0 0 0 0.1rem rgb(var(--blue-400-rgb) / 0.5);
    }

    &:active {
      box-shadow: none;
    }

    /* Sortable cell has background on hover */
    &:hover {
      background-color: var(--blue-gray-100);
    }
  }

  .cellSortSelection {
    /* A button with dropdown menu is rendered for sort selection options */
    .sortSelectionButton-menu-trigger {
      justify-content: flex-start;
      padding: var(--head-cell-vertical-padding) var(--cell-horizontal-padding);
    }
  }

  @nest html[data-theme='lightning'] & {
    border-top: var(--1px) solid var(--lightning--gray-200);
    border-bottom: 0;
  }
}

.sortSelectionButton-menu-menu {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

.sortSelectionButton-menu-item {
  padding-left: var(--5px);
  font-weight: unset;
}

.areaHeadPrimaryGrid {
  border-bottom: var(--1px) solid var(--blue-gray-100);
  background-color: var(--blue-gray-50);

  @nest html[data-theme='lightning'] & {
    border-bottom: none;
    background: var(--lightning--blue-gray-50);
  }
}

/* areaHeadEmpty is used to add padding when there is no cells inside. */
/* For instance, when using column.header prop we render text instead of all cells in header  */
.areaHeadEmpty,
.areaHead .cell:not(.cellSortSelection) {
  padding: var(--head-cell-vertical-padding) var(--cell-horizontal-padding);
}

.headerDesc {
  composes: normal from 'utils.css';
  color: var(--gray-600);
}

.areaBody {
  composes: area;
  composes: areaBody from './GridUtils.css';
  padding: 0;
  color: var(--gray-800);
  border-bottom: var(--1px) solid var(--blue-gray-100);

  .cell.cellPadding {
    padding: var(--body-cell-vertical-padding) var(--cell-horizontal-padding);

    /* Helper to extend cell content vertically to take whole cell height */
    /* Useful if you have custom cell content that has it's onw click event that should fire on whole cell click */
    .cellContentVExpand {
      margin-top: calc(-1 * var(--body-cell-vertical-padding));
      margin-bottom: calc(-1 * var(--body-cell-vertical-padding));
      padding-top: var(--body-cell-vertical-padding);
      padding-bottom: var(--body-cell-vertical-padding);
    }

    /* Helper to extend cell content horizontally to take whole cell width */
    .cellContentHExpand {
      margin-right: calc(-1 * var(--cell-horizontal-padding));
      margin-left: calc(-1 * var(--cell-horizontal-padding));
      padding-right: var(--cell-horizontal-padding);
      padding-left: var(--cell-horizontal-padding);
    }

    &:first-child {
      padding-top: calc(var(--body-area-vertical-padding) + var(--body-cell-vertical-padding));

      .cellContentVExpand {
        margin-top: calc(-1 * (var(--body-area-vertical-padding) + var(--body-cell-vertical-padding)));
        padding-top: calc(var(--body-area-vertical-padding) + var(--body-cell-vertical-padding));
      }
    }

    &:last-child {
      padding-bottom: calc(var(--body-area-vertical-padding) + var(--body-cell-vertical-padding));

      .cellContentVExpand {
        margin-bottom: calc(-1 * (var(--body-area-vertical-padding) + var(--body-cell-vertical-padding)));
        padding-bottom: calc(var(--body-area-vertical-padding) + var(--body-cell-vertical-padding));
      }
    }
  }

  @nest html[data-theme='lightning'] & {
    color: var(--lightning--blue-gray-900);
    border-top: var(--1px) solid var(--lightning--blue-gray-200);
    border-bottom: none;
  }
}

.rowBody:last-child {
  @nest html[data-theme='lightning'] & {
    .areaBody {
      border-bottom: var(--1px) solid var(--lightning--blue-gray-200);
    }
  }
}

/* In case of empty cell, we should apply normal line-height to it, we can do it by adding empty symbol */
/* Otherwise cell will collapse what can break hover state on sorting cell in head */
.areaHead .cell:not(.cellSortSelection)::before,
.areaBody .cell:empty::before {
  content: '\200b'; /* unicode zero width space character */
}

/* Grid item */
.cell {
  position: relative;
  flex: 0 0 auto;
  max-width: 100%; /* To prevent the cell with the content inside from overflowing the parent area */
  overflow-wrap: break-word;
}

/* Render cell content like it's a link, but decorate cell text with underline on whole row hover */
.linky {
  composes: link from 'components/Link/Link.css';

  @nest html[data-theme='lightning'] & {
    color: var(--lightning--blue-500);
  }
}

.checkboxCell-box {
  @nest html[data-theme='lightning'] & {
    --box-border-color: var(--lightning--blue-gray-500);
    --box-fill-color: var(--lightning--blue-500);

    border-radius: var(--2px);
  }
}

.areaHead .checkboxCell-labelBox {
  padding: var(--head-cell-vertical-padding) var(--cell-horizontal-padding);
}

.areaBody .checkboxCell-labelBox {
  padding: calc(var(--body-area-vertical-padding) + var(--body-cell-vertical-padding)) var(--cell-horizontal-padding);
}

.cell.rowStatus {
  .areaHead & {
    padding-right: var(--6px);
  }

  .areaBody & {
    --cell-horizontal-padding: var(--0px); /* Icon itself will have horizontal padding to extend tooltip hover area */
    /* Center content horizontally when the cell is wider than the icon, for example when it stacks with other columns */
    display: flex;
    justify-content: center;
  }
}

.emptyMessage {
  margin-top: var(--30px);
}

.cellHeadSort-iconsSort {
  position: absolute;
  display: none;
  top: var(--head-cell-vertical-padding);
  bottom: var(--head-cell-vertical-padding);
  left: 0;
  width: var(--cell-horizontal-padding);
  line-height: 1;
}

/* Show sort icons when sortable column header hovered or if column is sorted */
.cell:hover,
.cellSorted {
  .cellHeadSort-iconsSort {
    display: flex;
  }
}

.asterisk {
  padding: 0 var(--5px);
  color: var(--red-700);
}

.cellHeadSortVisible-iconsSort {
  composes: cellHeadSort-iconsSort;
  display: flex;
}

.rowBodySelected .areaBody {
  background-color: var(--lightning--blue-25);
}

.focuser {
  position: relative; /* To position absolute loader inside */
  z-index: 3; /* To make loader overlay the cells
                (keep z-index at 3 since some elements like group pill
                already have z-index of 1 which conflicts with this z-index) */
  outline: none; /* To remove focus glow */
}

.loader {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  height: 100%;
  opacity: 0;
  background-color: rgb(var(--blue-gray-400-rgb) / 0.6);
  animation: fadeIn 0.25s linear forwards;

  @nest html[data-theme='lightning'] & {
    background-color: rgb(var(--lightning--blue-gray-200-rgb) / 0.8);
  }
}

.loader-spinner {
  width: var(--22px);
  height: var(--22px);
  margin: unset;
  border-width: var(--4px);
  border-color: rgb(var(--blue-gray-100-rgb) / 0.8) rgb(var(--blue-gray-100-rgb) / 0.8)
    rgb(var(--blue-gray-100-rgb) / 0.8) var(--blue-600);
}

.rowBodyClickable:not(.rowBodySelected) {
  cursor: pointer;

  .areaBody {
    transition: background-color 0.1s linear;

    @nest html[data-theme='lightning'] & {
      border-top: var(--1px) solid var(--lightning--blue-gray-200);
    }
  }

  &.focused {
    .linky {
      text-decoration: underline;
    }

    .areaBody {
      background-color: var(--gray-50);

      /* need to target first and last areaBody for left and right border */
      @nest html[data-theme='lightning'] & {
        border-top-color: var(--lightning--blue-300);
        border-bottom-color: var(--lightning--blue-300);
        background-color: var(--lightning--blue-gray-25);
      }
    }

    @nest html[data-theme='lightning'] & {
      .areaBody:nth-child(2) {
        border-left: var(--1px) solid var(--lightning--blue-300);
      }

      .areaBody:last-child {
        @nest html[data-theme='lightning'] & {
          border-right: var(--1px) solid var(--lightning--blue-300);
        }
      }
    }
  }

  &:active {
    @nest html[data-theme='lightning'] & {
      .areaBody {
        background: var(--lightning--blue-50);
      }
    }
  }

  &.rowBodySelected {
    &.focused {
      .areaBody {
        background-color: var(--blue-gray-100);
      }
    }
  }

  @nest html[data-theme='lightning'] & {
    .areaBody:nth-child(2) {
      border-left: var(--1px) solid var(--lightning--blue-gray-200);
    }

    .areaBody:last-child {
      border-right: var(--1px) solid var(--lightning--blue-gray-200);
    }

    &.focused + .rowBodyClickable {
      .areaBody {
        border-top-color: var(--lightning--blue-300);
      }
    }
  }
}

.rowBodyInsensitive,
.rowBodyInsensitive * {
  pointer-events: none;
}

/*
 * Class declarations for sizes similar to @media but for grid container size rather than window size
 * Class name will be applied when container width is inside specified boundaries
 * Class names extracted by getMaxWidthClasses function in Grid.js
 *
 * Format: .table[-minWidth_XXX][-maxWidth_XXX]
 * Examples:
 * .table-minWidth_1281 Class will be applied when grid container width is 1281 and more
 * .table-minWidth_961-maxWidth_1280 Class will be applied when grid container width is inside [961, 1280] interval
 * .table-maxWidth_960 Class will be applied when grid container width is 960 and less
 */

/* @media (min-width: 961px) and (max-width: 1280px) { */
.table-minWidth_961-maxWidth_1280 {
  --head-cell-vertical-padding: var(--4px);
  --body-area-vertical-padding: var(--3px);
  --body-cell-vertical-padding: var(--3px);
}

/* @media (min-width: 481px) and (max-width: 960px) { */
.table-minWidth_481-maxWidth_960 {
  --head-cell-vertical-padding: var(--2px);
  --body-area-vertical-padding: var(--2px);
  --body-cell-vertical-padding: var(--2px);
}

/* @media (max-width: 480px) { */
.table-maxWidth_480 {
  --head-cell-vertical-padding: var(--1px);
  --body-area-vertical-padding: var(--2px);
  --body-cell-vertical-padding: var(--2px);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.grab {
  position: absolute;
  width: 100%;
  padding: calc(var(--body-area-vertical-padding) + var(--body-cell-vertical-padding)) var(--cell-horizontal-padding);
  color: var(--blue-gray-400);
  cursor: move;

  &.disabled {
    color: var(--gray-100);
    cursor: default;
    pointer-events: none;

    @nest html[data-theme='lightning'] & {
      border-top: 1px solid var(--lightning--gray-200);
      border-bottom: 1px solid var(--lightning--gray-200);
      background: var(--lightning--gray-200);
    }
  }

  &:hover .grab-icon {
    color: var(--blue-gray-600);
  }

  @nest html[data-theme='lightning'] & {
    color: var(--lightning--blue-gray-400);
    cursor: grab;
  }
}
