/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.roleInput {
  display: flex;
  padding-bottom: var(--20px);
  cursor: pointer;
}

.roleText {
  padding-left: var(--10px);
}

.roleLabel {
  composes: bold from 'utils.css';
}

.levelIndent {
  padding-left: var(--20px);
}

.infoBox {
  display: flex;
}

.abilityTable {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  margin-left: var(--10px);
  padding: var(--10px) 0;
  border: var(--2px) dashed var(--blue-200);
}

.abilityRow {
  display: flex;
  justify-content: flex-start;
}

.abilityResource {
  composes: bold from 'utils.css';
  display: inline-block;
  min-width: 240px;
  padding-right: var(--30px);
  text-align: right;
}

.abilityName {
  display: inline-block;
  min-width: 200px;
}

.content {
  flex-grow: 1;
  max-width: 50%;
  padding: var(--20px);
  border: 1px solid var(--gray-300);
  background: var(--gray-50);

  &.contentBorder {
    border-right: 4px solid #dee1e3ff;
  }
}

.info {
  flex-grow: 1;
}

.arrow {
  align-self: center;
  width: 1px;
  height: 1px;
  border-top: 42px solid transparent;
  border-bottom: 40px solid transparent;
  border-left: 42px solid #dee1e3ff;
}

@media screen and (width <= 800px) {
  /* max-width: 800px was the breakpoint decided to stack */
  .content {
    max-width: 100%;

    &.contentBorder {
      border-bottom: 4px solid #dee1e3ff;
    }
  }

  .info {
    margin-top: var(--10px);
  }

  .infoBox {
    display: block;
  }

  .abilityTable {
    min-width: 0;
    margin-top: 0;
    margin-left: 0;
  }

  .arrow {
    width: 0;
    height: 0;
    margin-right: auto;
    margin-left: auto;
    border-top: 40px solid #dee1e3ff;
    border-right: 41px solid transparent;
    border-bottom: 0;
    border-left: 40px solid transparent;
  }
}
