/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.removedText {
  color: var(--red-200);
  text-decoration: line-through;
}

.addedText {
  color: var(--green-400);
}

.modalGap {
  composes: gapMedium from 'utils.css';
}
