/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */

.notifications {
  --notification-padding: var(--offset-medium);
  --notification-message-padding-left: var(--20px);
  --notification-message-font-size: var(--14px);
  --notification-title-font-size: var(--16px);
  /* Types */
  --instruction-border-color: var(--blue-100);
  --instruction-background-color: var(--blue-50);
  --instruction-sidebar-color: var(--blue-200);
  --success-border-color: var(--green-100);
  --success-background-color: var(--green-50);
  --success-sidebar-color: var(--green-200);
  --warning-border-color: var(--yellow-100);
  --warning-background-color: var(--yellow-50);
  --warning-sidebar-color: var(--yellow-200);
  --error-border-color: var(--red-100);
  --error-background-color: var(--red-50);
  --error-sidebar-color: var(--red-200);
  --notice-text-color: var(--gray-400);
  --notification-close—icon-color: var(--gray-500);

  @nest html[data-theme='lightning'] & {
    --instruction-border-color: var(--lightning--blue-100);
    --instruction-background-color: var(--lightning--blue-25);
    --instruction-sidebar-color: var(--lightning--blue-400);
    --success-border-color: var(--lightning--green-200);
    --success-background-color: var(--lightning--green-25);
    --success-sidebar-color: var(--lightning--green-400);
    --warning-border-color: var(--lightning--orange-200);
    --warning-background-color: var(--lightning--orange-25);
    --warning-sidebar-color: var(--lightning--orange-400);
    --error-border-color: var(--lightning--red-100);
    --error-background-color: var(--lightning--red-25);
    --error-sidebar-color: var(--lightning--red-400);
    --notice-text-color: var(--lightning--gray-400);
    --side-bar-width: var(--4px);
    --notification-border-radius: var(--6px);
    --notification-close—icon-color: var(--lightning--blue-700);
  }
}

.notifications {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.shrink {
  margin-right: auto;
  margin-left: auto;

  @media screen and (768px <= width <= 1600px) {
    width: 66%;
  }

  /*
    Width should stay the same while widening up from 1600 until it exceeds 50% of width,
    to avoid width jump when 1600 changes to 1601
    2112px = 0.66 * 1600 / 0.5
  */
  @media screen and (1601px <= width <= 2112px) {
    width: calc(0.66 * 1600px); /* 1056 */
  }

  @media screen and (width >= 2113px) {
    width: 50%;
  }
}

.notification {
  composes: gapMedium gapHorizontal from 'utils.css';
  padding-right: var(--notification-padding);
  padding-left: var(--notification-padding);
  border: 1px solid transparent;
  border-radius: var(--2px);

  > * {
    display: flex;
    align-items: center;

    &:not(.buttonItem) {
      padding-top: var(--notification-padding);
      padding-bottom: var(--notification-padding);
    }
  }

  @nest html[data-theme='lightning'] & {
    border-radius: var(--notification-border-radius);
  }
}

.notification.sidebar {
  @nest html[data-theme='lightning'] & {
    padding-left: calc(var(--notification-padding) + var(--side-bar-width));
  }
}

.compact {
  --notification-padding: var(--notification-border-radius);
  --notification-message-font-size: var(--13px);
  --notification-title-font-size: var(--15px);
}

.sidebar {
  border-left: 5px solid;

  @nest html[data-theme='lightning'] & {
    position: relative;
    border-left: 0;

    &::before {
      position: absolute;
      content: '';
      bottom: calc(-1 * var(--1px));
      left: 0;
      width: var(--side-bar-width);
      height: calc(100% + var(--2px));
      border-top-left-radius: var(--notification-border-radius);
      border-bottom-left-radius: var(--notification-border-radius);
    }
  }
}

.status-icon {
  font-size: var(--17px);
  color: var(--white);
  user-select: none;
}

/* Notification types */
.instruction {
  composes: notification;
  border-color: var(--instruction-border-color);
  border-left-color: var(--instruction-sidebar-color);
  background-color: var(--instruction-background-color);

  .status-icon {
    color: var(--blue-600);
  }

  .title {
    color: var(--blue-800);
  }

  @nest html[data-theme='lightning'] & {
    border-left-color: var(--instruction-border-color);

    .title {
      color: var(--lightning--blue-700);
    }
  }
}

.instruction.sidebar {
  border-left-color: var(--instruction-sidebar-color);

  @nest html[data-theme='lightning'] & {
    &::before {
      background-color: var(--instruction-sidebar-color);
    }
  }
}

.success {
  composes: notification;
  border-color: var(--success-border-color);
  border-left-color: var(--success-sidebar-color);
  background-color: var(--success-background-color);

  .status-icon {
    color: var(--green-700);
  }

  .title {
    color: var(--green-800);
  }

  @nest html[data-theme='lightning'] & {
    border-left-color: var(--success-border-color);

    .title {
      color: var(--lightning--green-600);
    }
  }
}

.success.sidebar {
  @nest html[data-theme='lightning'] & {
    &::before {
      background-color: var(--success-sidebar-color);
    }
  }
}

.warning {
  composes: notification;
  border-color: var(--warning-border-color);
  border-left-color: var(--warning-sidebar-color);
  background-color: var(--warning-background-color);

  .status-icon {
    color: var(--yellow-800);
  }

  .title {
    color: var(--yellow-800);
  }

  @nest html[data-theme='lightning'] & {
    border-left-color: var(--warning-border-color);

    .title {
      color: var(--lightning--orange-600);
    }
  }
}

.warning.sidebar {
  @nest html[data-theme='lightning'] & {
    &::before {
      background-color: var(--warning-sidebar-color);
    }
  }
}

.error {
  composes: notification;
  border-color: var(--error-border-color);
  border-left-color: var(--error-sidebar-color);
  background-color: var(--error-background-color);

  .status-icon {
    color: var(--red-600);
  }

  .title {
    color: var(--red-600);
  }

  @nest html[data-theme='lightning'] & {
    border-left-color: var(--error-border-color);

    .title {
      color: var(--lightning--red-600);
    }
  }
}

.error.sidebar {
  @nest html[data-theme='lightning'] & {
    &::before {
      background-color: var(--error-sidebar-color);
    }
  }
}

.notice {
  composes: notification;
  color: var(--notice-text-color);

  .status-icon {
    color: var(--notice-text-color);
  }

  .title {
    color: var(--notice-text-color);
  }
}

.mainItem {
  flex-grow: 1;
}

.title {
  display: inline-block;
  word-break: normal;
  overflow-wrap: normal;
}

.titleWithMessage {
  composes: title;
  composes: bold from 'utils.css';
  padding-right: var(--notification-message-padding-left);
  font-size: var(--notification-title-font-size);
}

.titleWithoutMessage {
  composes: title;
  composes: semiBold from 'utils.css';
  font-size: var(--notification-title-font-size);
}

.message {
  display: inline-block;
  font-size: var(--notification-message-font-size);
}

.buttonItem {
  composes: gapSmall gapHorizontal from 'utils.css';
  user-select: none;
}

.close-icon {
  margin-right: calc(-1 * var(--notification-padding));
  margin-left: calc(-1 * var(--notification-padding) / 2);
  padding-right: calc(var(--notification-padding));
  padding-left: calc(var(--notification-padding) / 2);
  font-size: 0.6em;
  cursor: pointer;
  user-select: none;

  &:hover {
    color: inherit;
  }

  @nest html[data-theme='lightning'] & {
    font-size: var(--13px);
    color: var(--notification-close—icon-color);

    &:hover {
      color: var(--lightning--blue-500);
    }
  }
}
