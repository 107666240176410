/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
.container {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding-top: var(--10px);
}

.skeletonContainer {
  padding: var(--20px);
}

.gridContainer {
  position: relative;
  flex-grow: 1;
  margin-top: var(--8px);
}

.gridContainerScrollable {
  position: absolute;
  inset: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

.tools {
  padding: 0 var(--10px);
}

.viewRuleError {
  padding: var(--20px);
}

.viewRuleModal {
  max-height: 80vh;
  padding: var(--5px);
  overflow: scroll;
}

.link {
  font-weight: var(--fontWeightBold);
  text-decoration: underline;
  cursor: pointer;
}

.extrascope {
  margin: calc(-1 * var(--20px));
  line-height: var(--5px);
}

.scope-toolBar {
  padding: var(--10px) 0 var(--5px) 0;
}

.ruleset-toolBar {
  padding-top: var(--10px);
  padding-bottom: var(--15px);
}

.ruleset-toolGroup {
  width: 100%;
}

.ruleset-toolGroupItem {
  &:last-child {
    width: 80%;
  }
}

.rulesetLabel {
  padding-left: var(--25px);
  font-weight: var(--fontWeightBold);
}

.rulesetOptions {
  display: flex;
}

.RulesetOptionName {
  flex-shrink: 0;
  flex-basis: 40%;
  padding-left: var(--20px);
}

.rulesetOptionScope {
  flex-grow: 1;
  padding-left: var(--5px);
}

.newRuleset {
  padding-left: var(--5px);
}

.settings {
  padding: var(--10px);

  @nest html[data-theme='lightning'] & {
    padding: var(--30px);
  }
}

.outOfScope {
  padding-top: var(--10px);
}

.scope {
  flex-grow: 1;
}

.title {
  @nest html[data-theme='lightning'] & {
    margin-top: var(--20px);
    padding: var(--20px) 0 var(--10px) var(--20px);
    font-size: var(--13px);
    font-weight: var(--fontWeightSemiBold);
    color: var(--lightning--gray-800);
    text-transform: none;
    border-top: 1px solid var(--lightning--gray-200);
  }
}
