/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.redBoldText {
  composes: bold from 'utils.css';
  color: var(--red-300);
}

.defaultReadOnly {
  display: flex;
  align-items: center;
}

.defaultReadOnlyDescription {
  padding: 0 var(--15px);
}

.globalRoleDetail {
  padding-top: var(--20px);
}

/* Set maximum AttributeList width to 1500px */
.list {
  max-width: calc(15 * var(--100px));
}

.abilityTable {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  min-width: calc(4.5 * var(--100px));
  height: calc(2.5 * var(--100px));
  padding: var(--10px) 0;

  @media (width <= 1152px) {
    height: 100%;
  }
}

.abilityRow {
  display: flex;
  justify-content: flex-start;
}

.abilityResource {
  composes: bold from 'utils.css';
  display: inline-block;
  width: 200px;
  padding-right: var(--30px);
  text-align: right;
}

.abilityName {
  display: inline-block;
  min-width: 200px;
}
