/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
.pageContainer {
  composes: gapMedium from 'utils.css';
}

.oldPageContainer {
  composes: gapMedium from 'utils.css';
  display: flex;
  flex-direction: column;
  margin: var(--offset-small);
  gap: var(--offset-medium);
}

.pairingScriptWrapper {
  display: flex;
  background: var(--lightning--blue-50);
}

.oldPairingScriptWrapper {
  display: flex;
  background: var(--blue-50);
}

.pairingScriptText {
  padding: var(--5px) var(--6px);
  line-height: var(--offset-x-large);
  word-break: break-all;
}

.h1 {
  font-size: var(--36px);
  font-weight: var(--fontWeight);
}

.h2 {
  font-weight: var(--fontWeightLight);
}

.h3 {
  font-size: var(--18px);
  font-weight: var(--fontWeightSemiBold);
}

.p {
  margin: 0;
}

.oldP {
  margin: 0;
  font-size: var(--16px);
  font-weight: var(--fontWeightLight);
}

.selectorMainWrapper {
  width: calc(3 * var(--100px));
}

.link {
  color: var(--lightning--blue-500);

  &:hover {
    cursor: pointer;
  }
}

.oldLink {
  color: var(--blue-500);

  &:hover {
    cursor: pointer;
  }
}

.info {
  color: var(--blue-600);
}

.xpressUIToggle {
  display: flex;
  grid-area: toggle;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.xpressNavigation {
  display: grid;
  grid-template-columns: calc(1.5 * var(--100px)) min-content calc(1.5 * var(--100px));
  grid-template-areas: 'placeholder navigation toggle';
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.placeholder {
  grid-area: placeholder;
}

.navigation {
  grid-area: navigation;
  align-self: flex-end;
}

.xpressHeaderIcon {
  margin-left: var(--offset-large);
}

.primary {
  --tabpanel-border-color: transparent;
  --tabpanel-height: var(--35px);
}

.tooltip {
  box-shadow: var(--shadow-depth-2);
  filter: drop-shadow(0 0 1px var(--blue-gray-200));

  .content {
    padding: var(--5px) var(--0px);
  }
}

.text {
  margin-left: var(--5px);
}

.itemContentWithIcon {
  color: var(--gray-700);

  &:hover {
    color: var(--blue-600);
    background-color: var(--gray-100);
  }
}

.itemContentWithIconActive {
  composes: itemContentWithIcon;
  color: var(--blue-600);
}

/* Need high specificity to override pendo badge margin property */
button[aria-label='small-badge-5'] {
  /* stylelint-disable-next-line declaration-no-important */
  margin: var(--5px) !important;
}

button[aria-label='small-badge-10'] {
  /* stylelint-disable-next-line declaration-no-important */
  margin: var(--10px) !important;
}

button[aria-label='small-badge-15'] {
  /* stylelint-disable-next-line declaration-no-important */
  margin: var(--15px) !important;
}

button[aria-label='small-badge-20'] {
  /* stylelint-disable-next-line declaration-no-important */
  margin: var(--20px) !important;
}

button[aria-label='small-badge-25'] {
  /* stylelint-disable-next-line declaration-no-important */
  margin: var(--25px) !important;
}
