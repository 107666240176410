/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */

.added {
  color: var(--green-800);
}

.removed {
  color: var(--red-800);
  text-decoration: line-through;
}

.iconItem {
  color: var(--yellow-800);
}

.iconItemRemove {
  color: var(--red-600);
}

.visibility {
  color: var(--gray-800);
}

.error {
  color: var(--red-600);
}

.areaBody {
  border-bottom: unset;
}

/* providers and providingServices are the names of the property in Grid config */
.areaHead {
  .providers-customCellHeader,
  .providingServices-customCellHeader {
    color: var(--blue-gray-800);
    background-color: var(--indigo-200);

    &:hover {
      background-color: var(--indigo-300);
    }
  }
}

.areaBody .cell.arrow {
  /* Align with the Pill */
  line-height: var(--24px);
}

.content {
  font-size: var(--13px);
  color: var(--gray-550);
}

.warning {
  font-size: var(--13px);
  color: var(--red-500);
}

.iconSpinner {
  width: var(--24px);
  height: var(--24px);
  border-width: var(--4px);
}
