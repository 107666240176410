/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */

.scopePickerRow {
  composes: gapLarge gapHorizontal from 'utils.css';
}

.scopePickerWrapped {
  composes: gapMedium from 'utils.css';
}

.scopeItem {
  composes: gapXSmall from 'utils.css';
  flex: 1 1 33%;
  max-width: 100%;
}

.iconContainer {
  display: flex;
  flex: 0 0 auto;
  line-height: var(--32px);

  .scopePickerRow & {
    padding-top: calc(var(--gap-row-xsmall) + var(--fontSize) * 1.4);
  }

  .scopePickerWrapped & .arrow-icon {
    transform: rotate(90deg);
  }
}

.networkType {
  composes: gapXSmall gapHorizontal from 'utils.css';
  line-height: 1.7;
}

.buttons {
  composes: gapXSmall gapHorizontal from 'utils.css';
  display: flex;
  justify-content: flex-end;
}

.addNewServiceText {
  color: var(--blue-800);
  user-select: none;
}
