/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
.title {
  composes: gapXSmall gapHorizontal bold from 'utils.css';
  display: flex;
  align-items: center;
  font-size: var(--legend-title-font-size, var(--14px));

  @nest html[data-theme='lightning'] & {
    --legend-title-font-size: var(--12px);

    font-weight: var(--fontWeightSemiBold);
    color: var(--lightning--blue-gray-900);
  }
}

.nodeWrapper {
  display: flex;
  justify-content: space-between;
}

.contentWrapper {
  composes: gapSmall from 'utils.css';

  @nest html[data-theme='lightning'] & {
    flex-direction: column-reverse;
  }
}

.legendWrapper {
  composes: gapLarge gapHorizontal from 'utils.css';
  display: flex;

  @nest html[data-theme='lightning'] & {
    gap: var(--offset-small);
  }
}

.disabled {
  color: var(--gray-300);
}

.legend {
  padding-right: var(--legend-padding-right, var(--5px));

  @nest html[data-theme='lightning'] & {
    --legend-padding-right: 0;
  }
}

.legendColor {
  width: var(--legend-color-height, var(--12px));
  height: var(--legend-color-width, var(--12px));

  @nest html[data-theme='lightning'] & {
    --legend-color-height: var(--8px);
    --legend-color-width: var(--8px);

    border-radius: var(--2px);
  }
}
