/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.tallyAlignment {
  display: flex;
}

.left {
  composes: tallyAlignment;
  justify-content: flex-start;
}

.right {
  composes: tallyAlignment;
  justify-content: flex-end;
}

.center {
  composes: tallyAlignment;
  justify-content: center;
}

.tallies {
  --gap-row: var(--offset-xsmall); /* To reduce vertical offset in case of wrapping */
  --tallies-border-radius: var(--2px);
  --tally-vertical-padding: var(--offset-xsmall);
  --tally-horizontal-padding: var(--offset-large);
  --tally-underline-size: var(--1px);

  composes: gapLarge gapHorizontalWrap centerFlexAlign from 'utils.css';
  max-width: 100%;
  min-height: var(--30px);
  padding: var(--tally-vertical-padding) var(--tally-horizontal-padding);
  color: var(--tally-text-color);
  border-radius: var(--tallies-border-radius);
  box-shadow: inset 0 0 0 1px var(--tally-border-color);
  background-color: var(--tally-background-color);

  @nest html[data-theme='lightning'] & {
    --tallies-border-radius: var(--6px);
    --tally-horizontal-padding: var(--offset-small);
    --gap-row: 0;
    --gap-column: 0;

    min-height: unset;
    padding: 0;
    font-size: var(--15px);
    overflow: hidden;
    border: 1px solid var(--tally-border-color);
    box-shadow: unset;
  }
}

.info {
  --tally-text-color: var(--blue-800);
  --tally-border-color: var(--blue-100);
  --tally-background-color: var(--blue-50);

  composes: tallies;

  &:hover {
    --tally-background-color: var(--blue-100);
  }

  @nest html[data-theme='lightning'] & {
    --tally-text-color: var(--lightning--blue-700);
    --tally-border-color: var(--lightning--blue-200);
    --tally-background-color: var(--lightning--blue-50);
    --tally-background-color-hover: var(--lightning--blue-75);
    --tally-background-color-active: var(--lightning--blue-100);
    --tally-background-color-focus: var(--lightning--blue-75);
    --tally-outline-color-focus: var(--lightning--blue-500);
  }
}

.warning {
  --tally-text-color: var(--orange-800);
  --tally-border-color: var(--yellow-100);
  --tally-background-color: var(--yellow-50);

  composes: tallies;

  &:hover {
    --tally-background-color: var(--yellow-100);
  }

  @nest html[data-theme='lightning'] & {
    --tally-text-color: var(--lightning--orange-700);
    --tally-border-color: var(--lightning--orange-200);
    --tally-background-color: var(--lightning--orange-50);
    --tally-background-color-hover: var(--lightning--orange-25);
    --tally-background-color-active: var(--lightning--orange-100);
    --tally-background-color-focus: var(--lightning--orange-25);
    --tally-outline-color-focus: var(--lightning--orange-500);
  }
}

.error {
  --tally-text-color: var(--red-700);
  --tally-border-color: var(--red-100);
  --tally-background-color: var(--red-50);

  composes: tallies;

  &:hover {
    --tally-background-color: var(--red-100);
  }

  @nest html[data-theme='lightning'] & {
    --tally-text-color: var(--lightning--red-600);
    --tally-border-color: var(--lightning--red-200);
    --tally-background-color: var(--lightning--red-50);
    --tally-background-color-hover: var(--lightning--red-25);
    --tally-background-color-active: var(--lightning--red-100);
    --tally-background-color-focus: var(--lightning--red-25);
    --tally-outline-color-focus: var(--lightning--red-500);
  }
}

.tally {
  color: inherit; /* To make sure link will inherit info/warn/error colors */

  @nest html[data-theme='lightning'] & {
    padding: var(--offset-xsmall) var(--offset-small);

    &:hover:not(:active) {
      background-color: var(--tally-background-color-hover);
    }

    &:active {
      background-color: var(--tally-background-color-active);
    }

    &.tallyUnderline {
      padding-bottom: calc(var(--tally-vertical-padding) - var(--tally-underline-size));
    }
  }
}

.underline {
  line-height: var(--16px);
  border-bottom: var(--tally-underline-size) solid transparent;
}

/* A special underline behavior to align underlines of .count and .label even thought the are not aligned */
.tallyUnderline {
  &:hover:not(:active),
  &:focus-visible:not(:active) {
    text-decoration: none; /* Turn off the underline on Tally itself */

    .underline {
      padding-top: var(--1px);
      /* Turn on individual underlines on .count and .label */
      border-color: currentcolor;

      @nest html[data-theme='lightning'] & {
        padding-top: unset;
      }
    }
  }

  &:focus-visible:not(:active) {
    @nest html[data-theme='lightning'] & {
      outline: 1px solid var(--tally-outline-color-focus);
      outline-offset: calc(-1 * var(--1px));
      border-bottom: none;
      box-shadow: unset;
      background-color: var(--tally-background-color-focus);

      .underline {
        border-color: transparent;
      }
    }
  }
}

/* Tally that has 'onClick' prop (but not a link 'prop'), should still look like Link */
.tallyClickable {
  composes: tallyUnderline;
  composes: link from 'components/Link/Link.css';
  cursor: pointer;
}

/* Tally that has a 'link' prop */
.tallyLink-link {
  composes: tally tallyUnderline;
}

.count {
  composes: bold monoSpace from 'utils.css';
  vertical-align: calc(-1 * var(--1px)); /* To align it with the label since its two pixels higher */
  font-size: var(--16px);
  line-height: 1;

  @nest html[data-theme='lightning'] & {
    vertical-align: unset;
  }
}

.label {
  composes: semiBold from 'utils.css';
  padding-left: var(--offset-xsmall);
  word-break: break-word;
  overflow-wrap: break-word;
}
