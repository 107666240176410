/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */

.enabledText {
  color: var(--gray-800);
}

.button-textIsHideable {
  @media (width <= 1300px) {
    display: none;
  }
}

.toolBar {
  flex-wrap: nowrap;
  align-items: flex-start;
}

.actionButtons {
  width: 25%;
}

.wrapper {
  align-self: center;
  width: 50%;
}

.nextButtons {
  composes: gapSmall gapHorizontal from 'utils.css';
  justify-content: flex-end;
}
