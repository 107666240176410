/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */

.toolBar {
  composes: gapMedium gapHorizontalWrap from 'utils.css';
  align-items: center;
  border: none;
}

.toolGroup {
  composes: gapSmall gapHorizontalWrap from 'utils.css';
  align-items: center;
}

.toolGroupExpand {
  flex: 1 1 100%;
  min-width: 0; /* Allow block children to shrink and fit into 100% */

  .toolGroupItem {
    flex: 1 1 100%;
    max-width: 100%;
  }
}

.selected {
  color: var(--green-300);
}

.link {
  color: var(--blue-300);
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
