/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
.pageContainer {
  composes: gapXLarge from 'utils.css';
  margin: var(--offset-x-large) var(--offset-small) 0;
}

.contentWrapper {
  display: grid;
  grid-template-columns: repeat(2, calc(6.5 * var(--100px)));
  gap: calc(2 * var(--offset-x-large));

  @media (width <= 1450px) {
    grid-template-columns: repeat(2, 1fr);
    gap: var(--offset-x-large);
  }

  @media (width <= 850px) {
    grid-template-columns: 1fr;
  }
}

.h1 {
  font-size: var(--36px);
}

.h2 {
  font-weight: var(--fontWeightBold);
}
