/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

.card {
  padding: var(--20px) var(--20px) var(--20px) var(--30px);
  border: var(--3px) solid var(--gray-100);
  border-radius: var(--10px);
  transition: background-color 100ms linear;

  &:hover {
    cursor: pointer;
    background-color: var(--gray-50);
  }
}

.pillGap {
  composes: gapXSmall gapHorizontalWrap from 'utils.css';
}

.iconStyle {
  color: var(--green-600);
}

.title {
  composes: bold from 'utils.css';
  font-size: var(--16px);
  color: var(--blue-gray-400);
}

.description {
  margin: unset;
}

.cardsContainer {
  composes: gapMedium from 'utils.css';
  max-height: calc(var(--100px) * 4);
  margin: 0;
  padding: 0;
  overflow-y: scroll;
}

.areaHead .destination,
.areaHead .services {
  color: var(--blue-gray-800);
  background-color: var(--indigo-200);
}
