/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.online-icon {
  color: var(--green-800);
}

.offline-icon {
  color: var(--gray-100);
}

.invited-icon {
  color: var(--blue-800);
}

.locked-icon {
  color: var(--red-700);
}

.activityDetails-list {
  margin-bottom: var(--40px);
}

.userActivityStatus {
  padding-left: var(--7px);
}
