/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

/* If content should get out of default page's padding */
.containerWidth {
  width: calc(100% + var(--page-horizontal-padding) * 2);
  margin-left: calc(-1 * var(--page-horizontal-padding));
}

/* Fix jumping width, for example counter, using https://twitter.com/wesbos/status/932644812582522880 */
.monoSpace {
  /* Enable tabular (monospaced) figures */
  font-feature-settings: 'tnum';
  /* Activate the set of figures where numbers are all of the same size, */
  /* allowing them to be easily aligned like in tables. It corresponds to the tnum. */
  font-variant-numeric: tabular-nums;
}

/* In browsers if font-weight: 300, bolder is equal to 400, but we want 500 and also have ability to set 400 */
/* So introduce custom composition helpers to override default browser heuristic on resolving 'bolder' values: */
/* When font-weight: 400, .bold equals to 600, .semiBold equals to 500, .light equals to 300 */
/* When font-weight: 300, .bold equals to 500, .semiBold equals to 400, .light equals to 200 */
/* To use in css-modules, add 'composes: bold from 'utils.css';' into your class declaration */
.bold {
  font-weight: var(--fontWeightBold);
}
/* To use in css-modules, add 'composes: semiBold from 'utils.css';' into your class declaration */
.semiBold {
  font-weight: var(--fontWeightSemiBold);
}
/* To use in css-modules, add 'composes: light from 'utils.css';' into your class declaration */
.light {
  font-weight: var(--fontWeightLight);
}
/* Needed only if you want to override previous one set by parent */
.normal {
  font-weight: var(--fontWeight);
}

.inlineBlock {
  display: inline-block;
}

.inlineFlex {
  display: inline-flex;
}

/* Different types of white-space */
.breakSpaces {
  white-space: break-spaces;
}

.pre {
  white-space: pre;
}

.preWrap {
  white-space: pre-wrap;
}

.noWrap {
  white-space: nowrap;
}

/* Old/new helper classes for red strikethrough or green text */
.old {
  color: var(--red-700);
  text-decoration: line-through;
}

.new {
  color: var(--green-700);
}

/* Fixed element that covers a whole screen */
.fixedCurtain {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* show ... when text overflow */
.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ellipsisLines {
  --ellipsis-lines: 2;

  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  white-space: initial;
  overflow-wrap: break-word;
  overflow: hidden;
  -webkit-line-clamp: var(--ellipsis-lines, 2);
  -webkit-box-orient: vertical;
}

/* Two helper classes to control pointer sensitivity */
.insensitive {
  pointer-events: none;
}

.sensitive {
  pointer-events: auto;
}

/* TODO: clean up when we can use @layer  */
.globalInsensitive {
  user-select: none;
  pointer-events: none;
  * {
    user-select: none;
    pointer-events: none;
  }
}

/* fake :not to increase the specificity */
.globalSensitive:not(.non-existing-ghost-selector) {
  user-select: auto;
  pointer-events: auto;
  * {
    user-select: auto;
    pointer-events: auto;
  }
}

/* Two helper classes to control visibility */
.invisible {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

/* Helper classes and global variables to controll gap between children elements */
:root {
  --gap-row-xsmall: var(--offset-xsmall);
  --gap-column-xsmall: var(--offset-xsmall);
  --gap-row-small: var(--offset-small);
  --gap-column-small: var(--offset-small);
  --gap-row-medium: var(--offset-medium);
  --gap-column-medium: var(--offset-medium);
  --gap-row-large: var(--offset-large);
  --gap-column-large: var(--offset-large);
  --gap-row-x-large: var(--offset-x-large);
  --gap-column-x-large: var(--offset-x-large);
  --gap-child-flex: initial; /* This is equivalent to setting '0 1 auto' */
  --albatrossBreakpoint: calc(var(--100px) * 11);
}

.gap {
  display: flex;
  flex-direction: column;

  /* Flex property is set by variable to be able to easily change flex configuration on consumer side by just changing the variable */
  > * {
    flex: var(--gap-child-flex);
  }

  /* Modern browsers should use the new `gap` property on flex container the same way as on grid container */
  /* But only Firefox 63+, Safari 14.1+ and Chrome 84+ (and corresponding Chromium browsers) support it, */
  /* and there is no way of checking for `gap` support on flex container, because the same one exists on grid. */
  /* https://github.com/w3c/csswg-drafts/issues/3559 */
  /* We don't support old Firefox < 63, Chrome < 84 anymore, so we can just check that it's not Safari < 14.1. */
  /* Target Chrome(ium) and Firefox as they don't support stroke-color and Safari 14.1+ as it adds scale support */
  /* https://webkit.org/blog/11648/new-webkit-features-in-safari-14-1 */
  /* Once we drop support for Safari < 14.1, remove this @supports check, move gap property upper next to flex-direction */
  @supports (not (stroke-color: red)) or (scale: 1) {
    gap: var(--gap-row, 0) var(--gap-column, 0);
  }

  /* Margin fallback for the browsers (Safari < 14.1) that don't have `gap` property support. */
  /* Once we drop support for Safari < 14.1, remove this fallback completely */
  /* If it is nowrap flexbox, then we can apply right/bottom margins to its all items except last child */
  /* But if it's wrappable flexbox then: */
  /* Children have positive margins whilst gap container itself has negative margin to compensate them, */
  /* in fact, creating negative offset for the subsequent items on the container level */
  /* Have to also add --gap-*-child variable to be able to */
  /* include gap container into gap container without affecting own --gap-row variable */
  @supports (stroke-color: red) and (not (scale: 1)) {
    &.gapWrappable {
      margin-right: calc(var(--gap-column-child, var(--0px)) - var(--gap-column, var(--0px)));
      margin-bottom: calc(var(--gap-row-child, var(--0px)) - var(--gap-row, var(--0px)));
    }

    .gapHorizontal:not(.gapWrappable) > &.gapWrappable {
      margin-bottom: calc(-1 * var(--gap-row, var(--0px)));

      &:last-child {
        margin-right: calc(-1 * var(--gap-column, var(--0px)));
      }
    }

    :not(.gapHorizontal, .gapWrappable) > &.gapWrappable {
      margin-right: calc(-1 * var(--gap-column, var(--0px)));

      &:last-child {
        margin-bottom: calc(-1 * var(--gap-row, var(--0px)));
      }
    }

    /* Reset --gap-*-child variable if gap container is not a direct child of another gap container */
    :not(.gap) > & {
      --gap-row-child: initial;
      --gap-column-child: initial;
    }

    &:not(.gapHorizontal).gapWrappable > :not(.gapWrappable, .flexLineBreak),
    &.gapHorizontal.gapWrappable > :not(.gapWrappable, .flexLineBreak),
    &.gapHorizontal:not(.gapWrappable) > :not(.gapWrappable, .flexLineBreak, :last-child) {
      margin-right: var(--gap-column-child, 0);
    }

    &.gapHorizontal.gapWrappable > :not(.gapWrappable, .flexLineBreak),
    &:not(.gapHorizontal).gapWrappable > :not(.gapWrappable, .flexLineBreak),
    &:not(.gapHorizontal, .gapWrappable) > :not(.gapWrappable, .flexLineBreak, :last-child) {
      margin-bottom: var(--gap-row-child, 0);
    }
  }
}

.gapXLarge {
  --gap-row: var(--gap-row-x-large);

  composes: gap;

  /* Make gap inheritable in fallback version */
  /* so child or grandchild .gap container without size would inherit size of the closest ancestor .gap */
  > *, /* Any direct children will get margin */
  :not(.gap) > .gap:not(.gapLarge, .gapMedium, .gapSmall, .gapXSmall) > * {
    /* Children of a grandchild .gap will get margin */
    --gap-row-child: var(--gap-row-x-large);
  }

  &.gapHorizontal {
    --gap-column: var(--gap-column-x-large);

    > *,
    :not(.gap) > .gap:not(.gapLarge, .gapMedium, .gapSmall, .gapXSmall) > * {
      --gap-column-child: var(--gap-column-x-large);
    }
  }
}

.gapLarge {
  --gap-row: var(--gap-row-large);

  composes: gap;

  > *,
  :not(.gap) > .gap:not(.gapXLarge, .gapMedium, .gapSmall, .gapXSmall) > * {
    --gap-row-child: var(--gap-row-large);
  }

  &.gapHorizontal {
    --gap-column: var(--gap-column-large);

    > *,
    :not(.gap) > .gap:not(.gapXLarge, .gapMedium, .gapSmall, .gapXSmall) > * {
      --gap-column-child: var(--gap-column-large);
    }
  }
}

.gapMedium {
  --gap-row: var(--gap-row-medium);

  composes: gap;

  > *,
  :not(.gap) > .gap:not(.gapXLarge, .gapLarge, .gapSmall, .gapXSmall) > * {
    --gap-row-child: var(--gap-row-medium);
  }

  &.gapHorizontal {
    --gap-column: var(--gap-column-medium);

    > *,
    :not(.gap) > .gap:not(.gapXLarge, .gapLarge, .gapSmall, .gapXSmall) > * {
      --gap-column-child: var(--gap-column-medium);
    }
  }
}

.gapSmall {
  --gap-row: var(--gap-row-small);

  composes: gap;

  > *,
  :not(.gap) > .gap:not(.gapXLarge, .gapLarge, .gapMedium, .gapXSmall) > * {
    --gap-row-child: var(--gap-row-small);
  }

  &.gapHorizontal {
    --gap-column: var(--gap-column-small);

    > *,
    :not(.gap) > .gap:not(.gapXLarge, .gapLarge, .gapMedium, .gapXSmall) > * {
      --gap-column-child: var(--gap-column-small);
    }
  }
}

.gapXSmall {
  --gap-row: var(--gap-row-xsmall);

  composes: gap;

  > *,
  :not(.gap) > .gap:not(.gapXLarge, .gapLarge, .gapMedium, .gapSmall) > * {
    --gap-row-child: var(--gap-row-xsmall);
  }

  &.gapHorizontal {
    --gap-column: var(--gap-column-xsmall);

    > *,
    :not(.gap) > .gap:not(.gapXLarge, .gapLarge, .gapMedium, .gapSmall) > * {
      --gap-column-child: var(--gap-column-xsmall);
    }
  }
}

.gapInline {
  display: inline-flex;
}

.gapContents {
  display: contents;
}

.gapWrappable {
  flex-wrap: wrap;
}

.gapHorizontal {
  flex-direction: row;
  align-items: stretch;
}

.gapHorizontalWrap {
  composes: gapHorizontal gapWrappable;
}

.gapAlbatross {
  --gap-child-flex: 0 0 auto;

  composes: gapHorizontalWrap;

  /*
    Flexbox Albatross
    http://www.heydonworks.com/article/the-flexbox-holy-albatross
    https://snook.ca/archives/html_and_css/understanding-the-flexbox-albatross
    The breakpoint is around ~1100px, the modifier is 9999, which should stay high
   */
  margin: calc(var(--gap-column-child) * -1);

  > * {
    flex-grow: 1;
    flex-basis: calc(var(--albatrossBreakpoint) * 9999 - 100% * 9999);
    max-width: 100%;

    &:last-of-type {
      margin-right: 0;
      margin-bottom: 0;
    }
  }
}

.gapAlignStart {
  align-items: flex-start;
}

.gapAlignBaseline {
  align-items: baseline;
}

/* Center content of a box vertically and horizontally */
.centerFlexContent {
  justify-content: center;
  align-content: center;
  align-items: center;
}

.centerFlexAlign {
  align-items: center;
}

.centerMarginBlock {
  margin-block: auto;
}

.centeredFlex {
  composes: centerFlexContent;
  display: flex;
}

.centeredInlineFlex {
  composes: centerFlexContent inlineFlex;
}

/* Empty item to break flex line */
.flexLineBreak,
.gap > .flexLineBreak {
  flex: 1 1 100%;
}

/* Helper classes to control elements padding */
.paddingXSmallTop {
  padding-top: var(--offset-xsmall);
}

.paddingXSmallBottom {
  padding-bottom: var(--offset-xsmall);
}

.paddingSmallTop {
  padding-top: var(--offset-small);
}

.paddingSmallBottom {
  padding-bottom: var(--offset-small);
}

.paddingSmallLeft {
  padding-left: var(--offset-small);
}

.paddingSmallRight {
  padding-right: var(--offset-small);
}

.paddingMediumTop {
  padding-top: var(--offset-medium);
}

.paddingMediumBottom {
  padding-bottom: var(--offset-medium);
}

.paddingMediumLeft {
  padding-left: var(--offset-medium);
}

.paddingMediumRight {
  padding-right: var(--offset-medium);
}

.paddingLargeTop {
  padding-top: var(--offset-large);
}

.paddingLargeBottom {
  padding-bottom: var(--offset-large);
}

.paddingLargeLeft {
  padding-left: var(--offset-large);
}

.paddingLargeRight {
  padding-right: var(--offset-large);
}

.scrollLock {
  /* stylelint-disable-next-line declaration-no-important -- We are using !important, because we want to override any possible, overflow, inline styles whenever this class is used. */
  overflow: hidden !important;
  scrollbar-gutter: stable;
}
