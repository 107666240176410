/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */

.iconsSort {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1em;
  vertical-align: middle;
  font-size: var(--7px);
  line-height: 1;
}

.iconSort {
  color: var(--blue-gray-500);
  opacity: 0.4;
  transition: opacity 0.1s linear;
}

.iconSortSelected {
  opacity: 1;
}
