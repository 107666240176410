/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
.container {
  display: flex;
  flex-grow: 1;
}

.rulesContainer {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
