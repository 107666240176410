/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */

/* layout styles */
.outerWrapper {
  display: flex;
  flex-grow: 1;
  height: 0; /* without an explicit height set, we can't use percentages in innerWrapper */
}

.innerWrapper {
  width: 100%;
}

.drawerWrapper {
  position: relative;
  flex-grow: 1;
  height: 100%;
  /* browser renders scroll bar behind the grid because the wrapper has a overflow-y scroll - this compensates */
  padding-right: var(--9px);
  overflow-y: scroll;
}

.handle,
.denyRules-handle {
  position: sticky;
  z-index: 111;
  top: calc(-1 * var(--1px));
  height: var(--30px);
  padding-bottom: var(--5px);
  background-color: var(--white);
}

/* menu item styles */
.menuItemContent {
  composes: gapSmall from 'utils.css';
}

.menuItemText {
  margin: 0;
}

.menuItemSubtext {
  composes: menuItemText;
  color: var(--gray-500);
}

.buttonDropdown-itemContent:hover {
  cursor: pointer;
  background-color: var(--gray-100);
}

/* Grid column header styles */
.areaHead {
  display: flex;

  > div {
    flex-grow: 1; /* Grow all header cells vertically so bg color fills the cell 100% */
  }
}

.areaHead .service,
.areaHead .ipRanges {
  color: var(--blue-gray-800);
  background-color: var(--indigo-200);
}

.areaHead .arrowIcon {
  --head-cell-vertical-padding: var(
    --0px
  ); /* remove vertical padding from icon cell so the whole header row doesn't grow too large */
}

.icon {
  color: var(--blue-gray-500);
}

/*
  -Selectors have a default z-index: 120
  -Drawers have z-index: 110, so filters always overlay drawers
  -Allow rules drawer needs to overlay denyrules drawer - otherwise selector dropdown in last row will be clipped
  -Grid manager has z-index 130, so selectors within rule editor don't stack on top of grid manager
  -areaHead has same logic as grid manager, but it still needs to be less than grid manager, or else manager's
     dropdown will be below areaHead
 */
.drawer {
  position: relative;
  z-index: 110;
}

.denyRules-drawer {
  position: relative;
  z-index: 100;
}

.defaultRule-drawer {
  position: relative;
  z-index: 99;
}

/*
  TODO - we should remove .drawer outer class, but rule gets overwritten by styles in Grid
  Same specificity, and these styles are applied after first, so they should 'win' - investigate why
*/
.drawer {
  .manager-manager {
    z-index: 130;
  }

  .areaHead {
    z-index: 125;
  }
}
