/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
.icon {
  font-size: 2em;
  cursor: pointer;
}

.iconAccepted {
  color: var(--green-600);
}

.iconDenied {
  color: var(--gray-300);
}

.actionToggle {
  text-align: center;
  overflow-wrap: normal;
}

.protectionSchemaModalContent {
  display: grid;
  grid-template-columns: repeat(auto-fill, calc(var(--100px) * 1.4));
  grid-gap: var(--offset-medium);
  justify-content: space-around;
}

.schemaItemWrapper {
  display: flex;
  flex-direction: column;
}

.disabledSchema {
  color: var(--gray-400);
  cursor: not-allowed;
  pointer-events: none;
}

html[data-theme='lightning'] :is(.disabledSchema) .disabledSchema {
  color: var(--gray-400);
}

.oldProtectionSchemaCard {
  composes: centeredFlex from 'utils.css';
  flex-direction: column;
  justify-content: flex-start;
  width: calc(1.4 * var(--100px));
  height: calc(var(--100px));
  text-align: center;
  cursor: pointer;

  &:hover {
    color: var(--blue-500);
  }
}

.oldActiveProtectionSchemaCard {
  composes: oldProtectionSchemaCard;
  color: var(--blue-500);

  &:hover {
    color: var(--blue-500);
  }
}

.protectionSchemaCard {
  composes: oldProtectionSchemaCard;

  &:hover {
    color: var(--lightning--blue-500);
  }
}

.activeProtectionSchemaCard {
  composes: protectionSchemaCard;
  color: var(--lightning--blue-500);
}

.schemaText {
  height: var(--25px);
  text-align: center;
}

/* icon in modal */
.schemaIcon {
  font-size: 1.5em;
}

.gridSchemaIcon {
  font-size: 1.2em;
}

/* providers and ingress_services are the names of the property in Grid config */
.areaHead {
  .providers-customCellHeader,
  .ingress_services-customCellHeader {
    color: var(--blue-gray-800);
    background-color: var(--indigo-200);

    &:hover {
      background-color: var(--indigo-300);
    }
  }
}
