/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
.logo {
  display: block;
}

.icon {
  display: contents;
  font-size: calc(2 * var(--94px));

  .svg {
    height: 0.3em;
  }
}

.version {
  --version-color: var(--blue-700);
  --version-font-size: var(--22px);

  composes: bold from 'utils.css';
  font-size: var(--version-font-size);
  color: var(--version-color);

  @nest html[data-theme='lightning'] & {
    --version-color: var(--lightning--blue-gray-700);
    --version-font-size: var(--13px);
  }
}

.link {
  display: block;
}
