/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

.notifyUserLink {
  color: var(--blue-600);
}

.DoNotShowMessageCheckbox {
  margin-left: var(--8px);
}
