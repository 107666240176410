/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */
.container {
  display: flex;
  flex-direction: column;
  gap: var(--offset-x-large);
}

.oldContainer {
  display: grid;
  grid-template-columns: minmax(200px, 1fr) 2fr;
  grid-gap: var(--offset-x-large);

  @media (width <= 1650px) {
    display: flex;
    flex-direction: column;
  }
}
