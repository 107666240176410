/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

:root {
  --form-border-radius: var(--2px);
  --form-input-text: var(--13px);
  --form-input-border-width: 1px;
  --form-input-border: var(--form-input-border-width) solid var(--gray-100);
  --form-focus-border-color: var(--blue-700);
  --form-error-border-color: var(--red-600);
}

.form {
}
