/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */
.list {
  --s-row-gap: var(--gap-row-small);
  --m-row-gap: var(--gap-row-medium);
  --l-row-gap: var(--gap-row-large);
}

.listSideBySide {
  composes: list;
  display: grid;

  > .row {
    display: contents; /* Row doesn't exists for style in grid version */

    > .key {
      text-align: right;
    }

    > .keyWithPadding {
      padding-left: 0;
    }

    > .value,
    > .hint {
      padding-left: 0;
    }

    > .hintWithIcon {
      padding-left: var(--7px);
    }
  }
}
