/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */

.fileElement {
  position: absolute;
  left: var(--100px);
}

.uploadContainer {
  position: relative;
}

.input {
  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: 2px auto rgb(var(--blue-500-rgb) / 0.5);
  }
}

.hideFileInputText {
  color: transparent;
}
