/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */

.active {
  position: relative;
  color: var(--indigo-300);
  pointer-events: none;

  &::after {
    position: absolute;
    content: '▲';
    bottom: -100%;
    left: 47%;
  }
}
