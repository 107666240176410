/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */

.list {
  margin-top: 0;
  .value {
    white-space: pre-wrap;
    overflow: auto;
  }
}

.card {
  padding: 0 var(--10px);
}

.on {
  composes: bold from 'utils.css';
  color: var(--green-800);
}

.off {
  composes: bold from 'utils.css';
  color: var(--red-700);
}
