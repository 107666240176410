/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.username {
  color: var(--blue-400);
}

.inviteMessage {
  padding-top: var(--10px);
}

.inviteLinkBlock {
  display: flex;
  justify-content: space-between;
  margin-top: var(--10px);
}

.inviteLink {
  padding: var(--6px);
  background: var(--blue-50);
}

.success {
  color: var(--blue-700);
}

.iconSvgArrowDown {
  width: 0.85em;
  height: 0.65em;
  transform: translateX(25%);
}
