/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */

.status-badge {
  padding: var(--2px) var(--10px);
  line-height: 1.2;

  @nest html[data-theme='lightning'] & {
    padding: var(--4px) var(--8px);
  }
}

.status-badge {
  @nest html[data-theme='lightning'] & {
    text-transform: none;
  }
}

.table-minWidth_800 {
  .status-badge {
    margin: var(--4px) 0;
  }

  .areaBody .cell.status {
    padding-top: 0;
    padding-bottom: 0;
  }

  @nest html[data-theme='lightning'] & {
    .areaBody .cell.status {
      padding-top: var(--8px);
    }
  }
}

.table-minWidth_960 {
  .areaHead .cell {
    white-space: nowrap;
  }
}
