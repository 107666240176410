/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.table-minWidth_1100 {
  .areaHead .cell {
    white-space: nowrap;
  }
}

.rowToEnable .areaBody {
  background-color: var(--blue-gray-150);
}

/* Highlight disabled rows with gray */
.disabledRulesets .areaBody {
  @nest html[data-theme='lightning'] & {
    /* temp solution to prevent focused state from taking over background-color */
    /* stylelint-disable-next-line declaration-no-important */
    background-color: var(--lightning--blue-gray-200) !important;

    /* keep full opacity on checkbox, status, and scope columns */
    /* stylelint-disable selector-max-combinators, selector-max-compound-selectors */
    &:not(:nth-child(2), :has(.state), :has(.scope)) .cell {
      opacity: 0.6;
    }
    /* handle scope column separately to not affect contextual menu */
    /* stylelint-disable selector-max-universal */
    .cell.scope > * > *:not(.rulesetListGrid-dropdown) {
      opacity: 0.6;
    }
  }
}
