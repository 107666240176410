/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */

.table-minWidth_980 {
  .areaHead .cell {
    white-space: nowrap;
  }
}

.status-badge {
  padding: var(--2px) var(--10px);
  line-height: 1.2;
}

.table-minWidth_800 {
  .status-badge {
    margin: var(--4px) 0;
  }

  .areaBody .cell.status {
    padding-top: 0;
    padding-bottom: 0;
  }

  @nest html[data-theme='lightning'] & {
    .areaBody .cell.status {
      padding-top: var(--2px);
    }
  }
}

.table-minWidth_960 {
  .areaHead .cell {
    white-space: nowrap;
  }
}

.button-textIsHideable {
  @media (width <= 860px) {
    display: none;
  }
}

.pceRegion {
  color: var(--blue-700);
}
