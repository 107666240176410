/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.reminder {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  width: calc(4.5 * var(--100px));
  min-height: var(--90px);
  margin-bottom: var(--10px);
  color: var(--gray-800);
  opacity: 1;
  border-radius: var(--2px);
  box-shadow: 0 var(--2px) var(--2px) rgb(var(--black-rgb) / 0.3);
}

.counter {
  composes: bold from 'utils.css';
  position: absolute;
  display: flex;
  float: left;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 0;
  width: var(--100px);
  height: 100%;
  font-size: var(--16px);
  color: var(--white);
  border-radius: var(--2px) 0 0 var(--2px);
}

.counterTimeLeft {
  font-size: var(--36px);
}

.counterToday {
  font-size: var(--20px);
}

.counterOrange {
  composes: counter;
  background: var(--orange-500);
}

.counterRed {
  composes: counter;
  background: var(--red-600);
}

.body {
  display: flex;
  height: 100%;
  font-size: var(--14px);
}

.withCounter {
  composes: body;
  position: relative;
  flex-flow: row wrap;
  top: 0;
  right: 0;
  width: calc(35 * var(--10px));
  padding: var(--16px);
  text-align: left;
  border: var(--1px) solid var(--gray-200);
  border-radius: 0 var(--2px) var(--2px) 0;
  border-left-width: 0;
  background: var(--gray-50);
}

.withoutCounter {
  composes: body;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: var(--20px);
  color: var(--white);
  text-align: center;
  border-radius: var(--2px);
  background: var(--red-600);
}

.title {
  composes: bold from 'utils.css';
  margin-bottom: var(--8px);
  font-size: var(--16px);
}

.withoutCounter .title {
  font-size: var(--18px);
}

.message {
  margin-bottom: var(--8px);
}

.expired-link {
  margin-top: var(--10px);
  text-decoration: underline;
}

.close-icon {
  position: absolute;
  top: var(--10px);
  right: var(--10px);
  font-size: var(--10px);
  cursor: pointer;
  transition: transform 0.1s linear;
}
