/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */

.card {
  margin-bottom: var(--10px);
  padding: 0 var(--10px);
}

.on {
  composes: bold from 'utils.css';
  color: var(--green-800);
}

.off {
  composes: bold from 'utils.css';
  color: var(--red-700);
}
