/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */

.banner {
  --banner-width: auto;
  --banner-width-max: 96%;
  --main-width-min: calc(3.6 * var(--100px)); /* 360px */
  --main-width: calc(4.6 * var(--100px)); /* 460px */
  --main-width-max: var(--main-width); /* 460px */
  --core-height-min: var(--80px);
  --core-paddings: var(--10px);
  --additional-item-width: var(--92px);
  --text-font-size: var(--20px);
  --text-font-size-with-icon: var(--28px);
  --subtext-font-size: var(--14px);
  --banner-icon-font-size: var(--36px);
  --banner-icon-color: var(--white);
  --banner-core-font-color: var(--white);
  --banner-subtext-font-color: var(--gray-800);

  @nest html[data-theme='lightning'] & {
    --main-width-min: calc(3 * var(--100px)); /* 300px */
    --text-font-size: var(--24px);
    --text-font-size-with-icon: var(--24px);
    --banner-icon-font-size: var(--24px);
    --banner-icon-color: var(--lightning--white);
    --banner-core-font-color: var(--lightning--white);
    --banner-subtext-font-color: var(--lightning--gray-800);
  }
}

.centerer {
  display: flex;
  justify-content: center;
}

.banner {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: var(--banner-width);
  max-width: var(--banner-width-max); /* Banner can't be more than 96% of container (or smaller screen) */

  &.withIcon {
    .subText {
      padding-left: var(--80px);

      @nest html[data-theme='lightning'] & {
        padding-left: var(--34px);
      }
    }
  }

  &:not(.withIcon) {
    .main {
      text-align: center;

      @nest html[data-theme='lightning'] & {
        text-align: left;
      }
    }

    .text {
      justify-content: center;
      padding-left: var(--20px);

      @nest html[data-theme='lightning'] & {
        padding: 0;
      }
    }
  }

  @nest html[data-theme='lightning'] & {
    border-radius: var(--8px);
  }
}

.success {
  composes: banner;

  .main {
    @nest html[data-theme='lightning'] & {
      background-color: var(--lightning--green-25);
    }
  }

  .core {
    font-size: var(--text-font-size-with-icon);
    border: var(--1px) solid var(--green-800);
    background-color: var(--green-700);

    @nest html[data-theme='lightning'] & {
      border: none;
      background-color: unset;
    }
  }

  .subText {
    border-color: var(--gray-200);

    @nest html[data-theme='lightning'] & {
      font-weight: var(--fontWeightSemiBold);
      border: none;
      background-color: unset;
    }
  }

  .annexes,
  .annex {
    @nest html[data-theme='lightning'] & {
      border: unset;
      background-color: var(--white);
    }
  }

  .annex {
    @nest html[data-theme='lightning'] & {
      outline: var(--2px) solid var(--lightning--green-200);
    }
  }

  @nest html[data-theme='lightning'] & {
    --banner-icon-font-size: var(--24px);
    --banner-icon-color: var(--lightning--green-600);
    --banner-core-font-color: var(--lightning--green-600);
    --banner-subtext-font-color: var(--lightning--green-800);

    outline: var(--2px) solid var(--lightning--green-200);
  }
}

.warning {
  composes: banner;

  .main {
    @nest html[data-theme='lightning'] & {
      background-color: var(--lightning--orange-25);
    }
  }

  .core {
    font-size: var(--text-font-size-with-icon);
    border: var(--1px) solid var(--orange-600);
    background-color: var(--orange-500);

    @nest html[data-theme='lightning'] & {
      border: none;
      background-color: unset;
    }
  }

  .subText {
    border-color: var(--gray-200);

    @nest html[data-theme='lightning'] & {
      font-weight: var(--fontWeightSemiBold);
      border: none;
      background-color: unset;
    }
  }

  .annexes,
  .annex {
    @nest html[data-theme='lightning'] & {
      border: unset;
      background-color: var(--white);
    }
  }

  .annex {
    @nest html[data-theme='lightning'] & {
      outline: var(--2px) solid var(--lightning--orange-200);
    }
  }

  @nest html[data-theme='lightning'] & {
    --banner-icon-font-size: var(--24px);
    --banner-icon-color: var(--lightning--orange-700);
    --banner-core-font-color: var(--lightning--orange-700);
    --banner-subtext-font-color: var(--lightning--orange-900);

    outline: var(--2px) solid var(--lightning--orange-200);
  }
}

.error {
  composes: banner;

  .main {
    @nest html[data-theme='lightning'] & {
      background-color: var(--lightning--red-25);
    }
  }

  .core {
    font-size: var(--text-font-size-with-icon);
    border: var(--1px) solid var(--red-700);
    background-color: var(--red-600);

    @nest html[data-theme='lightning'] & {
      border: none;
      background-color: unset;
    }
  }

  .subText {
    border-color: var(--gray-200);

    @nest html[data-theme='lightning'] & {
      font-weight: var(--fontWeightSemiBold);
      border: none;
      background-color: unset;
    }
  }

  .annexes,
  .annex {
    @nest html[data-theme='lightning'] & {
      border: unset;
      background-color: var(--white);
    }
  }

  .annex {
    @nest html[data-theme='lightning'] & {
      outline: var(--2px) solid var(--lightning--red-200);
    }
  }

  @nest html[data-theme='lightning'] & {
    --banner-icon-font-size: var(--24px);
    --banner-icon-color: var(--lightning--red-700);
    --banner-core-font-color: var(--lightning--red-700);
    --banner-subtext-font-color: var(--lightning--red-900);

    outline: var(--2px) solid var(--lightning--red-200);
  }
}

.progress {
  --banner-core-font-color: var(--blue-800);

  composes: banner;

  .main {
    @nest html[data-theme='lightning'] & {
      background-color: var(--lightning--blue-gray-25);
    }
  }

  .core {
    font-size: var(--text-font-size-with-icon);
    border: var(--1px) solid var(--blue-gray-100);
    background-color: var(--blue-gray-50);

    @nest html[data-theme='lightning'] & {
      color: var(--lightning--blue-900);
      border: none;
      background-color: unset;
    }
  }

  .subText {
    border-color: var(--blue-gray-100);

    @nest html[data-theme='lightning'] & {
      font-weight: var(--fontWeightSemiBold);
      border: none;
      background-color: unset;
    }
  }

  .annexes,
  .annex {
    border-color: var(--blue-gray-100);

    @nest html[data-theme='lightning'] & {
      border: unset;
      background-color: var(--white);
    }
  }

  .annex {
    @nest html[data-theme='lightning'] & {
      outline: var(--2px) solid var(--lightning--blue-gray-200);
    }
  }

  @nest html[data-theme='lightning'] & {
    --banner-icon-font-size: var(--24px);
    --banner-icon-color: var(--lightning--blue-900);
    --banner-core-font-color: var(--lightning--blue-900);
    --banner-subtext-font-color: var(--lightning--blue-gray-700);

    outline: var(--2px) solid var(--lightning--blue-gray-200);
  }
}

.info {
  --banner-core-font-color: var(--blue-gray-400);
  --banner-subtext-font-color: var(--gray-500);

  composes: banner;

  .main {
    @nest html[data-theme='lightning'] & {
      background-color: var(--lightning--blue-75);
    }
  }

  .core {
    font-size: var(--text-font-size);
    border: var(--1px) solid var(--blue-100);
    background-color: var(--blue-50);

    @nest html[data-theme='lightning'] & {
      border: none;
      background-color: unset;
    }
  }

  .subText {
    border-color: var(--gray-200);

    @nest html[data-theme='lightning'] & {
      font-weight: var(--fontWeightSemiBold);
      border: none;
      background-color: unset;
    }
  }

  .annexes,
  .annex {
    border-color: var(--gray-200);

    @nest html[data-theme='lightning'] & {
      border: unset;
      background-color: var(--white);
    }
  }

  .annex {
    @nest html[data-theme='lightning'] & {
      outline: var(--2px) solid var(--lightning--blue-200);
    }
  }

  @nest html[data-theme='lightning'] & {
    --banner-icon-font-size: var(--24px);
    --banner-icon-color: var(--lightning--blue-800);
    --banner-core-font-color: var(--lightning--blue-600);
    --banner-subtext-font-color: var(--lightning--blue-800);

    outline: var(--2px) solid var(--lightning--blue-200);
  }
}

.plain {
  --banner-core-font-color: var(--gray-300);
  --banner-subtext-font-color: var(--gray-400);

  composes: banner;

  .main {
    @nest html[data-theme='lightning'] & {
      background-color: var(--lightning--blue-gray-25);
    }
  }

  .core {
    justify-content: center;
    font-size: var(--text-font-size);
    border: var(--1px) solid var(--gray-100);
    background-color: var(--gray-50);

    @nest html[data-theme='lightning'] & {
      border: none;
      background-color: unset;
    }
  }

  .subText {
    border-color: var(--gray-100);

    @nest html[data-theme='lightning'] & {
      font-weight: var(--fontWeightSemiBold);
      border: none;
      background-color: unset;
    }
  }

  .annexes,
  .annex {
    border-color: var(--gray-100);
  }

  @nest html[data-theme='lightning'] & {
    --banner-icon-font-size: var(--24px);
    --banner-icon-color: var(--lightning--blue-gray-500);
    --banner-core-font-color: var(--lightning--blue-gray-500);
    --banner-subtext-font-color: var(--lightning--blue-gray-600);

    outline: var(--2px) solid var(--lightning--blue-gray-200);
  }
}

.plainNoFill {
  --banner-core-font-color: var(--gray-300);

  composes: banner;

  .core {
    justify-content: center;
    font-size: var(--text-font-size);
  }

  @nest html[data-theme='lightning'] & {
    --banner-core-font-color: var(--lightning--blue-gray-500);
  }
}

.main {
  flex: 1 1 auto;
  width: var(--main-width);
  min-width: var(--main-width-min);
  max-width: var(--main-width-max);

  @nest html[data-theme='lightning'] & {
    padding: var(--16px) var(--32px);
  }
}

.core {
  display: flex;
  align-items: center;
  min-height: var(--core-height-min);
  padding: var(--core-paddings) 0;
  color: var(--banner-core-font-color);

  @nest html[data-theme='lightning'] & {
    min-height: unset;
    padding: 0;
  }
}

.icon {
  display: flex;
  flex: 0 0 var(--80px);
  justify-content: center;
  align-items: center;

  @nest html[data-theme='lightning'] & {
    flex: 0 0 var(--34px);
    justify-content: flex-start;
  }
}

.icon-icon {
  font-size: var(--banner-icon-font-size);
  color: var(--banner-icon-color);
}

.icon-spinner {
  width: var(--36px);
  height: var(--36px);
  border-width: var(--4px);

  @nest html[data-theme='lightning'] & {
    width: var(--22px);
    height: var(--22px);
  }
}

.text {
  composes: semiBold from 'utils.css';
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  padding-right: var(--20px);
  line-height: 1.2;

  @nest html[data-theme='lightning'] & {
    padding: 0;
  }
}

.subText {
  padding: var(--5px) var(--20px);
  font-size: var(--subtext-font-size);
  color: var(--banner-subtext-font-color);
  word-break: break-word;
  border-width: 0 var(--1px) var(--1px) var(--1px);
  border-style: solid;
  background-color: var(--white);

  @nest html[data-theme='lightning'] & {
    padding: var(--8px) 0 0;
  }
}

.annexes {
  display: flex;
  flex: 1 0 auto;
  align-items: stretch;
  max-width: var(--main-width-max);
  border-width: var(--1px) 0 0 var(--1px);
  border-style: solid;
  border-color: var(--gray-200);
}

.annex {
  display: flex;
  flex: 1 0 auto;
  /* Wrap and center so items are centered in vertical and horizontal axes when wrapped/not wrapped */
  flex-flow: row wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: var(--additional-item-width);
  padding: var(--14px) 0;
  text-align: center;
  border-width: 0 var(--1px) var(--1px) 0;
  border-style: solid;
  border-color: var(--gray-200);
  background-color: var(--gray-50);
}

.annexTitle {
  max-width: 100%;
  padding: 0 var(--10px);
  font-size: var(--16px);
  color: var(--gray-800);
  overflow-wrap: break-word;

  @nest html[data-theme='lightning'] & {
    padding: var(--4px) var(--10px);
    color: var(--lightning--blue-gray-700);
  }
}

.annexCount {
  composes: bold from 'utils.css';
  font-size: var(--32px);
  line-height: 1;
  color: var(--blue-gray-500);
  overflow-wrap: break-word;

  @nest html[data-theme='lightning'] & {
    padding: var(--4px);
    color: var(--lightning--blue-gray-900);
  }
}
