/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.globalRoleDetail {
  padding-top: var(--20px);
}

.redText {
  color: var(--red-600);
}
