/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
.actionsWrapper {
  display: flex;
  flex-wrap: nowrap;
  column-gap: var(--4px);
}

.numOfMatchExceedsConfMax {
  padding: var(--5px);
  font-size: var(--12px);
  font-weight: var(--fontWeightLight);
  line-height: var(--16px);
  color: var(--gray-400);
}

.editBtn {
  padding-left: var(--7px);
  color: var(--blue-800);
  cursor: pointer;
}

.fieldRange {
  font-size: var(--12px);
  color: var(--gray-400);
}
