/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
.container {
  composes: centerMarginBlock gapLarge from 'utils.css';

  @nest html[data-theme='lightning'] & {
    margin-top: var(--32px);
  }
}

.title {
  composes: bold from 'utils.css';
  font-size: var(--ven-count-by-status-title-font-size, var(--18px));

  @nest html[data-theme='lightning'] & {
    --ven-count-by-status-title-font-size: var(--14px);

    font-weight: var(--fontWeightBold);
    color: var(--lightning--blue-gray-900);
  }
}

.status {
  padding-top: var(--20px);
}
