/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

/** Layout **/
.landing {
  composes: containerWidth from 'utils.css';
  flex: 1 0 auto;
  margin-top: calc(var(--offset-medium) * -1);
  margin-bottom: calc(var(--offset-medium) * -2);
  background-color: var(--blue-gray-100);
}

.tilesContainer {
  margin: calc(var(--offset-large) * 5);
}

.tileLayout {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(calc(var(--80px) * 3), 1fr));
  grid-auto-rows: 1fr;
  justify-content: center;
  max-width: calc(var(--100px) * 8);
  margin: auto;
  padding: var(--20px);
}

/** Tile Styling **/
.tile {
  --card-background-color: white;

  max-height: calc(var(--100px) * 6);
  padding: 0;
  border: 0.5px solid var(--gray-200);
  border-radius: var(--3px);
  box-shadow: 0 0 5px 2px var(--gray-200);
}

.tileImage {
  height: calc(var(--80px) * 2);
  font-size: 6em;
  line-height: calc(var(--80px) * 2);
  color: white;
  text-align: center;
  border-radius: var(--3px) var(--3px) 0 0;
}

.tileDetails {
  height: auto;
  font-size: var(--13px);
  text-overflow: ellipsis;
  background-color: white;
}

.title {
  composes: bold from 'utils.css';
}

/** Tile Icon **/
.icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 1.2em;
  height: 1.2em;
  color: var(--white);
  border-radius: 100%;
}

.svg {
  margin: var(--10px);
  padding: var(--10px);
}

/** Tiles **/
.map {
  background-color: var(--blue-400);
  .icon {
    background-color: var(--blue-300);
  }
}

.illuminate {
  background-color: var(--teal-400);
  .icon {
    background-color: var(--teal-300);
  }
}

.explorer {
  background-color: var(--green-400);
  .icon {
    background-color: var(--green-300);
  }
}

.dashboard {
  background-color: var(--red-400);
  .icon {
    background-color: var(--red-300);
  }

  .svg {
    padding-top: var(--18px);
  }
}

.rulesets {
  background-color: var(--purple-400);
  .icon {
    background-color: var(--purple-300);
  }
}

.workloads {
  background-color: var(--yellow-200);
  .icon {
    background-color: var(--yellow-100);
  }
}
