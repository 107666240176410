/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
.map {
  display: flex;
  z-index: 0;
  flex-grow: 1;
  flex-direction: column;
  background: var(--white);
}

.appGroupMap {
  padding-top: var(--5px);
}

.splitViewPane {
  position: relative;
}

.leftPane {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background-color: var(--white);
}

.banner {
  margin-top: var(--100px);
}
