/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */

.content {
  font-size: var(--13px);
  color: var(--gray-550);
}

.text {
  padding: var(--5px) var(--6px);
  line-height: var(--20px);
  word-break: break-all;
}

.command {
  display: flex;
  justify-content: space-between;
  background: var(--blue-50);
}

.dropdown {
  max-height: calc(2.6 * var(--100px));
}
