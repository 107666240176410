/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */

:root {
  --side-navigation-padding: 0 var(--13px);
  --side-navigation-item-radius: var(--8px);
  --side-navigation-text: var(--13px);
  --side-navigation-toggle-radius: var(--24px);
  --side-navigation-item-margin-t: var(--2px);
  --side-navigation-icon-scale: scale(1.2);
  --side-navigation-icon-size: var(--20px);
  --side-navigation-arrow-icon-size: var(--10px);
  --side-navigation-flyout-active-bg-color: rgb(var(--lightning--blue-gray-950-rgb) / 0.92);
  --side-navigation-search-bg-color: rgb(var(--lightning--white-rgb) / 0.9);
  --box-shadow-color: 47 59 76;
}

.flexCenterAlign {
  display: flex;
  align-items: center;
}

.navItem {
  composes: flexCenterAlign;
  justify-content: space-between;
  font-weight: var(--fontWeightSemiBold);
  border-radius: var(--side-navigation-item-radius);

  &.focused {
    color: var(--lightning--blue-900);
    outline: none;
    background: var(--lightning--blue-50);
  }
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90vh;
  margin-top: 0;
  padding: var(--side-navigation-padding);
  overflow: auto;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: var(--side-navigation-background-color);
}

.expanded {
  .container {
    /* Make a side navigation 18% of the screen width, but between 220px and 260px */
    width: var(--side-navigation-width);
  }

  .search-icon {
    display: none;
  }
}

.collapsed {
  .container {
    width: var(--side-navigation-width--collapsed);
  }

  .sidenav-delimiter {
    width: var(--48px);
  }

  .content {
    align-items: center;
    padding: 0;
    overflow: visible;
  }

  .menuItem,
  .item {
    display: flex;
    justify-content: center;
  }

  .name {
    display: none;
  }

  .searchInput {
    display: none;
  }

  .search {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--4px) var(--12px);
    border: none;
    border-radius: var(--side-navigation-item-radius);
  }

  .search-wrapper {
    display: none;
  }
}

/* checkbox toggle styling */
.toggle-checkbox {
  position: absolute;
  display: flex;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  width: var(--side-navigation-toggle-radius);
  height: var(--side-navigation-toggle-radius);
  font-size: var(--side-navigation-text);
  color: var(--lightning--blue-50);
  border-radius: var(--side-navigation-toggle-radius);
  background: var(--blue-200);
  transform: translate(50%, -50%);

  &:not(.toggle-checked):hover {
    .toggle-customBox-svg {
      stroke: var(--lightning--blue-50);
    }
  }
}

.toggle-customBox-svg {
  .toggle-checkbox:not(.toggle-disabled).toggle-checked & {
    fill: var(--lightning--blue-50);
    stroke: var(--lightning--blue-50);
  }
}

.checkboxWrapper {
  composes: flexCenterAlign;
  position: relative;
  height: calc(2 * var(--side-navigation-icon-size));
}

/* backdrop styling */
@media screen and (width < 1152px) {
  .expanded {
    z-index: 1000;

    .screenMobile {
      position: absolute;
      box-shadow: var(--4px) 0 var(--16px) 0 rgb(var(--blue-gray-800-rgb) / 0.25);
    }

    .backdrop {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgb(var(--blue-gray-800-rgb) / 0.25);
    }
  }
}

/* Navigation Item CSS */
.menuItem {
  width: 100%;
  padding: 0;
}

.menu {
  padding: 0;

  .menuItem-link {
    margin-top: var(--side-navigation-item-margin-t);
    padding: var(--8px) var(--40px);
    list-style-type: none;
  }
}

.menu,
.menuItem {
  margin-top: var(--side-navigation-item-margin-t);
  color: var(--lightning--blue-gray-600);
  list-style: none;
  cursor: pointer;
  user-select: none; /* Prevent item text selection on fast click */
}

.menuName {
  composes: flexCenterAlign;
}

.menuOpen {
  .menuName {
    color: var(--lightning--blue-900);
  }
}

.name {
  margin-left: var(--8px);
}

.menu-icon {
  font-size: var(--side-navigation-icon-size);
}

/* sidenav items with menu */
.flyoutMenu-menu {
  width: 100%;
  height: var(--0px);
  margin: var(--side-navigation-item-margin-t) 0;
  &:hover {
    border-radius: var(--side-navigation-item-radius);
    background: var(--lightning--blue-50);

    .flyoutMenu-trigger {
      color: var(--lightning--blue-900);
    }
  }
  > .flyoutMenu-trigger {
    padding: var(--0px);
  }
  > .flyoutMenu-dropdown {
    width: 100%;
    margin-top: var(--0px);
    box-shadow: unset;
    background: unset;
    backdrop-filter: unset;
    > .flyoutMenu-itemsContainer {
      width: 100%;
      margin: auto;
      .flyoutMenu-itemsList {
        padding: 0;
      }
      .flyoutMenu-itemsExtender {
        width: inherit;
      }
      .flyoutMenu-itemContent {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: var(--6px) var(--12px);
      }

      .flyoutMenu-item {
        display: flex;
        justify-content: center;
        margin: var(--side-navigation-item-margin-t) 0;
        color: var(--lightning--blue-gray-600);
        list-style: none;
        cursor: pointer;
        user-select: none; /* Prevent item text selection on fast click */
        &:not(:first-of-type) {
          margin: var(--8px) var(--0px);
        }
        &:hover {
          color: var(--lightning--blue-900);
          border-radius: var(--side-navigation-item-radius);
          background: var(--lightning--blue-50);
        }
      }
      .menuItem-activeItemContent {
        color: var(--lightning--blue-900);
        background: var(--lightning--blue-gradient);
      }
      .menuItem-activeDropdown {
        outline: none;
        background: var(--lightning--blue-50);
      }
    }
  }
}

.menuItem-link,
.flyoutMenu-trigger {
  outline: none;

  &:focus {
    background: var(--lightning--blue-50);
  }
}

.flyoutMenu-trigger {
  width: 100%;
  padding: var(--6px) var(--12px);
  color: var(--lightning--blue-gray-600);
  border-radius: var(--side-navigation-item-radius);
}

.flyoutMenu-drillIcon {
  display: none;
}

.flyoutMenu-dropdown {
  /* top: calc(-1 * var(--10px)); */
  /* @WIP temp solution until floating UI is implemented */
  opacity: 1;
  border: none;
  border-radius: var(--side-navigation-item-radius);
  box-shadow: var(--lightning-box-shadow-200);
  background: var(--side-navigation-flyout-active-bg-color);
  backdrop-filter: blur(var(--side-navigation-item-radius));
}

.flyoutMenu-item {
  color: white;

  &:hover {
    background: rgb(var(--lightning--blue-gray-600-rgb) / 0.5);
  }
}

.menuInformation,
.menuItem-link {
  composes: navItem;
  padding: var(--4px) var(--12px);

  > .menuNameContainer {
    padding: 0;
  }
}

.menuItem-link:not(.menuItem-active) {
  &:hover {
    color: var(--lightning--blue-900);
    cursor: pointer;
    background: var(--lightning--blue-50);
  }
}

.menuItem-link {
  color: var(--lightning--blue-gray-600);
}

.upArrow-icon,
.downArrow-icon {
  font-size: var(--side-navigation-arrow-icon-size);
}

.upArrow-icon {
  color: var(--lightning--blue-900);
}

.icon-tooltip {
  margin-left: var(--6px);
  padding: var(--2px) var(--6px);
  color: var(--white);
  border-radius: var(--side-navigation-item-radius);
  background: var(--side-navigation-flyout-active-bg-color);

  .icon-arrow {
    display: none;
  }
}

.menuItem-activeItemContent {
  border-radius: var(--side-navigation-item-radius);
  background: var(--side-navigation-flyout-active-bg-color);
}

.menuItem-active {
  color: var(--lightning--blue-900);
  background: var(--lightning--blue-gradient);
}

.activeGroup {
  &.menuClosed {
    color: var(--lightning--blue-900);
    background: var(--lightning--blue-gradient);

    .sideNav-itemContent {
      background: none;
    }
  }

  .flyoutMenu-menu {
    border-radius: var(--side-navigation-item-radius);
    background: var(--lightning--blue-gradient);
  }

  .flyoutMenu-trigger {
    color: var(--lightning--blue-900);
  }
}

/* search input */
.menuItemsContainer {
  overflow: hidden;
}

.search-icon {
  font-size: var(--side-navigation-icon-size);
  color: var(--lightning--blue-gray-600);
}

.search-wrapper {
  width: 100%;
}

.search-input {
  --inputAndText-vertical-margin: 0;

  font-size: var(--side-navigation-text);
  color: var(--lightning--blue-gray-300);
  border: var(--1px) solid var(--lightning--blue-gray-300);
  border-radius: var(--side-navigation-item-radius);
  background-color: var(--side-navigation-search-bg-color);

  &:focus {
    background: none;
  }

  &:hover {
    border-color: var(--lightning--blue-400);
    background-color: var(--lightning--white);

    &::placeholder {
      /* stylelint-disable-next-line declaration-no-important */
      color: var(--lightning--blue-gray-500) !important;
    }
  }

  &.search-withIcon {
    --textRightOfIcon: var(--30px);

    font-size: var(--side-navigation-text);
    font-style: normal;
  }

  &::placeholder {
    /* stylelint-disable-next-line declaration-no-important */
    color: var(--lightning--blue-gray-600) !important;
  }
}

.search-icon-icon {
  --icon-padding-left: var(--6px);

  font-size: var(--side-navigation-icon-size);
}

.search {
  composes: navItem;
  justify-content: flex-start;
  margin: 0 0 var(--10px) 0;

  &:hover {
    cursor: pointer;
    background: var(--lightning--blue-50);

    .search-icon {
      color: var(--lightning--blue-900);
    }
  }
}
