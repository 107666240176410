/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

.container {
  display: flex;
  flex-direction: column;
}

.menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: var(--200px);
  font-size: var(--13px);
  border: 1px solid rgb(222 224 227 / 1);
  border-radius: var(--3px);
  box-shadow: 0 1px 6px 1px rgb(0 0 0 / 0.3);
  background: var(--white);
}

.group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: var(--7px) var(--6px);
  list-style: none;
}

.group label {
  margin-left: var(--6px);
  padding: 10 var(--0px);
  font-weight: var(--fontWeightBold);
  line-height: var(--16px);
  color: rgb(44 60 77);
}

.groupItem {
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--5px) var(--16px) var(--6px) var(--16px);
  color: var(--blue-gray-800);
  text-align: start;
  cursor: pointer;
}

.groupItem:hover {
  background-color: var(--blue-gray-100);
}

.groupItem:hover label {
  color: var(--blue-600);
}

.groupItem:hover .filterIcon {
  width: 14px;
  height: 14px;
  font-size: var(--13px);
  color: var(--blue-600);
}

.groupItem .filterIcon {
  display: flex;
  align-items: center;
  color: var(--blue-100);
}

.groupItem button {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: baseline;
  width: 100%;
  padding: 0;
  border: 0;
  background: transparent;
}

.groupItem label {
  flex: 1 0 auto;
  width: 100%;
  text-align: start;
  white-space: nowrap;
  cursor: pointer;
  pointer-events: inherit;
}

.line {
  width: 100%;
  height: var(--1px);
  background: var(--gray-200);
}
