/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */

.group {
  position: relative;
  display: inline-flex;
}

.button {
  --button-progress-duration: 1.25s;
  --button-progress-to100-duration: 0.2s;
  --button-progress-over100-duration: 0.7s;
  --button-progress-fadeout-delay: calc(
    var(--button-progress-to100-duration) + var(--button-progress-over100-duration) + 0.05s
  );
  --button-progress-background-color: var(--orange-a200);
  --button-progress-background-color-success: var(--green-a700);
  --button-progress-background-color-error: var(--red-600);
  --button-progress-fadeout-delayTo100Only: calc(var(--button-progress-to100-duration) + 0.2s);
  --button-progress-fadeout-duration: 0.2s;
  --button-icon-text-padding-left: var(--5px);
  --button-menu-triggerLabel-padding-left: var(--5px);

  composes: centeredInlineFlex from 'utils.css';
  position: relative;
  padding: 0;
  vertical-align: top;
  line-height: inherit;
  cursor: pointer;
  user-select: none;
  border: var(--button-border-width, var(--0px)) solid var(--button-border-color, transparent);
  border-radius: var(--button-border-radius, 0);
  background-color: transparent;
  /* Add touch-action: manipulation for a fast reacting touch experience */
  /* https://twitter.com/argyleink/status/1405881231695302659 */
  touch-action: manipulation;
  /* Remove the semi-transparent highlight iOS applies to buttons */
  -webkit-tap-highlight-color: transparent;

  &:focus {
    outline: none; /* We replace default outline with shadow glow */
  }

  &.animateInAndOut:not(:active, .active) {
    /* Activation phase should be set instantly, without transition delay */
    transition: color 0.12s ease-in-out, background-color 0.12s ease-in-out, box-shadow 0.12s ease-in-out;
  }

  &.disabled,
  &.insensitive {
    cursor: default;
  }

  &.styled {
    min-height: var(--button-min-height);
    font-size: var(--button-font-size);
    line-height: 1.25;
    /* To avoid inheriting word breaks from parent components, like Modal */
    word-break: normal;
    overflow-wrap: normal;

    &.fill {
      &:not(.disabled) {
        color: var(--button-color);
        background-color: var(--button-background-color);

        &:hover:not(.insensitive) {
          background-color: var(--button-background-color-hover);
        }

        &:focus-visible:not(:active, .active) {
          box-shadow: var(--button-focus-glow);

          @nest html[data-theme='lightning'] & {
            outline: var(--2px) solid var(--button-focus-outline-color);
            outline-offset: var(--2px);
            box-shadow: none;
          }
        }

        &:active,
        &.active {
          background-color: var(--button-background-color-active);
        }
      }

      &.disabled {
        color: var(--button-color-disabled);
        background-color: var(--button-background-color-disabled);
      }
    }

    &:not(.fill) {
      &:not(.disabled) {
        color: var(--button-color-no-fill);

        &:hover:not(:active, .active, .insensitive) {
          color: var(--button-color-no-fill-hover, var(--button-color-no-fill));
          box-shadow: inset 0 0 0 1px var(--button-delimiter-color);
        }

        &:focus-visible:not(:active, .active) {
          box-shadow: inset 0 0 0 1px var(--button-delimiter-color);

          @nest html[data-theme='lightning'] & {
            outline: var(--2px) solid var(--button-focus-outline-color);
            outline-offset: var(--2px);
            box-shadow: none;
          }
        }

        &:active,
        &.active {
          color: var(--button-color-no-fill-active, var(--button-color));
          background-color: var(--button-background-color-no-fill-active, var(--button-background-color-active));

          @nest html[data-theme='lightning'] & {
            --button-border-width: var(--1px);
            --button-border-color: var(--button-border-color-no-fill-active);
          }
        }
      }

      &.disabled {
        color: var(--button-color-no-fill-disabled);
      }
    }
  }

  /* Button inside group */
  .group & {
    /* All buttons except the last one */
    /* Group delimiter. Consist of right border that is colored on filled button and smaller ::after with 70% height */
    &:not(.isGroupLast) {
      border-right: 1px solid transparent;

      &::after {
        position: absolute;
        display: block;
        content: '';
        top: 15%;
        bottom: 15%;
        left: 100%;
        width: 1px;
        /* Delimiter color is the same for filled and not filled buttons */
        background-color: var(--button-delimiter-color);
      }

      &:not(:active, .active, .disabled)::after {
        transition: top 0.12s ease-in-out, bottom 0.12s ease-in-out, background-color 0.12s ease-in-out;
      }

      /* After part should grow full height on focus/hover/active */
      &:focus-visible,
      &:hover,
      &:active,
      &.active {
        &:not(.disabled)::after {
          top: calc(-1 * var(--button-border-width, var(--0px)));
          bottom: calc(-1 * var(--button-border-width, var(--0px)));
        }
      }

      /* Delimiter of filled button should have the same background color as button itself */
      &.fill {
        border-right-color: var(--button-background-color);
      }

      &.disabled {
        &::after {
          background-color: var(--button-delimiter-disabled);
        }

        &.fill {
          border-right-color: var(--button-background-color-disabled);
        }
      }
    }

    &.isGroupFirst:not(.isGroupLast) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      .progressWrapper {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    /* All buttons starting from the second */
    &:not(.isGroupFirst, .disabled) {
      /* Cover delimiter of previous button on hover/active state */
      &:hover,
      &:active,
      &.active {
        box-shadow: var(--button-delimiter-left-cover);
      }

      /* Filled button in focus state should have focus glow, cover delimiter of previous button and own glow above it */
      &.fill:focus-visible:not(:active, .active) {
        box-shadow: var(--button-delimiter-left-cover), var(--button-focus-glow-left-cover), var(--button-focus-glow);
      }
    }

    &:not(.isGroupFirst, .isGroupLast) {
      border-radius: 0;

      .progressWrapper {
        border-radius: 0;
      }
    }

    &:not(.isGroupFirst).isGroupLast {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      .progressWrapper {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    /* Not filled focused button should have: (Own right delimiter grows automatically on focus/hover) */
    &:hover:not(.fill, :active, .active, .disabled),
    &:focus-visible:not(.fill, :active, .active, .disabled) {
      /* First one: top/bottom/left borders */
      &.isGroupFirst {
        box-shadow: inset 1px 0 0 0 var(--button-delimiter-color), inset 0 1px 0 0 var(--button-delimiter-color),
          inset 0 -1px 0 0 var(--button-delimiter-color);
      }

      /* Middle ones: top/bottom border and cover left delimiter of previous button */
      &:not(.isGroupFirst, .isGroupLast) {
        box-shadow: -1px 0 0 0 var(--button-delimiter-color), inset 0 1px 0 0 var(--button-delimiter-color),
          inset 0 -1px 0 0 var(--button-delimiter-color);
      }

      /* Last one: top/right/bottom border and cover left delimiter of previous button */
      &:not(.isGroupFirst).isGroupLast {
        box-shadow: -1px 0 0 0 var(--button-delimiter-color), inset -1px 0 0 0 var(--button-delimiter-color),
          inset 0 1px 0 0 var(--button-delimiter-color), inset 0 -1px 0 0 var(--button-delimiter-color);
      }
    }

    /* Widen progress on group buttons to cover the preceding delimiter */
    &:not(.isGroupFirst) .progressWrapper {
      left: calc(-1 * var(--1px));
    }
  }

  @nest html[data-theme='lightning'] & {
    --button-progress-background-color: var(--lightning--yellow-400);
    --button-progress-background-color-success: var(--lightning--green-500);
    --button-progress-background-color-error: var(--lightning--red-500);
  }
}

/* Padding is applied to either button when it's a menu or to the noOverflowContainer wrapper when it's needed */
.withHorizontalPadding {
  padding-right: calc(var(--button-right-padding, var(--0px)) - var(--button-border-width, var(--0px)));
  padding-left: calc(var(--button-left-padding, var(--0px)) - var(--button-border-width, var(--0px)));
}

.withVerticalPadding {
  padding-top: var(--button-top-padding, 0);
  padding-bottom: var(--button-bottom-padding, 0);
}

.noOverflowContainer {
  composes: centeredFlex from 'utils.css';
  align-self: stretch;
  overflow: hidden;
}

.progressWrapper {
  position: absolute;
  inset: 0;
  overflow: hidden;
  border-radius: var(--button-border-radius, 0);
}

.progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  color: var(--white);
  opacity: 1;
  pointer-events: none;
  background-color: var(--button-progress-background-color);
  will-change: opacity, width, background-color;
  transition: background-color var(--button-progress-to100-duration) 0s ease-out;
  animation-name: progressAnimation;
  animation-duration: var(--button-progress-duration);
  animation-timing-function: cubic-bezier(0.24, 0, 0.45, 1);
  animation-iteration-count: infinite;
}

/* When progress is done, briefly put progress animation on pause to get current progress width in componentDidUpdate */
.progressPause {
  animation-play-state: paused;
}

/* When paused loder width is computed, we set it directly on progress div and remove animation */
.progressStop {
  animation: none;
}

/* Now we can set the width to 100% directly on div and set transitions to reach it */
.progressTo100 {
  color: var(--black);
  opacity: 0;
  background-color: var(--button-progress-background-color-success);
  transition: width var(--button-progress-to100-duration) cubic-bezier(0.43, 0.64, 0.4, 1),
    background-color var(--button-progress-to100-duration) ease-out,
    /* Color transition to catch transition event for onProgressBeforeFade */ color
      calc(var(--button-progress-to100-duration) + var(--button-progress-over100-duration)) ease-out,
    opacity var(--button-progress-fadeout-duration) var(--button-progress-fadeout-delay) ease-out;
}

/* If there is no checkmark animation, transition should end quicker */
.progressTo100Only {
  transition: width var(--button-progress-to100-duration) cubic-bezier(0.43, 0.64, 0.4, 1),
    background-color var(--button-progress-to100-duration) ease-out,
    /* Color transition to catch transition event for onProgressBeforeFade */ color
      var(--button-progress-fadeout-delayTo100Only) ease-out,
    opacity var(--button-progress-fadeout-duration) var(--button-progress-fadeout-delayTo100Only) ease-out;
}

/* If progress errors out, set red color as final state */
.progressTo100Error {
  background-color: var(--button-progress-background-color-error);
}

/* Container of the progress icon is fixed, stretches to the button size and doesn't allow overflow */
.progressIcon {
  composes: centeredFlex from 'utils.css';
  position: absolute;
  justify-content: center;
  align-items: center;
  inset: 0;
  font-size: var(--button-progress-icon-size);
  color: var(--button-progress-icon-color);
  overflow: hidden;
  pointer-events: none;
}

.progressIconLeft {
  composes: progressIcon;
  justify-content: flex-start;
  margin-left: var(--6px);
}

/* Progress icon is moving and will not break out of its container */
.progressIcon-svg {
  opacity: 0;
  transform: translate3d(0, 180%, 0);
  will-change: opacity, transform;
  animation-name: moveLoaderIcon;
}

.animateContent {
  animation-name: moveLoadingContent;
}

.progressIcon-svg,
.animateContent {
  animation-duration: calc(
    var(--button-progress-to100-duration) + var(--button-progress-over100-duration) +
      var(--button-progress-fadeout-duration)
  );
  animation-delay: calc(var(--button-progress-to100-duration) / 2);
  /* Start content/checkbox moving on the halfway of 100% filling */
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  animation-fill-mode: forwards;
}

.showContentOnAction:not(:focus-visible, :hover, :active, .active) {
  .icon,
  .text,
  .menuIcon-icon {
    visibility: hidden;
  }
}

/* Sizes */
.small {
  --button-font-size: var(--13px);
  --button-icon-size: var(--12px);
  --button-menu-icon-size: var(--9px);
  --button-min-height: var(--24px);
  --button-top-padding: var(--1px);
  --button-right-padding: var(--5px);
  --button-bottom-padding: var(--2px);
  --button-left-padding: var(--5px);
  --button-border-radius: var(--2px);
  --button-progress-icon-size: var(--13px);

  .counter {
    --counter-diameter: var(--13px);

    padding: var(--0px);
    font-size: var(--10px);
  }

  @nest html[data-theme='lightning'] & {
    --button-min-height: var(--28px);
    --button-border-radius: var(--6px);
    --button-top-padding: var(--2px);
    --button-right-padding: var(--10px);
    --button-bottom-padding: var(--2px);
    --button-left-padding: var(--10px);
    --button-icon-text-padding-left: var(--6px);
    --button-menu-triggerLabel-padding-left: var(--0px);
  }
}

.medium {
  --button-font-size: var(--14px);
  --button-icon-size: var(--13px);
  --button-menu-icon-size: var(--10px);
  --button-min-height: var(--30px);
  --button-top-padding: var(--4px);
  --button-right-padding: var(--7px);
  --button-bottom-padding: var(--5px);
  --button-left-padding: var(--7px);
  --button-border-radius: var(--2px);
  --button-progress-icon-size: var(--14px);

  @nest html[data-theme='lightning'] & {
    --button-min-height: var(--32px);
    --button-border-radius: var(--7px);
    --button-icon-size: var(--16px);
    --button-top-padding: var(--2px);
    --button-right-padding: var(--offset-medium);
    --button-bottom-padding: var(--2px);
    --button-left-padding: var(--offset-medium);
    --button-icon-text-padding-left: var(--6px);
    --button-menu-triggerLabel-padding-left: var(--0px);
  }
}

.large {
  --button-font-size: var(--15px);
  --button-min-height: var(--40px);
  --button-top-padding: var(--8px);
  --button-right-padding: var(--7px);
  --button-bottom-padding: var(--10px);
  --button-left-padding: var(--7px);
  --button-border-radius: var(--3px);
  --button-progress-icon-size: var(--16px);
  --button-menu-icon-size: var(--12px);

  .counter {
    font-size: var(--13px);
  }

  @nest html[data-theme='lightning'] & {
    --button-font-size: var(--16px);
    --button-border-radius: var(--8px);
    --button-icon-size: var(--16px);
    --button-top-padding: var(--6px);
    --button-right-padding: var(--12px);
    --button-bottom-padding: var(--6px);
    --button-left-padding: var(--12px);
    --button-icon-text-padding-left: var(--8px);
    --button-menu-triggerLabel-padding-left: var(--2px);
  }
}

/* Types */
.primary {
  --button-color: var(--white);
  --button-color-disabled: var(--white);
  --button-color-no-fill: var(--blue-800);
  --button-color-no-fill-disabled: var(--blue-100);
  --button-background-color: var(--blue-600);
  --button-background-color-hover: var(--blue-400);
  --button-background-color-active: var(--blue-800);
  --button-background-color-disabled: var(--blue-100);
  --button-focus-glow: 0 0 0 0.2rem rgb(var(--blue-500-rgb) / 0.5);
  --button-focus-glow-left-cover: -0.25rem 0 0 0 var(--button-background-color);
  --button-delimiter-color: var(--blue-400);
  --button-delimiter-disabled: var(--blue-50);
  --button-delimiter-left-cover: -1px 0 0 0 var(--button-delimiter-color);

  @nest html[data-theme='lightning'] & {
    --button-background-color: var(--lightning--blue-600);
    --button-background-color-hover: var(--lightning--blue-500);
    --button-background-color-active: var(--lightning--blue-700);
    --button-background-color-no-fill-active: var(--lightning--blue-50);
    --button-background-color-disabled: var(--lightning--blue-100);
    --button-color-no-fill: var(--lightning--blue-600);
    --button-color-no-fill-disabled: var(--lightning--blue-100);
    --button-color-no-fill-active: var(--lightning--blue-500);
    --button-color-no-fill-hover: var(--lightning--blue-500);
    --button-delimiter-color: var(--lightning--blue-300);
    --button-border-color-no-fill-active: var(--lightning--blue-300);
    --button-focus-outline-color: var(--lightning--blue-200);
  }
}

.secondary {
  --button-color: var(--white);
  --button-color-disabled: var(--white);
  --button-color-no-fill: var(--green-800);
  --button-color-no-fill-disabled: var(--green-100);
  --button-background-color: var(--green-600);
  --button-background-color-hover: var(--green-400);
  --button-background-color-active: var(--green-800);
  --button-background-color-disabled: var(--green-100);
  --button-focus-glow: 0 0 0 0.2rem rgb(var(--green-500-rgb) / 0.5);
  --button-focus-glow-left-cover: -0.25rem 0 0 0 var(--button-background-color);
  --button-delimiter-color: var(--green-400);
  --button-delimiter-disabled: var(--green-50);
  --button-delimiter-left-cover: -1px 0 0 0 var(--button-delimiter-color);

  @nest html[data-theme='lightning'] & {
    --button-background-color: var(--lightning--green-600);
    --button-background-color-hover: var(--lightning--green-500);
    --button-background-color-active: var(--lightning--green-700);
    --button-background-color-no-fill-active: var(--lightning--green-50);
    --button-background-color-disabled: var(--lightning--green-200);
    --button-color-no-fill: var(--lightning--green-600);
    --button-color-no-fill-disabled: var(--lightning--green-200);
    --button-color-no-fill-active: var(--lightning--green-500);
    --button-color-no-fill-hover: var(--lightning--green-500);
    --button-delimiter-color: var(--lightning--green-300);
    --button-border-color-no-fill-active: var(--lightning--green-300);
    --button-focus-outline-color: var(--lightning--green-300);
  }
}

/* Filled Standard button always has a border by ux */
/* Active color differs from not-active */
.standard {
  --button-color: var(--blue-gray-700);
  --button-color-hover: var(--blue-gray-600);
  --button-color-active: var(--blue-gray-400);
  --button-color-disabled: var(--gray-300);
  --button-color-no-fill: var(--blue-gray-700);
  --button-color-no-fill-disabled: var(--gray-300);
  --button-background-color: var(--blue-gray-100);
  --button-background-color-hover: var(--blue-gray-50);
  --button-background-color-active: var(--blue-gray-300);
  --button-background-color-disabled: var(--blue-gray-50);
  --button-focus-glow: 0 0 0 0.2rem rgb(var(--blue-gray-300-rgb) / 0.5);
  --button-focus-glow-left-cover: -0.25rem 0 0 0 var(--button-background-color);
  --button-delimiter-color: var(--blue-gray-300); /* Delimiter color is the same for filled and not filled buttons */
  --button-delimiter-left-cover: -1px 0 0 0 var(--blue-gray-150);
  --button-delimiter-disabled: var(--blue-gray-100);
  --button-border-width: var(--1px);
  --button-progress-icon-color: var(--white);

  .animateBlackToWhiteAndBack {
    animation-name: animateStandardColor;
    animation-duration: calc(var(--button-progress-fadeout-delay) + var(--button-progress-fadeout-duration));
    animation-timing-function: cubic-bezier(0.43, 0.64, 0.4, 1);
    animation-fill-mode: forwards;
  }

  .animateBlackToWhiteAndBackTo100Only {
    animation-duration: calc(var(--button-progress-fadeout-delayTo100Only) + var(--button-progress-fadeout-duration));
  }

  &.styled:not(.disabled) {
    &.fill {
      &:not(:active, .active) {
        --button-border-color: var(--blue-gray-150);
      }

      &:hover:not(.insensitive) {
        --button-color: var(--button-color-hover);
      }

      &:active,
      &.active {
        --button-color: var(--button-color-active);
        --button-border-color: var(--blue-gray-400);
      }
    }

    &:not(.fill) {
      &:hover:not(:active, .active, .insensitive) {
        --button-border-color: var(--blue-gray-150);

        box-shadow: none;

        @nest html[data-theme='lightning'] & {
          color: var(--button-color-no-fill-hover, var(--button-color-no-fill));
          box-shadow: inset 0 0 0 var(--1px) var(--button-delimiter-color);
        }
      }

      &:focus-visible:not(:active, .active) {
        --button-border-color: var(--blue-gray-150);

        box-shadow: none;

        @nest html[data-theme='lightning'] & {
          outline: var(--2px) solid var(--lightning--blue-gray-200);
          outline-offset: var(--2px);
          box-shadow: none;
        }
      }

      &:active,
      &.active {
        --button-color: var(--white);
        --button-border-color: var(--blue-gray-400);
      }
    }

    @nest html[data-theme='lightning'] & {
      --button-color: var(--lightning--blue-800);
      --button-background-color: var(--lightning--blue-50);
      --button-background-color-hover: var(--lightning--blue-25);
      --button-background-color-active: var(--lightning--blue-100);
      --button-background-color-no-fill-active: var(--lightning--blue-gray-50);
      --button-background-color-disabled: var(--lightning--blue-25);
      --button-color-disabled: var(--lightning--blue-100);
      --button-border-width: var(--0px);
      --button-color-no-fill: var(--lightning--blue-gray-800);
      --button-color-no-fill-disabled: var(--lightning--blue-gray-300);
      --button-color-no-fill-active: var(--lightning--blue-gray-600);
      --button-color-no-fill-hover: var(--lightning--blue-gray-600);
      --button-delimiter-color: var(--lightning--blue-gray-300);
      --button-border-color-no-fill-active: var(--lightning--blue-gray-300);
      --button-color-hover: var(--lightning--blue-600);
      --button-color-active: var(--lightning--blue-800);
      --button-focus-outline-color: var(--lightning--blue-200);
    }
  }

  /* Secondary button changes some standard group styling */
  .group & {
    &:not(.isGroupLast) {
      &:not(.disabled) {
        /* Button on focus/hover/active should change delimiter color to the same as border */

        &:hover,
        &:focus-visible {
          &::after {
            --button-delimiter-color: var(--blue-gray-150);
          }
        }

        &:active,
        &.active {
          &::after {
            background-color: var(--blue-gray-400);
          }
        }
      }

      &.disabled {
        &.fill {
          border-right-color: var(--blue-gray-50);
        }
      }
    }

    &:not(.isGroupFirst) {
      border-left: none;

      &:active,
      &.active {
        --button-delimiter-left-cover: -1px 0 0 0 var(--blue-gray-400);
      }
    }

    /* Not filled focused/hovered standard button should override generic group button style and just have gray box */
    /* Add .button class to add specificity for that */
    &.button:hover:not(.fill, :active, .active),
    &.button:focus-visible:not(.fill, :active, .active) {
      box-shadow: 0 0 0 0 var(--button-border-color);

      &:not(.isGroupFirst) {
        box-shadow: -1px 0 0 0 var(--button-border-color);
      }
    }
  }

  /* Widen progress on standard button to cover borders */
  .progressWrapper {
    /* Loader should overlap standard border */
    top: calc(-1 * var(--1px));
    right: calc(-1 * var(--1px));
    left: calc(-1 * var(--1px));
    height: calc(100% + 2 * var(--1px));
  }

  /* For Safari, animation should be reset again for the same specificity if animation is reassigned  */
  .progressStop {
    animation: none;
  }
}

.icon {
  font-size: var(--button-icon-size);
}

.text,
.icon {
  /* Make them relative to overflow progress */
  position: relative;
}

.icon + .text,
.text + .icon {
  padding-left: var(--button-icon-text-padding-left);
}

.icon-icon {
  /* You can modify button icon styles using that classname in theming */
}

/* Button with menu section */
.menu-trigger {
  composes: withHorizontalPadding;

  /* Progress icon when there is no icon, should be moved to the left by the size of the dropdown icon */
  .progressIcon:not(.progressIconLeft) {
    margin-right: var(--15px);
  }
}

.menu-triggerLabel {
  composes: noOverflowContainer withVerticalPadding;
  display: flex;
}

/* Dropdown icon */
.menuIcon-icon {
  position: relative; /* To make Loader below menu dropdown icon */

  .menu-menuLarge & {
    padding-top: var(--2px);
    font-size: var(--button-menu-icon-size);
  }

  .menu-menuMedium & {
    font-size: var(--button-menu-icon-size);
  }

  .menu-menuSmall & {
    font-size: var(--button-menu-icon-size);
  }
}

/* Dropdown icon should o up if there is no icon and text after animating with checkmark */
.menuIconAnimate-icon {
  composes: menuIcon-icon;
  overflow: hidden;
}

.menuIconAnimate-svg {
  composes: animateContent;
}

.menu-triggerLabel.menu-realLabel + .menuIcon-icon {
  padding-left: var(--button-menu-triggerLabel-padding-left);
}

.menu-itemsList {
  /* Width 50px - 380px */
  min-width: var(--50px);
  max-width: calc(4 * var(--100px));
}

@keyframes progressAnimation {
  0% {
    width: 0;
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  100% {
    width: 100%;
  }
}

@keyframes moveLoadingContent {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  10% {
    opacity: 1;
  }

  25% {
    transform: translate3d(0, -180%, 0);
  }

  75% {
    opacity: 1;
    transform: translate3d(0, -180%, 0);
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes moveLoaderIcon {
  10% {
    opacity: 1;
  }

  25% {
    transform: translate3d(0, 0, 0);
  }

  75% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 180%, 0);
  }
}

@keyframes animateStandardColor {
  0% {
    color: var(--button-color);
  }

  20% {
    color: var(--white);
  }

  75% {
    color: var(--white);
  }

  100% {
    color: var(--button-color);
  }
}
