/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.pseudoSelector {
  composes: search from 'containers/Selectors/Selectors.css';
  padding: var(--2px);
}

.focused {
  border: 1px solid var(--blue-700);
}

.item {
  margin: var(--2px);
}

.labelSelector {
  flex-grow: 1;
}

.labelType {
  composes: bold from 'utils.css';
  display: flex;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  width: 120px;
  padding: 0 var(--10px);
}

.editLabelsRow {
  display: flex;
}

.undoButton {
  width: var(--55px);
  padding-left: var(--5px);
}

.content {
  padding-bottom: var(--6px);
  font-size: var(--13px);
  color: var(--gray-550);
}

.policyContent {
  padding: var(--12px) var(--12px) var(--6px) var(--12px);
  border: var(--1px) solid var(--blue-gray-200);
  border-radius: var(--5px);
}

.spinnerContainer {
  display: flex;
  align-items: center;
  height: var(--80px);
}

.icon-spinner {
  width: var(--25px);
  height: var(--25px);
  border-width: var(--4px);
}
