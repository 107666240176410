/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */

.badge {
  display: inline-block;
  min-width: var(--56px);
  padding: 0 var(--3px);
  font-size: var(--10px);
  line-height: var(--16px);
  color: var(--white);
  text-align: center;
  text-transform: uppercase;
  border-radius: var(--2px);

  @nest html[data-theme='lightning'] & {
    min-width: unset;
    padding: var(--1px) var(--4px) 0;
    font-size: var(--11px);
    font-weight: var(--fontWeightSemiBold);
    border-radius: var(--4px);
  }
}

.large {
  @nest html[data-theme='lightning'] & {
    min-width: unset;
    padding: var(--6px) var(--9px);
    font-size: var(--14px);
    font-weight: var(--fontWeight);
    text-transform: none;
    border-radius: var(--6px);
  }
}

.created {
  background: var(--green-600);

  @nest html[data-theme='lightning'] & {
    background: var(--lightning--green-500);
  }
}

.updated {
  background: var(--blue-gray-400);

  @nest html[data-theme='lightning'] & {
    background: var(--lightning--blue-400);
  }
}

.deleted {
  background: var(--red-500);

  @nest html[data-theme='lightning'] & {
    background: var(--lightning--red-400);
  }
}

.disabled {
  background: var(--gray-400);

  @nest html[data-theme='lightning'] & {
    color: var(--lightning--gray-600);
    background: var(--lightning--blue-gray-200);
  }
}

.new {
  min-width: unset;
  background: var(--green-700);
}

.preview {
  min-width: unset;
  background: var(--orange-500);

  @nest html[data-theme='lightning'] & {
    background: var(--lightning--orange-400);
  }
}

.info {
  background: var(--blue-700);

  @nest html[data-theme='lightning'] & {
    background: var(--lightning--blue-500);
  }
}

.low {
  background: var(--green-800);

  @nest html[data-theme='lightning'] & {
    color: var(--lightning--green-600);
    background: var(--lightning--green-50);
  }
}

.medium {
  background: var(--orange-700);

  @nest html[data-theme='lightning'] & {
    color: var(--lightning--orange-700);
    background: var(--lightning--orange-50);
  }
}

.high {
  background: var(--red-600);

  @nest html[data-theme='lightning'] & {
    color: var(--lightning--red-700);
    background: var(--lightning--red-50);
  }
}

.critical {
  background: var(--red-900);

  @nest html[data-theme='lightning'] & {
    color: var(--lightning--red-700);
    background: var(--lightning--red-100);
  }
}
