/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
.layout {
  display: flex;
  flex-direction: row;
}

.menu {
  display: flex;
  flex-direction: column;
  width: 290px;
  min-width: 290px;
  margin-top: var(--5px);
  color: var(--blue-gray-200);
}

.section {
  padding: var(--10px);
  font-weight: var(--fontWeightLight);
}

.apiName {
  margin-left: var(--10px);
  padding: var(--5px) var(--15px);
  font-weight: var(--fontWeightBold);
  color: var(--blue-gray-500);

  &:hover {
    cursor: pointer;
    background-color: var(--blue-gray-100);
  }
}

.selected {
  margin-left: var(--10px);
  padding: var(--5px) var(--10px);
  font-weight: var(--fontWeightBold);
  color: var(--blue-700);
  border-left: 5px solid var(--blue-700);
  background-color: var(--blue-gray-50);
}

.response {
  flex-grow: 1;
  background-color: var(--gray-50);
}

.responseHeader {
  padding: var(--10px);
  font-size: 1.2em;
  background-color: var(--gray-300);
}

.responseBody {
  margin: var(--15px);
}

.searchBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--10px) var(--20px) 0 var(--10px);
}
