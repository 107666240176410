/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
.added {
  color: var(--green-800);
}

.removed {
  color: var(--red-800);
  text-decoration: line-through;
}

.iconItem {
  color: var(--yellow-800);
}

.iconItemRemove {
  color: var(--red-600);
}

.attributeContainer {
  display: grid;
}
