/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.authSecPrincipalPicker {
  composes: gapSmall from 'utils.css';
  min-height: 200px;
}

.gridOutline {
  min-height: 200px;
  border: 1px solid var(--blue-gray-400);

  @nest html[data-theme='lightning'] & {
    border: none;
  }
}
