/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */

.menuHeading {
  color: var(--gray-500);
}

.menuOptionText {
  font-weight: var(--fontWeightBold);
}
