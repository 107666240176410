/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.dateTimePicker {
  --dt-section-width: 300px;
  --dt-section-width-small: 260px;
  --dt-picker-width: calc(2 * var(--dt-section-width));
  --dt-picker-width-small: calc(2 * var(--dt-section-width-small));
  --time-picker-bottom-padding: var(--10px);
  --time-title-display: none;
}

.picker {
  display: flex;
  z-index: 120;
  background: var(--blue-gray-50);
}

.dateTimePicker {
  composes: picker;
  flex-wrap: wrap;
  width: var(--dt-picker-width);
}

.datePicker {
  composes: picker;
  flex-direction: column;
  width: var(--dt-section-width);
}

.timePicker {
  composes: semi-bolder from global;
  composes: picker;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
  width: var(--dt-section-width);
  padding-top: var(--10px);
  padding-bottom: var(--time-picker-bottom-padding);
  color: var(--gray-800);
}

.arrows {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--34px);
  margin-bottom: calc(-1 * var(--34px));
  padding: 0 var(--50px);
  color: var(--white);
  cursor: pointer;
  background: var(--blue-800);
}

.timeTitle {
  composes: semiBold from global;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--dt-section-width);
  height: var(--34px);
  background: var(--blue-800);

  & span {
    display: var(--time-title-display);
    color: var(--white);
  }
}

.visible span {
  display: inline;
}

.timeTextField {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: var(--10px);
  color: var(--gray-800);
  text-align: center;

  & span {
    padding: 0 var(--15px);
  }

  & input {
    width: var(--30px);
    height: var(--40px);
    text-align: center;
    border: 1px solid var(--blue-gray-200);
    border-radius: var(--2px);
  }
}

.timeRangeField {
  display: flex;
  flex-direction: column;
  padding: var(--10px) var(--50px);
}

.smallSection {
  width: var(--dt-section-width-small);
}

.smallPicker {
  width: var(--dt-picker-width-small);
}

.absolute {
  position: absolute;
}

/* this is hardcoded for now, eventually the component that uses date time will have to calculate offsets for directional opening */
.openUp {
  margin-top: -23rem;
}

.shadow {
  box-shadow: 0 1px 1px rgb(var(--black-rgb) / 0.2);
}

.disabled {
  display: none;
}
