/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
.pageTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--18px);
  font-weight: var(--fontWeightSemiBold);
}
