/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */

.enforceGroup {
  composes: gapSmall gapHorizontal from 'utils.css';
}

.enforceGroupCategory {
  composes: enforceGroup;

  &.groupColumn {
    flex-direction: column;
  }
}

.enforceCategoryLineHeight {
  line-height: 1.7;
}

.enforceProvidingServices {
  flex: 1 1 0; /* Keep 'Providing Services' title on one line */
  flex-wrap: wrap;
}

.enforceBody {
  padding: 0.5em;
  border: 1px solid var(--gray-100);
  background-color: var(--gray-50);
}

.enforceMain {
  composes: enforceGroup;
  composes: enforceBody;
  display: grid;

  &.providerServicesStart {
    grid-template-columns: repeat(2, minmax(100px, max-content)) max-content minmax(100px, max-content);
  }

  &.consumerStart {
    grid-template-columns: minmax(100px, max-content) max-content repeat(2, minmax(100px, max-content));
  }
}

.iconContainer {
  padding-top: 0.2em;
  padding-right: 1em;
  padding-left: 1em;

  .enforcementScopePickerWrapped & .arrow-icon {
    transform: rotate(90deg);
  }
}

.enforceIconBreakpoint {
  composes: gapSmall from 'utils.css';
}
