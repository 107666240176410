/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */

.menu {
  composes: headerItem headerItemClickable headerItemTwoThirdWidth headerItemLeftDivider from '../Header/HeaderOld.css';
}

.trigger {
  flex: 0 0 100%;
  font-size: var(--16px);
}

.expandIcon-icon {
  font-size: var(--12px);
}

.dropdown {
  left: 0;
}
