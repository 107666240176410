/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
/* Illumination-Plus Color Palette */
:root {
  --map-transparent-background: transparent;
  --map-white-background: var(--white);
  --map-black-background: var(--black);
  --map-legend-grey-line: rgba(255 255 255 /0.7);
  --map-background: rgb(243 245 247);
  --map-combo-default: rgb(223 228 233);
  --map-combo-app-closed: rgb(66 165 245);
  --map-combo-app-open: rgb(216 233 247);
  --map-combo-role-closed: rgb(206 147 216);
  --map-combo-role-open: rgb(237 230 242);
  --map-combo-env-closed: rgb(38 165 153);
  --map-combo-env-open: rgb(212 233 233);
  --map-combo-loc-closed: rgb(133 122 214);
  --map-combo-loc-open: rgb(226 226 242);
  --map-combo-nodes-closed: rgb(167 172 176);
  --map-combo-nodes-open: rgb(223 228 233);
  --map-combo-nodes-appGroupMap-open: rgb(255 244 255);
  --map-combo-nodes-appGroupMap-closed: rgb(217 100 176);
  --map-combo-appGroup-closed: rgb(112 135 150);
  --map-combo-appGroup-open: rgb(223 228 233);
  --map-combo-appGroup-appGroupMap: rgb(227 242 252);
  --map-combo-border: transparent;
  --map-combo-mode-visibility-only: rgb(223 162 118);
  --map-combo-mode-selective: rgb(127 178 198);
  --map-combo-mode-full: var(--blue-gray-600);
  --map-combo-label-color: var(--text-color);
  --map-endpoint-workload: rgb(168 172 176);
  --map-endpoint-workload-background: var(--white);
  --map-endpoint-workload-label-color: var(--text-color);
  --map-endpoint-unmanagedWorkload: rgb(62 73 125);
  --map-endpoint-unmanagedWorkload-background: var(--white);
  --map-endpoint-unmanagedWorkload-border: transparent;
  --map-endpoint-unmanagedWorkload-label-color: var(--text-color);
  --map-endpoint-cloudWorkload: rgba(8 95 187 /1);
  --map-map-label: rgb(0 3 51);
  --map-map-label-background: transparent;
  --map-map-link-blocked: var(--red-400);
  --map-map-link-deficiency-blocked: rgb(200 53 32);
  --map-map-link-allowed: var(--green-700);
  --map-map-link-deficiency-allowed: rgb(1 176 233);
  --map-map-link-potentially-blocked: rgb(229 146 52);
  --map-map-link-deficiency-potentially-blocked: rgb(255 209 128);
  --map-map-link-default: rgb(140 140 140);
  --map-map-link-label: rgb(46 56 66);
  --map-map-tooltip-bi-directional-link: rgb(212 219 226);
  --map-interaction-select: rgb(255 254 84);
  --map-combo-innerLabel-line: rgba(255 255 255 /0.5);
  --map-legend-background: rgb(238 240 242);
  --map-legend-box-shadow: rgb(187 187 187);
  --map-legend-link-not-calculated: rgb(178 186 187);
  --map-legend-combo-shallower: rgb(205 207 209);
  --map-dashboard-enforcement-mode-idle: rgb(227 230 233);
  --map-dashboard-enforcement-mode-visibility: rgb(245 199 142);
  --map-count-glyph-color: var(--map-map-link-potentially-blocked);
  --map-count-glyph-label-color: var(--white);
}

html[data-theme='lightning'] {
  --map-map-link-blocked: var(--lightning--red-500);
  --map-map-link-potentially-blocked: var(--lightning--yellow-500);
  --map-map-link-allowed: var(--lightning--green-500);
  --map-map-link-default: var(--lightning--gray-600);
  --map-endpoint-unmanagedWorkload: var(--lightning--white);
  --map-endpoint-workload: var(--lightning--white);
  --map-endpoint-unmanagedWorkload-background: var(--lightning--blue-gray-300);
  --map-endpoint-workload-background: var(--lightning--blue-gray-600);
  --map-interaction-select: rgb(255 250 137);
  --map-count-glyph-color: var(--lightning--orange-200);
  --map-count-glyph-label-color: var(--lightning--orange-900);
  --map-background: var(--lightning--white);
  --map-combo-label-color: var(--lightning--blue-gray-900);
  --map-endpoint-workload-label-color: var(--lightning--blue-gray-900);
  --map-endpoint-unmanagedWorkload-label-color: var(--lightning--blue-gray-900);
  --map-combo-mode-visibility-only: var(--lightning--orange-300);
  --map-combo-mode-selective: var(--lightning--blue-500);
  --map-combo-mode-full: var(--lightning--green-400);
}

.graphPage {
  display: flex;
  height: calc(6.5 * var(--100px));
  padding-top: var(--10px);
}

.select {
  padding-bottom: var(--10px);
}

.data {
  width: 70%;
  margin-left: var(--5px);
  padding: var(--10px);
  overflow: scroll;
  box-shadow: 0 0 2px 2px var(--gray-100);
  background-color: var(--gray-50);
  transition: width 1s;
}

.dataClose {
  width: 0;
  transition: width 1s;
}

.graph {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: var(--map-background);
}

.stats {
  position: absolute;
  z-index: calc(var(--map-toolbar-z-index) - 1);
  top: 0;
  right: 0;
  padding: var(--offset-xsmall);
}
