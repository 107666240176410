/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

:root {
  --map-filter-consumer-provider-service-min-width: calc(var(--100px) * 2); /* 200px */
  --map-filter-column-minmax: minmax(var(--map-filter-consumer-provider-service-min-width), auto);
}

/* container styles */
.container {
  display: grid;
  grid-template-areas:
    'consumerProviderServiceFieldGroup .'
    'additionalFieldsWrapper additionalFieldsWrapper';
  grid-auto-columns: auto min-content;
  padding: var(--10px) var(--10px) var(--10px) var(--10px);
  column-gap: var(--8px);
  row-gap: var(--8px);

  @nest html[data-theme='lightning'] & {
    grid-template-areas:
      'consumerProviderServiceFieldGroup basicModeToggle'
      'additionalFieldsWrapper additionalFieldsWrapper';

    &.basicMode {
      grid-template-areas: 'consumerProviderServiceFieldGroup additionalFieldsWrapper basicModeToggle';
      grid-auto-columns: auto auto min-content;
    }
  }
}

.basicModeToggle {
  display: flex;
  grid-area: basicModeToggle;
  align-self: flex-start;
}

/* consumerProviderService field group styles */
.consumerProviderServiceFieldGroup {
  display: grid;
  grid-area: consumerProviderServiceFieldGroup;
  flex-grow: 1;
  grid-template-areas:
    'consumerInclude swapInclude providerInclude servicesInclude'
    'consumerExclude swapExclude providerExclude servicesExclude';
  grid-auto-columns: auto max-content auto auto;
  row-gap: var(--10px);
  column-gap: var(--6px);

  &.excludeHidden {
    grid-template-areas: 'consumerInclude swapInclude providerInclude servicesInclude';
  }

  &.consumerOrProvider {
    grid-template-areas:
      'consumerOrProviderInclude servicesInclude'
      'consumerOrProviderExclude servicesExclude';
    grid-auto-columns: var(--map-filter-column-minmax) var(--map-filter-column-minmax);

    &.excludeHidden {
      grid-template-areas: 'consumerOrProviderInclude servicesInclude';
    }
  }

  &.basicMode {
    grid-template-areas:
      'consumerOrProviderInclude'
      'consumerOrProviderExclude';
    grid-auto-columns: var(--map-filter-column-minmax);

    &.excludeHidden {
      grid-template-areas: 'consumerOrProviderInclude';
    }
  }
}

.additionalFieldsWrapper {
  display: flex;
  grid-area: additionalFieldsWrapper;
  flex-grow: 1;
  flex-wrap: wrap;
  row-gap: var(--8px);
}

.basicMode .additionalFieldsWrapper {
  flex-grow: 0;
  flex-wrap: nowrap;
  align-items: flex-start;
}

.additionalFields {
  display: flex;
}

.controlButtonGroupWrapper {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
}

.basicMode .controlButtonGroupWrapper {
  flex-grow: 0;
  justify-content: flex-start;
}

/* grid area definitions */
.consumerInclude {
  grid-area: consumerInclude;
}

.providerInclude {
  grid-area: providerInclude;
}

.consumerOrProviderInclude {
  grid-area: consumerOrProviderInclude;
}

.servicesInclude {
  grid-area: servicesInclude;
}

.swapInclude {
  grid-area: swapInclude;
}

.consumerExclude {
  grid-area: consumerExclude;
}

.providerExclude {
  grid-area: providerExclude;
}

.consumerOrProviderExclude {
  grid-area: consumerOrProviderExclude;
}

.servicesExclude {
  grid-area: servicesExclude;
}

.swapExclude {
  grid-area: swapExclude;
}

.time {
  grid-area: time;
}

.basicMode .time {
  padding-top: var(--3px);
}

.policyDecisions {
  grid-area: policyDecisions;
}

.filterSettings {
  grid-area: filterSettings;
}

.controlButtonGroup {
  grid-area: controlButtonGroup;
  justify-content: flex-end;
}

/* OptionSelector component customizations */
.selectorMain,
.selectorMain:focus,
.selectorMain.selectorActive {
  outline: none;
  border-color: transparent;
}

.selectMainLabel {
  padding-top: var(--0px);
  padding-bottom: var(--0px);
}

.icon .iconSvgArrowDown {
  width: var(--10px);
  height: var(--10px);
}

/* Selector component customizations  */
.selectorContainer {
  flex-grow: 1;
  align-self: stretch;
  flex-flow: row nowrap;
}

.selector {
  --searchBar-background-color-focused: var(--white);

  .items {
    align-items: flex-start;

    @nest html[data-theme='lightning'] & {
      align-items: unset;
    }
  }

  .valuePanel {
    padding-top: var(--2px);
  }

  .icon .svg {
    vertical-align: text-bottom;
  }

  .label {
    white-space: nowrap;
  }
}

.legend {
  margin-left: var(--12px);
}

.highlighted {
  color: var(--text-color);
  background: var(--blue-gray-100);
}

.optionPanel .resources > * {
  justify-content: flex-start;
}

.dropdown {
  width: auto;

  &.noCategoryPanel .optionPanel {
    min-width: calc(var(--100px) + var(--80px));
  }

  .dropdownLabel {
    white-space: nowrap;
  }
}

/* policyDecisions field style overrides */
.policyDecisions .selectorContainer .label {
  word-break: break-word;
}

.policyDecisions .searchBarContainer {
  --searchBar-background-color: transparent;
  --searchBar-border-color-focused: transparent;
  --searchBar-background-color-focused: transparent;

  background: transparent;
}

.policyDecisions .selector .input,
.policyDecisions .selector .inputPanel {
  position: absolute;
  bottom: 0;
  width: var(--0px);
  height: var(--0px);
}

.policyDecisions .selector .searchBarContainer {
  padding-top: var(--3px);
  padding-bottom: var(--0px);
  padding-left: var(--0px);
}

.policyDecisions .selector .searchBar,
.policyDecisions .selector .searchBar.focused,
.policyDecisions .selector:focus-within .searchBar:not(.error) {
  top: calc(var(--9px) * -1);
  border: none;
}

.policyDecisions .selector .items {
  padding: 0;
}

.policyDecisions .selector .valuePanel {
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
}

.policyDecisions .button.styled.standard {
  border: none;

  & .icon {
    --button-icon-size: 1em;

    color: var(--text-color);
  }

  @nest html[data-theme='lightning'] & {
    color: var(--text-color);
  }
}

.policyDecisions .button.styled.standard:not(.disabled):active,
.policyDecisions .button.styled.standard:not(.disabled):hover {
  --button-border-color: transparent;
  --button-color: var(--text-color);

  color: var(--text-color);
  background: transparent;

  @nest html[data-theme='lightning'] & {
    --button-border-width: var(--0px);

    color: var(--text-color);
    border-color: transparent;
    box-shadow: none;
  }
}

.policyDecisions .dropdown .footer {
  display: none;
}

.policyDecisions .selectedValueText {
  white-space: normal;
  word-break: normal;
}

.policyDecisions .toggle-icon > span {
  font-size: 0.9em;
}

.resources {
  height: unset;
}

/* swapInclude & swapExclude field style overrides */
.swapInclude .button,
.swapExclude .button {
  --button-left-padding: var(--0px);
  --button-right-padding: var(--0px);

  padding-top: 0;
  padding-bottom: 0;

  .icon {
    font-size: calc(var(--fontSize) * 1.5);
  }
}

.settingsButton-menu-dropdownWithArrow::before {
  display: none;
  margin-top: var(--2px);
}

.favoriteFiltersButton-menu-dropdownWithArrow {
  margin-top: var(--1px);
}

.item:hover {
  background-color: var(--gray-100);

  @nest html[data-theme='lightning'] & {
    background-color: var(--lightning--blue-50);
  }
}
