/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */

.wrapper {
  display: flex;
  width: 100%;
  height: var(--17px);
}

.item {
  display: flex;
}

.item {
  &:not(.link) {
    color: var(--lightning--blue-gray-600);
  }
}

.itemContent {
  white-space: nowrap;
}

.name {
  padding-left: var(--4px);
  font-weight: var(--fontWeight);
}

.separator {
  display: flex;
  align-items: center;
  padding: var(--2px) var(--14px) 0;
  font-size: 0.7em;
}

.breadcrumbs-button {
  &.breadcrumbs-styled {
    min-height: var(--4px);
  }
}
