/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */

.groupView {
  --tabpanel-padding: var(--page-padding);

  composes: gap from 'utils.css'; /* To make it inherit gap from upper container */
}

.add .areaBody {
  background-color: var(--green-a100);
}

.remove .areaBody {
  background-color: var(--red-a100);
}

.modified .areaBody {
  background-color: var(--blue-gray-50);
}

.svg {
  transform: scale(0.75);
}

.add-svg {
  composes: svg;
  color: var(--green-800);
}

.remove-svg {
  composes: svg;
  color: var(--red-700);
}

.tabPanel {
  position: sticky;
  z-index: var(--shadow-above-all-z);
}

.noDashboard-tabPanel {
  --tabpanel-height: calc(var(--tabpanel-height-primary) + var(--tabpanel-padding));

  composes: tabPanel;
  composes: containerWidth from 'utils.css';
  top: var(--header-height);
  margin-top: calc(-1 * var(--tabpanel-padding));
  padding: var(--tabpanel-padding) var(--page-padding) 0;
}

.onDashboard-tabPanel {
  --tabpanel-background-color: var(--card-background-color);

  composes: tabPanel;
  composes: cardWidth from 'components/Card/Card.css';
  top: 0;
}

.button {
  /* progress animation with checkmark on menu with dropdown has overflow */
  overflow: hidden;
}
