/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.pill {
  position: relative;
  display: inline-block;
  min-width: var(--50px);
  font-size: var(--12px);
  line-height: var(--24px);
  color: var(--white);
  text-align: center;
  border-radius: var(--15px);
}

/* Vulnerability types */
.critical {
  composes: pill;
  background: var(--red-800);
}

.high {
  composes: pill;
  background: var(--red-500);
}

.medium {
  composes: pill;
  background: var(--orange-a700);
}

.low {
  composes: pill;
  background: var(--green-800);
}

.info {
  composes: pill;
  background: var(--blue-700);
}

.none {
  composes: pill;
  background: var(--gray-300);
}

.syncing-icon {
  font-size: var(--16px);
}

/* Internet cloud icon */
.internet-icon {
  position: absolute;
  top: -42%;
  right: -15%;
  font-size: var(--18px);
  color: var(--blue-gray-700);
  pointer-events: none;
}

.internet-svg {
  width: var(--20px); /* To fit stroke */
  stroke: var(--white);
  stroke-width: 3px;
  stroke-linejoin: round;
}

/* Clickable state */
.clickable {
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:focus-visible:not(:active) {
    /* Add custom focus outline */
    box-shadow: 0 0 3px 3px rgb(var(--blue-400-rgb) / 0.5), 0 0 0 1px var(--blue-500);
  }

  &:not(:active):hover,
  &:not(:active):focus {
    &.critical {
      background: var(--red-600);
    }

    &.high {
      background: var(--red-300);
    }

    &.medium {
      background: var(--orange-500);
    }

    &.low {
      background: var(--green-600);
    }

    &.info {
      background: var(--blue-500);
    }

    &.none {
      color: var(--gray-500);
      background: var(--gray-100);
    }
  }

  &:active {
    &.critical {
      background: var(--red-900);
    }

    &.high {
      background: var(--red-600);
    }

    &.medium {
      background: var(--orange-800);
    }

    &.low {
      background: var(--green-900);
    }

    &.info {
      background: var(--blue-800);
    }

    &.none {
      background: var(--gray-400);
    }
  }
}
