/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */

.expanded {
  .svg {
    width: 65%;
    height: var(--36px);
  }
}

.collapsed {
  .svg {
    width: 100%;
    height: var(--30px);
  }
}

.logosContainer {
  margin-top: var(--20px);
  margin-bottom: var(--28px);
  padding: 0 var(--13px);
}

.logosContainerCollapsed {
  margin-top: var(--30px);
}
