/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.details-list {
  margin-top: 0;
  margin-bottom: var(--10px);
}

.scopeAndRoles {
  composes: bold from 'utils.css';
  padding-top: var(--5px);
  font-size: var(--16px);
}

.externalUserRemoveDetails {
  padding: var(--10px) 0;
}

.table {
  width: 100%;

  & th {
    padding: var(--8px);
    text-align: left;
    border-bottom: 1px solid #dddf;
  }

  & td {
    padding: var(--8px);
    text-align: left;
  }
}
