/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */

.loading {
  display: flex;
  justify-content: center;
  padding: var(--30px) 0;
}

.banner {
  padding: var(--30px) 0;
}

.picker {
  width: calc(3 * var(--45px));
  background-color: var(--white);
}

.pickerDropdown {
  width: calc(2 * var(--85px));
  max-height: calc(3 * var(--100px));
}

.progress-graphBar {
  margin: var(--5px) 0;
}

.more {
  align-self: center;
}

.flows {
  width: 50px;
  text-align: end;
}

.policy {
  composes: bold from 'utils.css';
}

.list {
  padding-left: var(--10px);
}

.groups {
  margin: var(--5px) var(--10px);
}

.pill {
  margin-left: var(--offset-small);
}
