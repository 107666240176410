/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */

.table-minWidth_960 {
  .areaHead .cell {
    white-space: nowrap;
  }
}

.icon-spinner {
  width: var(--25px);
  height: var(--25px);
  border-width: var(--4px);
}

.downloadText {
  margin-left: var(--10px);
}

.deleted {
  color: var(--red-700);
}

/*
 Need to adjust the position of the modal to make room for the Date/Time Calendar
 TODO: to remove it once we have responsive dropdowns
 */
.generateModal-animator {
  align-items: self-start;
  padding-top: 5vh;
}

.timestampContainer {
  composes: gapXSmall from 'utils.css';
}

.labelText {
  font-weight: var(--fontWeight);
}

.labelSubText {
  color: var(--gray-550);
}
