/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */

/**
 * Banner themeing
 */
.introBanner-banner {
  width: 100%;
  max-width: 100%;
}

.introBanner-main {
  display: flex;
  min-height: calc(var(--100px) + var(--24px));
  margin: var(--10px) 0 var(--14px);
  padding: var(--20px);
  font-size: var(--21px);
  color: var(--white);
  border-radius: var(--3px);
  background: var(--indigo-500);

  @media (768px <= width <= 798px) {
    min-width: calc(var(--100px) * 7);
  }

  @media (width >= 798px) {
    min-width: calc(var(--100px) * 8);
  }
}

.introBanner-text {
  composes: normal from 'utils.css';
  color: var(--white);
  text-align: left;
}

.textHighlight {
  composes: bold from 'utils.css';
}

.authenticationBannerImage {
  max-height: calc(var(--100px) + var(--20px));
  margin-left: var(--10px);
}

/**
 * Card styling
 */
.cardContainer,
.cardDetails {
  display: flex;
  flex-direction: column;
  max-width: calc(var(--100px) * 7.5);
  margin: 0 auto var(--15px) auto;
}

.card {
  display: flex;
  justify-content: space-between;
  min-height: var(--80px);
  margin-bottom: var(--14px);
  padding: var(--10px) var(--10px) var(--10px) var(--20px);
  color: var(--blue-gray-600);
}

.cardTitle {
  composes: bold from 'utils.css';
  text-transform: uppercase;
}

.cardText {
  font-size: var(--12px);
}

.cardAction {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cardDetails {
  min-height: var(--80px);
  padding-top: var(--20px);
  padding-bottom: var(--20px);
  padding-left: var(--20px);
  border-left: var(--10px) solid var(--blue-700);
}

.link {
  display: inline-block;
  margin-right: var(--15px);
}

.cardDetailsTitle {
  composes: bold from 'utils.css';
  margin-bottom: var(--10px);
}

.cardDetailsBody {
  margin-bottom: var(--10px);
}
