/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */

.navigationAlertContainer {
  position: fixed;
  display: flex;
  z-index: var(--shadow-depth-4z);
  justify-content: center;
  top: 0;
  right: 0;
  left: 0;
  pointer-events: none;
}

.navigationAlert {
  --navigationAlert-padding: var(--offset-medium);

  display: grid;
  grid-template-areas: 'statusIcon text buttons closeIcon';
  grid-column-gap: var(--offset-medium);
  max-width: 90%;
  min-height: var(--54px);
  padding-right: var(--navigationAlert-padding);
  padding-left: var(--navigationAlert-padding);
  pointer-events: auto;
  border: var(--1px) solid transparent;
  border-radius: 0 0 var(--4px) var(--4px);
  border-top: none;
  box-shadow: var(--shadow-depth-4);

  > * {
    display: flex;
    align-items: center;

    &:not(.buttons) {
      padding-top: var(--navigationAlert-padding);
      padding-bottom: var(--navigationAlert-padding);
    }
  }
}

.warning {
  composes: navigationAlert;
  border-color: var(--yellow-400);
  background-color: var(--yellow-100);

  .status-icon {
    color: var(--yellow-800);
  }
}

.error {
  composes: navigationAlert;
  color: var(--white);
  border-color: var(--red-800);
  background-color: var(--red-600);

  .status-icon {
    color: var(--white);
  }
}

.status-icon {
  grid-area: statusIcon;
  font-size: var(--17px);
  user-select: none;
}

.text {
  grid-area: text;
}

.buttons {
  composes: gapSmall gapHorizontal from 'utils.css';
  grid-area: buttons;
  justify-content: center;
  user-select: none;
}

.close-icon {
  grid-area: closeIcon;
  margin-right: calc(-1 * var(--navigationAlert-padding));
  margin-left: calc(-1 * var(--navigationAlert-padding) / 2);
  padding-right: calc(var(--navigationAlert-padding));
  padding-left: calc(var(--navigationAlert-padding) / 2);
  font-size: 0.6em;
  color: var(--gray-200);
  cursor: pointer;
  user-select: none;

  &:hover {
    color: var(--white);
  }
}
