/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */

.wrapper {
  position: relative;
}

.textarea {
  display: block;
  padding: var(--inputAndtextArea-vertical-padding) var(--6px);
  font-size: var(--form-input-text);
  line-height: var(--inputAndtextArea-line-height);
  border: var(--form-input-border);
  border-radius: var(--form-border-radius);
  background: var(--white);

  &:focus {
    outline: 0;
  }

  &:focus-visible {
    border-color: var(--form-focus-border-color);
    background: var(--white);
  }

  &:disabled {
    background: var(--blue-gray-50);
  }

  &.fullWidth {
    width: 100%;
  }

  &.error:not(:disabled) {
    border-color: var(--form-error-border-color);
  }

  @media (prefers-reduced-motion: no-preference) {
    transition: border-color 0.15s linear;
  }

  @nest html[data-theme='lightning'] & {
    margin-bottom: var(--4px);
    padding: calc(2 * var(--inputAndtextArea-vertical-padding)) var(--12px);
    color: var(--lightning--blue-gray-900);
    border-color: var(--lightning--gray-300);
    border-radius: var(--8px);

    &::placeholder {
      color: var(--lightning--blue-gray-500);
    }

    &:hover {
      border-color: var(--lightning--blue-200);
    }

    &:active {
      border-color: var(--lightning--blue-400);
    }

    &:focus {
      border-color: var(--lightning--blue-400);
      box-shadow: 0 0 0 1px var(--lightning--blue-50);
    }

    &.error:not(:disabled) {
      border-color: var(--lightning--red-400);

      &:hover {
        border-color: var(--lightning--red-300);
      }

      &:active {
        border-color: var(--lightning--red-500);
      }

      &:focus {
        box-shadow: 0 0 0 1px var(--lightning--red-50);
      }
    }

    &.success:not(:disabled) {
      border-color: var(--lightning--green-500);

      &:hover {
        border-color: var(--lightning--green-300);
      }

      &:active {
        border-color: var(--lightning--green-500);
      }

      &:focus {
        box-shadow: 0 0 0 1px var(--lightning--green-50);
      }
    }

    &.warn:not(:disabled) {
      border-color: var(--lightning--orange-500);

      &:hover {
        border-color: var(--lightning--orange-300);
      }

      &:active {
        border-color: var(--lightning--orange-500);
      }

      &:focus {
        box-shadow: 0 0 0 1px var(--lightning--orange-50);
      }
    }
  }
}
