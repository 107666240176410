/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */

.table {
  .cell.cellPadding.modified {
    padding-right: 0;
    padding-left: 0;
  }

  .cell.cellPadding.error {
    padding-right: 0;
  }
}
