/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */

.menu {
  /* From Header standard width to calc arrow central position */
  --triggerWidth: var(--50px); /* For big screens >= 1440px */

  /* Tablets */
  @media (768px <= width <= 960px) {
    --triggerWidth: var(--37px);
  }

  /* Small screens */
  @media (961px <= width <= 1024px) {
    --triggerWidth: var(--40px);
  }

  /* Normal screens */
  @media (1025px <= width <= 1439px) {
    --triggerWidth: var(--45px);
  }
}

.menu {
  composes: headerItemStandardWidth from '../Header/HeaderOld.css';
}

.headerItem {
  composes: headerItem headerItemStandardWidth from '../Header/HeaderOld.css';
}

.trigger {
  font-size: var(--17px);
}

.itemsList {
  /* Main menu dropdown width 190px - 300px */
  min-width: calc(1.9 * var(--100px));
  max-width: calc(3 * var(--100px));
}

.subDropdown .itemsList {
  /* Subdropdown width 100px - 300px */
  min-width: calc(1 * var(--100px));
}
