/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
.pageContainer {
  composes: pageContainer from 'antman/containers/styles.css';

  /* Need high specificity to override noFill `color` property */
  .toolBar .button:has(.progressWrapper) {
    color: var(--white);
  }
}

.labelText {
  font-size: var(--14px);
}

.svgBlueFill {
  fill: var(--blue-700);
}

.svgGrayFill {
  fill: var(--blue-gray-300);
}

.link {
  color: var(--blue-500);

  &:hover {
    cursor: pointer;
  }
}

.compatibilityReportToggle {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;

  &:hover {
    cursor: pointer;
  }
}
