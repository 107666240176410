/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */

.table-minWidth_960 {
  .areaHead .cell {
    white-space: nowrap;
  }
}

/* Highlight required by rows with  */
.rowOnMouseOverClick .areaBody {
  background-color: var(--yellow-50);
}
