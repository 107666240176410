/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */

/**
 * Modal styles
 */
.modal {
  position: absolute;
  top: calc((var(--100px) * 2));
  max-width: calc(var(--100px) * 7);
  border-radius: var(--15px);
  background-color: transparent;

  @nest html[data-theme='lightning'] & {
    box-shadow: none;
  }
}

.container {
  border-radius: var(--15px);
  background-color: var(--white);

  @nest html[data-theme='lightning'] & {
    border-radius: var(--8px);
    background: var(--lightning--blue-gray-100);
  }
}

/**
 * Input styles
 */

.input {
  position: relative;
  height: var(--60px);
  padding: var(--10px) 0 var(--10px) var(--40px);
  font-size: var(--22px);
  /**
   * Fix for Safari line height implementation which differs in calculation of height from other browsers.
   */
  line-height: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  outline: none;
  overflow: hidden;
  border-color: transparent;
  border-radius: var(--15px);
  background-color: transparent;

  &:focus {
    outline: none;
    border-color: transparent;
    background-color: transparent;
  }

  @nest html[data-theme='lightning'] & {
    --inputAndText-vertical-margin: 0;

    height: var(--44px);
    padding-left: var(--40px);
    font-size: var(--16px);
    border-color: transparent;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    transition: none;

    &:hover {
      border: none;
    }

    &:focus {
      border-color: transparent;
    }
  }
}

.inputBorderRadiusTop {
  composes: input;
  border-radius: var(--10px) var(--10px) 0 0;
}

.hint {
  position: absolute;
  top: 0;
  left: 0;
  color: var(--gray-400);
  opacity: 1;
}

.inputHint {
  composes: input hint;
}

.inputBorderRadiusTopHint {
  composes: inputBorderRadiusTop hint;
}

.icon {
  position: absolute;
  padding: var(--16px) var(--10px) var(--15px) var(--12px);
  font-size: var(--20px);
  color: var(--gray-550);

  @nest html[data-theme='lightning'] & {
    padding: var(--8px) var(--8px) var(--8px) var(--12px);
    font-size: var(--20px);
    color: var(--blue-gray-900-lightning);
  }
}

.listWrapper {
  max-width: calc(var(--100px) * 7);
  height: 0;
  max-height: calc((var(--100px) * 2.5) + var(--25px));
  will-change: width, height;
  transition: height 0.15s ease;

  @nest html[data-theme='lightning'] & {
    border-top: solid var(--1px) var(--lightning--blue-gray-300);
  }
}

/**
 * Suggestions
 */
.suggestions {
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;

  &:hover {
    cursor: pointer;
  }
}

.suggestionItem {
  height: var(--40px);
  padding: var(--5px) var(--10px) var(--5px) var(--40px);
  font-size: var(--20px);
  text-overflow: ellipsis;
  list-style: none;
  border-top: var(--1px) solid var(--gray-100);
  background-color: var(--white);

  @nest html[data-theme='lightning'] & {
    border-top: none;
  }
}

.suggestionHistoryItem {
  composes: suggestionItem;
  color: var(--blue-gray-500);

  @nest html[data-theme='lightning'] & {
    color: var(--lightning--blue-gray-900);
  }
}

.actions {
  display: flex;
}

.selectedSuggestionItem {
  composes: suggestionItem;
  color: var(--gray-50);
  background-color: var(--blue-600);

  .highlight {
    -webkit-text-stroke-color: var(--gray-50);
  }

  @nest html[data-theme='lightning'] & {
    color: var(--lightning--blue-900);
    border-radius: var(--8px);
    background: linear-gradient(136.72deg, rgb(0 221 251 / 0.15) 7.63%, rgb(0 178 255 / 0.15) 92.65%);

    .highlight {
      -webkit-text-stroke-color: var(--lightning--blue-600);
      -webkit-text-fill-color: var(--lightning--blue-600);
    }
  }
}

.button {
  display: flex;
}

/* Use stroke-width instead of bold font-weight to prevent highlighted letters from moving other letters as user typing in */
.highlight {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}

@keyframes appearIn {
  0% {
    opacity: 0.4;
    transform: scale(0.98);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animateFilter {
  animation: appearIn 0.2s;
}

.filter {
  composes: animateFilter;
  position: absolute;
  top: var(--13px);
  left: var(--45px);
  padding: var(--4px) var(--10px);
  font-size: var(--22px);
  line-height: normal;
  color: var(--gray-50);
  pointer-events: none;
  border-radius: var(--10px);
  background-color: var(--blue-600);
  will-change: transform, opacity;

  @nest html[data-theme='lightning'] & {
    top: var(--6px);
    padding: var(--8px) var(--12px);
    font-size: var(--13px);
    border-radius: var(--8px);
    background-color: var(--lightning--blue-600);
  }
}

.noResultsFound {
  position: relative;
  float: right;
  right: var(--14px);
  bottom: var(--42px);
  height: 0;
  font-size: var(--16px);
  color: var(--gray-550);

  @nest html[data-theme='lightning'] & {
    bottom: var(--34px);
  }
}

.shortcuts {
  padding: var(--10px);
  font-size: var(--14px);
  color: var(--gray-550);
  text-align: right;
  border-bottom-left-radius: var(--10px);
  border-bottom-right-radius: var(--10px);
  background-color: var(--gray-50);

  @nest html[data-theme='lightning'] & {
    background-color: var(--lightning--blue-gray-50);
  }
}

.shortcut {
  font-weight: var(--fontWeightBold);
}

.shortcutOffset {
  composes: shortcut;
  vertical-align: sub;
}

.shortcutText {
  margin: 0 var(--10px) 0 var(--5px);
}

.divider {
  display: block;
  border-top: var(--1px) solid var(--gray-300);
}

@keyframes fadein {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

.animateSuggestionItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  animation: fadein 0.5s;
}

@keyframes shimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.animatedBackground {
  position: relative;
  background: #f6f6f6ff;
  background: linear-gradient(to right, whitesmoke 10%, #f1f1f1ff 21%, #f7f7f7ff 34%);
  background-size: calc(var(--100px) * 8) calc(var(--100px) + 4);
  will-change: background;
  animation-name: shimmer;
  animation-duration: 2.15s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
}

/**
 * Placeholder until skeleton styling is ready
 */
.textSkeleton {
  composes: animatedBackground;
  display: block;
  width: calc((2 * var(--100px) + var(--70px)));
  height: var(--25px);
  margin: var(--3px) 0 0;
  border-radius: var(--4px);
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-5px);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translateX(5px);
  }
}

.shake {
  composes: container;
  will-change: transform;
  animation-name: shake;
  animation-duration: 0.6s;
  animation-timing-function: linear;
}

.recentSearches {
  display: flex;
  justify-content: space-between;
  padding: var(--5px) var(--13px);
  font-weight: var(--fontWeight);
  color: var(--blue-gray-500);
  border-top: solid var(--1px) var(--gray-100);
  background-color: var(--gray-50);

  @nest html[data-theme='lightning'] & {
    border-top: solid var(--1px) var(--lightning--blue-gray-300);
    background-color: var(--lightning--blue-gray-50);
  }
}

.recentSearchesText {
  align-self: center;
}

.clearAllButton {
  --button-color-no-fill: var(--blue-gray-500);

  composes: button;

  @nest html[data-theme='lightning'] & {
    /* WIP */
    /* stylelint-disable-next-line declaration-no-important */
    color: var(--lightning--blue-600) !important;
  }
}

.recentSearchesIcon {
  composes: icon;
  position: absolute;
  left: var(--13px);
  padding: 0;
  font-size: var(--20px);
  color: var(--blue-gray-500);

  @nest html[data-theme='lightning'] & {
    padding: 0;
    color: var(--lightning--blue-gray-900);
  }
}

.recentSearchesIconSelected {
  composes: recentSearchesIcon;
  color: var(--gray-50);

  @nest html[data-theme='lightning'] & {
    padding: 0;
    color: var(--lightning--blue-gray-900);
  }
}

.suggestionText {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
}

.delete-button {
  --button-color-no-fill: var(--blue-gray-500);

  composes: button;

  @nest html[data-theme='lightning'] & {
    /* WIP */
    /* stylelint-disable-next-line declaration-no-important */
    color: var(--lightning--blue-gray-600) !important;
  }
}

.selectedDelete-button {
  --button-color-no-fill: var(--gray-50);

  composes: button;

  @nest html[data-theme='lightning'] & {
    /* WIP */
    /* stylelint-disable-next-line declaration-no-important */
    color: var(--lightning--blue-900) !important;
  }
}
