/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */

.toggleButton {
  --toggle-button-background-color: var(--gray-200);
  --toggle-button-background-color-disabled: var(--gray-100);
  --toggle-button-outline-color: var(--blue-200);
  --toggle-button-handle-color: var(--blue-gray-700);
  --toggle-button-handle-color-hover: var(--blue-gray-600);
  --toggle-button-handle-color-active: var(--blue-gray-800);
  --toggle-button-handle-color-disabled: var(--blue-gray-400);
  --toggle-button-handle-checked-color: var(--white);
  --toggle-button-handle-checked-background-color: var(--blue-600);
  --toggle-button-handle-checked-background-color-hover: var(--blue-500);
  --toggle-button-handle-checked-background-color-disabled: var(--blue-100);

  @nest html[data-theme='lightning'] & {
    --toggle-button-background-color: var(--lightning--gray-200);
    --toggle-button-background-color-disabled: var(--lightning--gray-100);
    --toggle-button-outline-color: var(--lightning--blue-200);
    --toggle-button-handle-color: var(--lightning--gray-700);
    --toggle-button-handle-color-hover: var(--lightning--gray-600);
    --toggle-button-handle-color-active: var(--lightning--blue-gray-800);
    --toggle-button-handle-color-disabled: var(--lightning--gray-400);
    --toggle-button-handle-checked-color: var(--lightning--white);
    --toggle-button-handle-checked-background-color: var(--lightning--blue-600);
    --toggle-button-handle-checked-background-color-hover: var(--lightning--blue-500);
    --toggle-button-handle-checked-background-color-disabled: var(--lightning--blue-100);
  }
}

.toggleButton {
  display: flex;
  padding: var(--4px);
  border: none;
  border-radius: var(--100px);
  background-color: var(--toggle-button-background-color);
}

.handle {
  padding: var(--4px) var(--10px);
  color: var(--toggle-button-handle-color);
  cursor: pointer;
  border-radius: var(--100px);
}

.handle.checked {
  color: var(--toggle-button-handle-checked-color);
  background-color: var(--toggle-button-handle-checked-background-color);
}

.toggleButton:focus-visible {
  outline: var(--2px) solid var(--toggle-button-outline-color);
  outline-offset: var(--2px);
}

.toggleButton:not([disabled]):hover .handle:not(.checked) {
  color: var(--toggle-button-handle-color-hover);
}

.toggleButton:not([disabled]):active .handle:not(.checked) {
  color: var(--toggle-button-handle-color-active);
}

.toggleButton[disabled] {
  background-color: var(--toggle-button-background-color-disabled);
}

.toggleButton[disabled] .handle {
  cursor: default;
}

.toggleButton[disabled] .handle:not(.checked) {
  color: var(--toggle-button-handle-color-disabled);
}

.toggleButton[disabled] .handle.checked {
  background-color: var(--toggle-button-handle-checked-background-color-disabled);
}
