/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.table-minWidth_960 {
  .areaHead .cell {
    white-space: nowrap;
  }
}

.provisionPageHelp {
  margin: var(--10px) 0 var(--5px);
  font-size: var(--16px);
  line-height: var(--30px);
  color: var(--gray-900);
}

.provisionDependencies {
  margin-top: var(--20px);
}

.provisionCheckoutButtons {
  display: flex;
  justify-content: flex-end;
}

.provisionCheckout-button {
  margin: var(--10px) 0 var(--10px) var(--10px);
}

.provisionPageHelpSub {
  padding-left: var(--20px);
  font-size: var(--14px);
  color: var(--gray-900);
}

.close-icon {
  margin: 0 var(--10px);
  padding: var(--5px);
  font-size: var(--8px);
  color: var(--gray-500);
  cursor: pointer;
  &:hover {
    color: var(--black);
  }
}

.warning-icon {
  margin-right: var(--5px);
  font-size: var(--16px);
}

.info-icon {
  margin-right: var(--5px);
  font-size: var(--16px);
}

.critical-icon {
  margin-right: var(--2px);
  font-size: var(--16px);
  background: var(--red-900);
}

.tallyAlignment {
  margin: 0;
}

.tallies {
  width: 100%;
  margin-bottom: 0;
}

/* Highlight required by rows with  */
.rowToDependency .areaBody {
  background-color: var(--yellow-50);
}

/* To align summary attribute key with tallies */
.attributeKey {
  line-height: 2.2;
}

.warningMessage {
  display: flex;
  flex-direction: column;
}

.warningBlock {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

/* The buttons need to actually render, so move them off the screen */
.hide {
  position: fixed;
  visibility: hidden;
  width: 0;
  height: 0;
}
