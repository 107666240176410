/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

.infoPanel {
  position: absolute;
  right: var(--10px);
  bottom: var(--10px);
}

.filteringTips {
  composes: gapXSmall gapHorizontalWrap from '~utils.css';
  font-weight: var(--fontWeightBold);
  color: var(--blue-800);
}
