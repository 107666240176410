/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

.pageContainer {
  composes: gapXLarge gapHorizontalWrap from 'utils.css';
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  max-width: 100%;
  margin-top: var(--offset-x-large);

  .endpoints,
  .servers {
    flex-grow: 1;
    max-width: 50%;
    height: calc(3.1 * var(--100px));
  }
}

.oldPageContainer {
  composes: gapXLarge gapHorizontal from 'utils.css';
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'endpoints servers'
    'illumination illumination';
  max-width: 100%;
  margin: var(--offset-x-large) auto 0;
}

.button {
  width: max-content;
}

.legend li {
  font-size: var(--14px);
}

.itemValue {
  width: max-content;
  padding: 0 var(--5px);
  border-radius: var(--15px);
  background: var(--gray-100);
}

.illumination-card {
  grid-area: illumination;
}

.map-graph {
  /* .graph class uses absolute positioning, we don't want that, override it to relative positioning */
  position: relative;

  & canvas {
    /* regraph canvas uses inline styles, so the only way to override is to use 'important' */
    /* stylelint-disable-next-line declaration-no-important */
    max-width: 100% !important;
    /* stylelint-disable-next-line declaration-no-important */
    cursor: pointer !important;
  }
}

.graphContainer {
  width: 100%;
  height: calc(5 * var(--100px));
}

/* use height & width properties from parent container */
.skeleton,
.skeletonBlock {
  composes: graphContainer;
}

.illuminationCardTitle {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.refreshButton {
  display: flex;
  justify-content: center;
  width: var(--70px);
}

.refreshButtonInProgress {
  composes: refreshButton;
  justify-content: space-between;
}
