/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */

.dashboard {
  display: grid;
  flex: 1 1 auto;
  grid-template:
    'header header' min-content
    'notify notify' min-content
    'groups main' auto
    / minmax(400px, 2fr) 5fr;
  align-items: stretch;
  padding-bottom: var(--page-padding);
  gap: var(--offset-medium);
}

.dashboardWithDiscoveredWorkloads {
  composes: dashboard;
  grid-template:
    'header header' min-content
    'notify notify' min-content
    'groups main' auto
    'discoveredWorkloads main' max-content
    / minmax(440px, 2fr) 5fr;
}

.header {
  display: grid;
  grid-area: header;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  gap: var(--offset-large);
}

.notification {
  grid-area: notify;

  .message {
    display: flex;
    justify-content: space-between;
  }
}

.notificationMessage {
  /* Span the whole width to allow space-between to show Link at the end when displaying message */
  width: 100%;
}

/* On two-colum layout make the AppContent allocate the rest of the window's height, but not more than window and not less than 500px */
/* To make each column scrollable separately */
@media (width >= 961px) {
  .app-content {
    flex: 1 0 calc(5 * var(--100px));
    flex-direction: row;
    align-items: stretch;
    overflow: hidden;
  }

  .header {
    padding-bottom: var(--10px);
  }
}

/* On small screen put everything in one column but keep groups list scrollable inside itself */
@media (width <= 960px) {
  .dashboard {
    grid-template:
      'header' min-content
      'notify' min-content
      'groups' minmax(220px, 35vh)
      'main' minmax(50vh, auto)
      / 1fr;
  }

  .dashboardWithDiscoveredWorkloads {
    grid-template:
      'header' min-content
      'notify' min-content
      'groups' minmax(220px, 35vh)
      'discoveredWorkloads' max-content
      'main' minmax(50vh, auto)
      / 1fr;
  }
}

.title {
  composes: bold from 'utils.css';
  font-size: var(--20px);
}

.card {
  padding-bottom: 0;
}

.groups-card {
  composes: card;
  display: flex;
  grid-area: groups;
  flex-direction: column;
  justify-content: flex-start;
}

.groupsGrid {
  composes: cardWidth from 'components/Card/Card.css';
  flex: 1 1 0;
  overflow: auto;
  background: var(--white);
}

.groupsGrid-manager-manager {
  border-top-width: 0; /* Keep only groupsSelector border between grid and selector, since there is no gap */
}

.groupsSelector {
  composes: cardWidthBordersOverlap from 'components/Card/Card.css';
  margin-top: var(--offset-medium);
}

.groupsGrid-actions {
  composes: noWrap gapXSmall gapHorizontal from 'utils.css';
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.groupHeader {
  composes: gapMedium gapHorizontalWrap from 'utils.css';
  align-items: baseline;
  word-break: break-all;
}

.groupTitle {
  composes: bold from 'utils.css';
  font-size: var(--16px);
}

.groupWrapper {
  composes: cardWidth from 'components/Card/Card.css';
  flex: 1 1 auto;
  padding-right: var(--card-padding);
  padding-left: var(--card-padding);
  background-color: white;
}

/* In two-columns layout make grid scrollable separately */
@media (width >= 961px) {
  .groupWrapper {
    flex: 1 1 0;
    overflow: auto;
  }
}

.main-card {
  composes: card;
  composes: gapMedium from 'utils.css';
  display: flex;
  grid-area: main;
  flex-direction: column;
  justify-content: flex-start;
  /* To prevent TabPanel in from exploding 'main' grid item, assign 0 to its min-width, instead of default 'auto'. */
  /* We could use minmax(0, Xfr) in grid template, but since we have multiple breakpoints, using min-width is shorter */
  /* https://stackoverflow.com/a/43312314 */
  min-width: 0;
}

.status-badge {
  padding: var(--2px) var(--10px);
  line-height: 1.2;
}

.table-minWidth_360 {
  .areaHead .cell {
    white-space: nowrap;
  }
}

.discoveredWorkloadsTitle {
  --discovered-title-font-size: var(--20px);

  font-size: var(--discovered-title-font-size);

  &.discoveredWorkloadsGroupTitle {
    --discovered-title-font-size: var(--16px);
  }
}

.discoveredWorkloads-card {
  grid-area: discoveredWorkloads;
}

.discoveredWorkloads-icon {
  display: inline-flex;
  padding: var(--5px);
  font-size: var(--14px);
  color: var(--gray-50);
  border-radius: 100%;
  background: var(--orange-500);
}
