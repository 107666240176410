/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
.container {
  --diff-added-background-color-light: var(--green-a100);
  --diff-added-background-color-dark: var(--green-950);
  --diff-removed-background-color-light: var(--red-a100);
  --diff-removed-background-color-dark: var(--red-950);
  --diff-added-highlight-bg-color-light: var(--green-a200);
  --diff-added-highlight-bg-color-dark: var(--green-500);
  --diff-removed-highlight-bg-color-light: var(--red-a200);
  --diff-removed-highlight-bg-color-dark: var(--red-500);
  --diff-online-svg: var(--blue-700);
  --diff-add-svg-color-light: var(--green-800);
  --diff-add-svg-color-dark: var(--green-600);
  --diff-remove-svg-color-light: var(--red-700);
  --diff-remove-svg-color-dark: var(--red-600);
  --diff-unified-background-color: var(--blue-gray-50);

  display: inline-block;

  &.containerUnified {
    background-color: var(--diff-unified-background-color);
  }

  @nest html[data-theme='lightning'] & {
    --diff-added-background-color-light: rgb(var(--lightning--green-a100-rgb) / 0.15);
    --diff-added-background-color-dark: rgb(var(--lightning--green-a100-rgb) / 0.15);
    --diff-removed-background-color-light: rgb(var(--lightning--red-a100-rgb) / 0.1);
    --diff-removed-background-color-dark: rgb(var(--lightning--red-a100-rgb) / 0.2);
    --diff-added-highlight-light: rgb(var(--lightning--green-a100-rgb) / 0.4);
    --diff-added-highlight-dark: rgb(var(--lightning--green-a100-rgb) / 0.3);
    --diff-removed-highlight-light: rgb(var(--lightning--red-a100-rgb) / 0.3);
    --diff-removed-highlight-dark: rgb(var(--lightning--red-a100-rgb) / 0.3);
    --diff-online-svg: var(--lightning--blue-700);
    --diff-add-svg-light: var(--lightning--green-500);
    --diff-add-svg-dark: var(--lightning--green-a100);
    --diff-remove-svg-light: var(--lightning--red-500);
    --diff-remove-svg-dark: var(--lightning--red-a100);
    --diff-unified-background-color: var(--lightning--gray-50);
  }
}

.row {
  display: grid;
  grid-template-columns: minmax(1em, min-content) auto; /* Move to .container once subgrid is supported everywhere */
  grid-column-gap: 0.4em;
  padding: 0 0.35em;
}

.value {
  &:empty::before {
    content: '\200b'; /* unicode zero width space character to prevent empty text row from collapsing (to preserve user's linebreaks) */
  }
}

.changed {
  composes: row;
}

.added {
  composes: row;
  background-color: var(--diff-added-background-color-light);

  &.dark {
    background-color: var(--diff-added-background-color-dark);
  }
}

.removed {
  composes: row;
  background-color: var(--diff-removed-background-color-light);

  &.dark {
    background-color: var(--diff-removed-background-color-dark);
  }
}

.addedHighlight {
  background-color: var(--diff-added-highlight-bg-color-light);
}

.removedHighlight {
  background-color: var(--diff-removed-highlight-bg-color-light);
}

.svg {
  transform: scale(0.75);
}

.online-svg {
  color: var(--diff-online-svg);
  transform: scale(0.6);
}

.add-diff-svg,
.add-svg {
  composes: svg;
  color: var(--diff-add-svg-color-light);
}

.dark {
  .add-diff-svg,
  .add-svg {
    color: var(--diff-add-svg-color-dark);
  }

  .remove-diff-svg,
  .remove-svg {
    color: var(--diff-remove-svg-color-dark);
  }

  .addedHighlight {
    background-color: var(--diff-added-highlight-bg-color-dark);
  }

  .removedHighlight {
    background-color: var(--diff-removed-highlight-bg-color-dark);
  }
}

.remove-diff-svg,
.remove-svg {
  composes: svg;
  color: var(--diff-remove-svg-color-light);
}
