/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
.error {
  text-decoration: underline dashed var(--red-700);
}

.duplicate {
  composes: duplicate from '../IP.css';
}

.total {
  composes: total from '../IP.css';
}

.exclusion {
  composes: exclusion from '../IP.css';
}

.warning {
  composes: warning from '../IP.css';
  text-decoration: underline dashed var(--orange-800);
}
