/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
/* Shadow helpers that IntersectioObserver observes and if hidden adds state to show shadow on header/footer */
.observers {
  position: relative;
  height: 0;
  pointer-events: none;

  > .observerStuck,
  > .observerShadow {
    position: absolute;
    width: 100%;
    height: 1px;
  }

  &.top {
    > .observerStuck {
      top: calc(-1 * var(--modal-sticky-position));
    }

    > .observerShadow {
      top: calc(var(--modal-sticky-shadow) - var(--modal-sticky-position));
    }
  }

  &.bottom {
    > .observerStuck {
      bottom: calc(-1 * var(--modal-sticky-position));
    }

    > .observerShadow {
      bottom: calc(var(--modal-sticky-shadow) - var(--modal-sticky-position));
    }
  }
}

.sticky {
  position: sticky;
  background-color: var(--white);

  &.pseudo {
    height: 0;
    font-size: 0;
    line-height: 0;
    pointer-events: none;

    &::after {
      position: absolute;
      content: ' ';
      width: 100%;
      height: 1px;
    }
  }

  &.animate {
    &:not(.pseudo),
    &.pseudo::after {
      transition: box-shadow 0.1s linear;
    }

    &.shadow:not(.pseudo),
    &.shadow.pseudo::after {
      transition: box-shadow 0.2s ease-out;
    }
  }

  &.top {
    z-index: var(--shadow-above-all-z); /* Put top shadow above the content */
    top: var(--modal-sticky-position);

    &.pseudo::after {
      top: calc(-1 * var(--1px));
    }

    &.shadow:not(.pseudo) {
      box-shadow: var(--shadow-depth-2onlyDown);
    }

    &.shadow.pseudo::after {
      box-shadow: var(--shadow-depth-3onlyDown);
    }
  }

  &.bottom {
    bottom: var(--modal-sticky-position);

    &.pseudo::after {
      bottom: 0; /* Keep it 0 before stuck to prevent overflowing container and showing scrollbar if content fits on the screen */
    }

    &.shadow.pseudo::after {
      top: 100%;
    }

    &.shadow:not(.pseudo) {
      box-shadow: var(--shadow-depth-2onlyUp);
    }

    &.shadow.pseudo::after {
      box-shadow: var(--shadow-depth-3onlyUp);
    }
  }
}
