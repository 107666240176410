/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */

.menu {
  --avatar-width: var(--34px);
  --avatar-height: var(--34px);
  --padding: var(--10px);

  @media (width < 960px) {
    --padding: var(--7px);
  }
}

.menu {
  flex: 0 0 auto;
  margin-left: var(--padding);
}

.trigger {
  padding: 0 var(--padding);
  font-size: var(--16px);
}

.name,
.avatar {
  display: inline-block;
  vertical-align: middle;
}

.avatar {
  display: none;
  justify-content: center;
  align-items: center;
  width: var(--34px);
  height: var(--34px);
  border-radius: 50%;
  background-color: var(--blue-gray-200);
}

.name {
  max-width: 17vw;
  /* margin-left: var(--padding); */ /* Uncomment when we'll be showing real avatar with name */
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.expandIcon-icon {
  margin-left: var(--10px);
  font-size: var(--12px);
}

.organization {
  composes: gapSmall from 'utils.css';
  padding: var(--offset-small) var(--offset-xsmall);
  color: var(--blue-gray-200);

  .title {
    font-size: var(--16px);
  }
}

.username-menu-triggerLabel {
  composes: bold from 'utils.css';
}

.username-menu-activeItem:not(:focus) {
  background: linear-gradient(136.72deg, rgb(0 221 251/0.22) 7.63%, rgb(0 178 255/0.22) 92.65%);
  background-clip: content-box;
}

.username-button {
  @nest html[data-theme='lightning'] & {
    --button-icon-size: var(--20px);
    --button-top-padding: var(--6px);
    --button-bottom-padding: var(--6px);
  }
}

.itemWithIcon-text {
  composes: gapMedium gapHorizontal from 'utils.css';
}

html[data-theme='lightning'] .themeToggle-icon {
  color: var(--lightning--green-700);
}

.themeToggle-svg {
  transform: scale(1.5);
}
