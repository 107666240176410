/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
.result {
  composes: centeredFlex from 'utils.css';
  padding: var(--offset-large) 0;
}

.analysis {
  composes: result;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.analysisColor {
  color: var(--green-700);
}
