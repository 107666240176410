/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.vCenter {
  justify-content: center;
}

/* Highlight removed rows with red */
.rowToRemove .areaBody {
  background-color: var(--red-100);

  @nest html[data-theme='lightning'] & {
    background-color: var(--lightning--red-25);
  }
}

/* Highlight reverted rows with yellow */
.rowToRevert .areaBody {
  background-color: var(--yellow-100);

  @nest html[data-theme='lightning'] & {
    background-color: var(--lightning--orange-50);
  }
}

/* Highlight provisioned/apply policy rows with blue-gray */
.rowToProvision .areaBody,
.rowToApplyPolicy .areaBody {
  background-color: var(--blue-100);

  @nest html[data-theme='lightning'] & {
    background-color: var(--lightning--blue-50);
  }
}

/* Highlight generating rows with blue-gray */
.rowToGenerate .areaBody {
  background-color: var(--blue-gray-100);
}

/* Highlight selected rows with blue-gray */
.rowsSelected .areaBody {
  background-color: var(--blue-gray-150);
}

/* Highlight modified rows with green */
.rowsModified .areaBody {
  background-color: var(--green-50);
}

/* Highlight selected rows with blue-gray */
.rowToDownload .areaBody {
  background-color: var(--blue-200);
}

.rowBodyNotification .areaBody {
  background-color: var(--yellow-50);
}

.rowBodyRemoveNotification .areaBody {
  background-color: var(--red-50);
}

.addRows .areaBody {
  background-color: var(--green-a100);

  @nest html[data-theme='lightning'] & {
    background-color: var(--lightning--green-25);
  }
}

.removeRows .areaBody {
  background-color: var(--red-a100);
}

.modifiedRows .areaBody {
  background-color: var(--blue-50);
}

.rowStatus-icon {
  padding-right: var(--2px);
  padding-left: var(--2px);
}

.status-badge {
  padding: var(--2px) var(--10px);
  line-height: 1.2;

  @nest html[data-theme='lightning'] & {
    padding: var(--4px) var(--8px);
    text-transform: none;
    white-space: nowrap;
  }
}

.status-created {
  @nest html[data-theme='lightning'] & {
    background: var(--lightning--green-50);
  }
}

.status-updated {
  @nest html[data-theme='lightning'] & {
    background: var(--lightning--blue-50);
  }
}

.status-deleted {
  @nest html[data-theme='lightning'] & {
    background: var(--lightning--red-50);
  }
}

.table-minWidth_800 {
  .status-badge {
    margin: var(--4px) 0;
  }

  .areaBody .cell.status {
    padding-top: 0;
    padding-bottom: 0;
  }

  @nest html[data-theme='lightning'] & {
    .areaBody .cell.status {
      padding-top: var(--8px);
    }
  }
}
