/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.inviteMessage {
  padding-top: var(--10px);
}

.inviteLink {
  margin-top: var(--10px);
  padding: var(--6px);
  background: var(--blue-50);
}

.invited-icon {
  color: var(--blue-800);
}

.locked-icon {
  color: var(--red-700);
}

.lockSuccessMessage {
  text-overflow: ellipsis;
  overflow: hidden;
}

.lockSuccess {
  color: var(--blue-700);
}

.userActivityDetails {
  margin: var(--20px) 0 var(--40px);
}

.userActivityStatus {
  padding-left: var(--7px);
}

.scopeAndRoles {
  composes: bold from 'utils.css';
  padding-top: var(--5px);
  font-size: var(--16px);
}

.details-list {
  margin-top: 0;
  margin-bottom: var(--10px);
}

.editModal {
  width: 47rem;
}

.table {
  width: 100%;

  & th {
    padding: var(--8px);
    text-align: left;
    border-bottom: 1px solid #dddf;
  }

  & td {
    padding: var(--8px);
    text-align: left;
  }
}
