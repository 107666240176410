/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
.picker-list {
  margin-bottom: var(--10px);
}

.pickerCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--40px);
  height: var(--40px);
  color: var(--white);
  border-radius: 50%;
  background: var(--blue-800);
}

.pickerLabel {
  display: flex;
  flex-direction: row;
}

.pickerLabelText {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: calc(2 * var(--100px));
  padding-left: var(--20px);
  font-size: var(--18px);
}
