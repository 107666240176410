/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.table-minWidth_960 {
  .areaHead .cell {
    white-space: nowrap;
  }
}

.icon-spinner {
  width: var(--25px);
  height: var(--25px);
  border-width: var(--4px);
}

.downloadText {
  margin-left: var(--10px);
}

.deleted {
  color: var(--red-700);
}
