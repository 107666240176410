/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.menu {
  --menu-width: calc(4 * var(--70px));
  --menu-width-collapsed: var(--30px);
  --item-height: var(--32px);
  --item-padding-left: var(--8px);
  --item-border-left: var(--4px);

  position: relative;
  z-index: 103;
  width: var(--menu-width);
  min-width: var(--menu-width);
  min-height: 100%;
  box-shadow: var(--shadow-depth-6);
  background-color: var(--blue-gray-150);
  transition: none 300ms ease;
  transition-delay: -100ms;
  transition-property: width, min-width;

  &:focus {
    outline: none;
  }

  &:focus-visible:not(:active) {
    /* Add custom focus outline */
    box-shadow: 0 0 3px 3px rgb(var(--blue-400-rgb) / 0.5);
  }
}

.collapsed {
  width: var(--menu-width-collapsed);
  min-width: var(--menu-width-collapsed);
  box-shadow: none;
}

.hideContent {
  display: none;
}

.header-toolBar {
  composes: bold from 'utils.css';
  position: relative;
  min-width: max-content;
  height: var(--item-height);
  padding-left: calc(var(--item-padding-left) + var(--item-border-left));
  color: var(--blue-gray-700);
}

.caret {
  .icon {
    display: inline-block;
    margin-right: var(--10px);
    font-size: var(--12px);
    cursor: pointer;
  }

  .icon:hover {
    color: var(--blue-gray-400);
  }
}

.openCaret {
  composes: caret;
  .icon {
    transform: rotate(90deg);
  }
}

.closedCaret {
  composes: caret;
  .icon {
    transform: rotate(-90deg);
  }
}

.container {
  border-top: 1px solid var(--blue-gray-300);
}

.container:last-child {
  border-bottom: 1px solid var(--blue-gray-300);
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: var(--item-height);
  cursor: pointer;

  &:focus {
    outline: none;
    background-color: var(--blue-gray-200);
  }
}

.itemText {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.selected {
  color: var(--white);
  pointer-events: none;
  background-color: var(--blue-gray-400);
}

.category {
  composes: item;
  padding-left: var(--item-padding-left);
  border-left: var(--item-border-left) solid transparent;

  .icon {
    margin-right: var(--10px);
    font-size: var(--12px);
  }
}

.active {
  border-left-color: var(--blue-400);
}

.subcategory {
  composes: item;
  padding-left: var(--20px);
}

.number {
  padding-right: var(--10px);
}

.stickyMenu {
  position: sticky;
  top: var(--header-height);
}

.menuScroller {
  max-height: calc(100vh - var(--header-height) - 2 * var(--grid-manager-height));
  overflow-y: auto;
}
