/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */

.content {
  font-size: var(--13px);
  font-weight: var(--fontWeight);
  color: var(--gray-550);
}

.copyButtonWrapper {
  display: flex;
  justify-content: space-between;
  background: var(--blue-50);
}

.copyButtonText {
  padding: var(--5px) var(--6px);
  line-height: var(--20px);
  word-break: break-all;
}

.ransomwareReadiness {
  display: flex;
  align-items: center;
}

.ransomwareExposureStatus {
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-right: var(--6px);
  border-radius: 50%;
}

.fully_protected {
  background-color: var(--green-500);

  @nest html[data-theme='lightning'] & {
    background-color: var(--lightning--green-400);
  }
}

.critical {
  background-color: var(--red-400);

  @nest html[data-theme='lightning'] & {
    background-color: var(--lightning--orange-500);
  }
}

.high {
  background-color: var(--orange-400);

  @nest html[data-theme='lightning'] & {
    background-color: var(--lightning--blue-800);
  }
}

.medium {
  background-color: var(--blue-a200);

  @nest html[data-theme='lightning'] & {
    background-color: var(--lightning--blue-500);
  }
}

.low {
  background-color: var(--blue-100);

  @nest html[data-theme='lightning'] & {
    background-color: var(--lightning--blue-100);
  }
}
