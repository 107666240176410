/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */

.container {
  --albatrossBreakpoint: calc(var(--100px) * 8);

  composes: gapLarge gapAlbatross from 'utils.css';
  flex-direction: row-reverse;
  align-items: flex-start;
}

.editor-list {
  max-height: calc(100vh - var(--header-height));
  padding: var(--5px);
  overflow: auto;
  background-color: var(--deep-purple-50);

  @media (width >= 785px) {
    position: sticky;
    top: var(--header-height);
  }
}

.keyBlock {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
}

.note {
  font-size: 90%;
  color: var(--gray-500);
  text-align: left;
}
