/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */

.card {
  padding: 0 var(--10px);
}

.ldapOn {
  composes: bold from 'utils.css';
  color: var(--green-800);
}

.ldapOff {
  composes: bold from 'utils.css';
  color: var(--red-700);
}

.areaBody .cell.actions {
  display: flex;
  justify-content: flex-end;

  > *:not(:last-child) {
    margin-right: var(--5px);
  }
}

.addLDAPConfig {
  display: flex;
  flex-wrap: wrap;
  margin: var(--10px) 0;

  @media (width >= 479px) {
    align-items: center;
  }
}

.button {
  margin-right: var(--10px);
}

.error {
  composes: error from '../View/AuthenticationSettingsLDAPView.css';
}

.addLDAPConfigHelpText {
  margin-left: var(--10px);
}
