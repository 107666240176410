/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
:root {
  --map-parallel-coords-link-color: var(--blue-300);
  --map-parallel-coords-axis-color: var(--black);
  --map-parallel-coords-axis-hover-color: var(--orange-500);
  --map-parallel-coords-tick-hover-color: var(--green-500);
}

.renderContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.container {
  width: 100%;
  height: 100%;
}

.svg {
  width: 100%;
  height: 100%;
}

.svg text {
  text-shadow: 0 1px 0 var(--white), 1px 0 0 var(--white), 0 -1px 0 var(--white), -1px 0 0 var(--white);
}

.links path {
  fill: none;
  stroke: var(--map-parallel-coords-link-color);
}

.tick {
  color: var(--map-parallel-coords-axis-color);
}

.tick:hover {
  color: var(--map-parallel-coords-tick-hover-color);
}

.axis {
  color: var(--map-parallel-coords-axis-color);
}

.axisTitle {
  cursor: grab;
  fill: var(--map-parallel-coords-axis-color);
  text-anchor: middle;
}

.axis:hover {
  color: var(--map-parallel-coords-axis-hover-color);
}

.informationBar {
  composes: gapXSmall gapHorizontal from 'utils.css';
  justify-content: space-between;
  align-items: flex-start;
  padding: var(--offset-xsmall);
}
