/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

@keyframes appear {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.provisionProgress {
  position: fixed;
  display: flex;
  z-index: var(--shadow-depth-3z);
  justify-content: center;
  align-items: center;
  right: var(--20px);
  bottom: var(--20px);
  width: calc(2.6 * var(--100px));
  min-height: calc(6.4 * var(--10px));
  padding: var(--14px);
  color: var(--white);
  opacity: 1;
  cursor: pointer;
  border-radius: var(--2px);
  box-shadow: inset 0 0 var(--1px) rgb(var(--black-rgb) / 0.3);
  background-color: var(--blue-gray-700);
  /* Transition to 'complete' state */
  transition: background-color 0.2s ease;
  /* Animation on first render */
  animation: appear 0.2s ease 1 alternate;

  &:hover {
    background-color: var(--blue-gray-600);
  }
}

.provisionProgressComplete {
  justify-content: flex-start;
  background-color: var(--green-700);

  &:hover {
    background-color: var(--green-600);
  }

  .provisionProgressContent {
    flex-direction: row;
  }
}

.provisionProgressContent {
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  font-size: var(--15px);
  font-weight: 600; /* stylelint-disable-line */
}

.progressBar {
  margin: var(--6px) 0;
  font-size: var(--15px);
}

.provisionProgressContentNoBar {
  padding-right: var(--20px);
  text-align: center;
}

.close-icon {
  position: absolute;
  top: var(--8px);
  right: var(--8px);
  font-size: var(--10px);
}

.close-svg {
  color: var(--white);
  cursor: pointer;
}

.complete-icon {
  margin: 0 var(--16px) 0 var(--2px);
}

.complete-svg {
  width: var(--30px);
  height: var(--20px);
  color: var(--white);
}
