/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

:root {
  --map-pagination-page-current-button-background-color: var(--blue-gray-300);
  --map-pagination-page-current-button-color: var(--white);
  --map-pagination-container-border-color: var(--gray-100);
}

.container {
  display: flex;
  align-items: center;
  padding: 0 var(--6px);
  border-right: var(--1px) solid var(--map-pagination-container-border-color);
  border-left: var(--1px) solid var(--map-pagination-container-border-color);

  @nest html[data-theme='lightning'] & {
    --map-pagination-page-current-button-background-color: var(--lightning--blue-400);

    border-right: none;
    border-left: none;
  }
}

.blocks {
  display: flex;
  flex: 0 0 auto;
  flex-wrap: nowrap;
  align-items: stretch;
  gap: var(--2px);
}

.block {
  /* for blocks without a button, style the block */
  display: flex;
  border-radius: var(--2px);

  /* for blocks with a button, style the button inside the block */
  .button {
    display: flex;
    padding: var(--2px) var(--8px);
    color: var(--map-toolbar-button-color);
    border-radius: var(--2px);
  }

  &.current {
    background-color: var(--map-pagination-page-current-button-background-color);

    .button {
      color: var(--white);
      cursor: default;
      border-radius: var(--2px);

      &:hover {
        border: none;
        background-color: var(--map-pagination-page-current-button-background-color);
      }
    }
  }

  &.disabled:not(.current) {
    .button:hover {
      background-color: transparent;
    }
  }
}

.pageBlock .button {
  position: relative;
}

.pageBlock.current .pageNumber {
  position: relative;
  z-index: 1; /* currentPage needs z-index to sit on top of the button's progress indicator */
}

.nextPrevBlock .icon {
  font-size: var(--8px);
}

.ellipsisBlock {
  padding: 0 var(--4px);
}

.asteriskBlock {
  width: auto;
  padding: 0 var(--6px);
  color: var(--blue-600);

  &:hover {
    color: var(--blue-800);
    cursor: pointer;
  }
}
