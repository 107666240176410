/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
.ipMain {
  display: grid; /* Don't use flex here for Chrome 88, it causes cursor issue for new line */
  grid-template-columns: auto auto 1fr;
}

.lineNumberColumn {
  position: relative;
  cursor: pointer;

  &::after,
  &.lineNumberExtend::before {
    /* Use mono fonts to make sure line numbers with the same figure have the same pixel length for perfect alignment */
    font-family: SFMono-Regular, Consolas, 'Liberation Mono', Menlo, monospace;
  }

  &::after {
    content: attr(data-line-number);
    color: var(--gray-300);
  }

  &.lineNumberExtend::before {
    content: '0'; /* Prepend one-fugure numbers (1-9) with invisible zero, to align them with two-figure numbers (10-99) */
  }

  &.lineNumberHide::after,
  &.lineNumberExtend::before {
    visibility: hidden;
  }
}

.delete-icon {
  position: absolute;
  inset: 0;
  text-align: center;
}

.modified-icon {
  display: flex;
  align-self: stretch;
  align-items: center;
  padding-left: var(--5px);
  font-size: 0.5em;
  color: var(--blue-700);
  cursor: default;
}

/* Spacing icon is used to align the space. Setting opacity */
.notmodified-icon {
  composes: modified-icon;
  opacity: 0;
}

.modified-svg {
  vertical-align: baseline;
}
