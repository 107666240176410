/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */
/** Port Status icon */
.port-inactive-icon {
  color: var(--gray-200);
}

.port-listening-icon {
  color: var(--yellow-500);
}

/** Protection Status icon */
.protection-unprotected-icon {
  color: var(--red-700);
}

.protection-open-icon,
.protection-closed-icon {
  color: var(--green-800);
}

/** Protected Ports info tally */
.info {
  --tally-text-color: var(--green-800);
  --tally-border-color: var(--green-100);
  --tally-background-color: var(--green-50);

  @nest html[data-theme='lightning'] & {
    --tally-text-color: var(--lightning--green-700);
    --tally-border-color: var(--lightning--green-300);
    --tally-background-color: var(--lightning--green-50);
    --tally-background-color-hover: var(--lightning--green-25);
    --tally-background-color-active: var(--lightning--green-100);
    --tally-background-color-focus: var(--lightning--green-50);
    --tally-outline-color-focus: var(--lightning--green-500);
  }
}
