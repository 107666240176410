/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */

.table-minWidth_960 {
  .areaHead .cell {
    white-space: nowrap;
  }
}

.usageHeader {
  composes: cell from 'components/Grid/Grid.css';
  font-size: var(--12px);
  color: var(--gray-600);
}
