/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
.timeSelector {
  padding: var(--4px) var(--8px);
}

.customDatePicker {
  position: absolute;
  z-index: 125;
  top: var(--0px);
  left: var(--100px);

  @nest html[data-theme='lightning'] & {
    top: var(--40px);
    left: var(--50px);
  }
}

.dropdownButton-menu-dropdownWithArrow::before {
  display: none;
  margin-top: var(--2px);
}

.customRangeLabel {
  display: flex;
  flex-wrap: wrap;
}

.customRangeTime {
  white-space: nowrap;
}

.customRangeLabel .customRangeTime:first-of-type {
  margin-right: var(--8px);
}
