/**
 * Copyright 2021 Illumio, Inc. All Rights Reserved.
 */

.text {
  composes: semiBold from 'utils.css';
  font-size: var(--18px);
}

.secondaryText {
  composes: light from 'utils.css';
  font-style: italic;
  text-align: right;
}

.handle {
  display: flex;
  align-items: center;
  /* set min-height so drawer handle doesnt "jump" when opening & closing due to height change */
  min-height: var(--26px);
  color: var(--gray-700);
  cursor: pointer;
  user-select: none;

  &:focus {
    outline: none;
  }

  &:focus-visible {
    box-shadow: 0 0 0 2px rgb(var(--blue-400-rgb) / 0.5);
  }
}

.innerHandle {
  composes: gapLarge gapHorizontal from 'utils.css';
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
}
