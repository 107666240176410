/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */
.container {
  position: fixed;
  width: 100%;
  height: 100%;
  padding-bottom: var(--20px);
  overflow-y: scroll;
}
