/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */
.barWithoutHoverHighlight {
  cursor: default;
  outline: none;
  fill: var(--blue-800);
}

.bar {
  composes: barWithoutHoverHighlight;

  &:hover {
    opacity: 0.8;
  }
}

.xAxis,
.yAxis {
  font-size: var(--12px);
  color: var(--gray-500);
}
