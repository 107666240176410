/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */

.more {
  composes: link from 'components/Link/Link.css';
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.expandableList {
  composes: gapXSmall gapHorizontalWrap from 'utils.css';
}
