/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */
.areaHead {
  .target-customCellHeader,
  .targetService-customCellHeader,
  .targetLabels-customCellHeader,
  .targetSummary-customCellHeader {
    flex: 1 1 auto;
    color: var(--blue-gray-800);
    background-color: var(--indigo-200);

    &:hover {
      background-color: var(--indigo-300);
    }
  }
}
