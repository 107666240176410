/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
:root {
  --tabpanel-height-primary: var(--30px); /* height is line-height + padding + border should be 30 */
  --tabpanel-height-secondary: var(--34px); /* height is line-height + padding + border should be 34 */
  --tabpanel-border-color: var(--blue-gray-200);
  --tabpanel-background-color: var(--white);
  --tab-focus-color: rgb(var(--blue-500-rgb) / 0.5);
}

.tabPanel {
  display: flex;
  height: var(--tabpanel-height, auto);
  overflow: hidden;
  background-color: var(--tabpanel-background-color);
}

.primary {
  --tabpanel-height: var(--tabpanel-height-primary);

  composes: tabPanel;
}

.secondary {
  --tabpanel-height: var(--tabpanel-height-secondary);

  composes: tabPanel;
}

.pill {
  composes: tabPanel;
  height: var(--28px);
}

.outerTabPanel {
  position: relative; /* To calculate offset of children */
  display: flex;
  flex: 1 1 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none; /* Standard property to remove scrollbar. Only Firefox supports it at the time of writing this */
  scroll-behavior: smooth; /* To animate scroll on click */
  /* Prevent page navigation once a scroller has reached its full extent */
  /* Works Firefox 59+, Chrome 65+ */
  /* If not supported, mousewheel event is used in preventHistoryChangeOnScroll function in dom.js */
  overscroll-behavior-x: none;
  background-color: var(--tabpanel-background-color);

  &.hidden {
    visibility: hidden;
    scroll-behavior: auto; /* To make scroll on first appearance instantly, without animation */

    .underline {
      transition: none; /* To prevent underline animation on first appearance */
    }
  }

  /* Webkit specific property to remove scrollbar. Remove after browser starts supporting scrollbar-width */
  &::-webkit-scrollbar {
    display: none;
  }
}

.extraBottomBorder {
  flex: 1 1 auto;
  border-bottom: var(--1px) solid var(--tabpanel-border-color);
}

.extraBottomBorderLeft {
  width: var(--10px);
  height: var(--33px);
  border-bottom: var(--1px) solid var(--tabpanel-border-color);
}

.arrow {
  position: relative;
  display: flex;
  z-index: 1;
  align-self: stretch;
  width: var(--24px);
  cursor: pointer;
  transition: color 0.1s linear;

  &:hover {
    color: var(--blue-gray-300);
  }

  &:active {
    color: var(--blue-gray-600);
  }

  &::before {
    position: absolute;
    z-index: -1;
    content: '';
    top: 15%;
    width: 50%;
    height: 70%;
    border-radius: 100%;
    transition: box-shadow 0.1s linear;
  }
}

.arrowLeft {
  composes: arrow;

  &:not(.disabled)::before {
    right: 0;
    box-shadow: 1px 0 7px rgb(var(--black-rgb) / 0.5), 0 0 20px rgb(var(--black-rgb) / 0.2);
  }
}

.arrowRight {
  composes: arrow;

  &:not(.disabled)::before {
    left: 0;
    box-shadow: -1px 0 7px rgb(var(--black-rgb) / 0.5), 0 0 20px rgb(var(--black-rgb) / 0.2);
  }
}

.arrow-icon {
  display: flex;
  flex: 0 0 100%;
  justify-content: center;
  align-items: center;
  background-color: var(--tabpanel-background-color);
}

.innerTabPanel {
  display: flex;
  flex-wrap: nowrap;
  background-color: var(--tabpanel-background-color);
}

.pillInnerTabPanel {
  composes: gapXSmall gapHorizontal from 'utils.css';
  background-color: var(--white);
}

/* Each Tab is a Link with custom style */
.link {
  composes: semiBold from 'utils.css';
  position: relative;
  line-height: var(--20px);
  color: var(--gray-700);
  white-space: nowrap;
  user-select: none; /* To make not selectable on click/doubleclick on windows */

  &:focus {
    outline: none;
  }

  &:hover:not(.active) {
    color: var(--blue-400);
  }

  &.active {
    color: var(--blue-700);
  }
}

.disabled {
  color: var(--gray-300);
  pointer-events: none;
}

.primary .link {
  padding: 0 var(--20px) var(--10px);
  font-size: var(--15px);
  box-shadow: inset 0 -1px 0 0 var(--tabpanel-border-color);

  &:focus-visible {
    box-shadow: inset 0 0 0 2px var(--tab-focus-color);
  }
}

.secondary .link {
  padding: var(--7px) var(--11px) var(--6px);
  border-bottom: var(--1px) solid var(--tabpanel-border-color);

  &:hover {
    background: var(--blue-gray-100);
  }

  &.active {
    padding: var(--6px) var(--10px) var(--7px);
    border-top: var(--1px) solid var(--tabpanel-border-color);
    border-right: var(--1px) solid var(--tabpanel-border-color);
    border-bottom: none;
    border-left: var(--1px) solid var(--tabpanel-border-color);
  }

  &:focus-visible {
    padding: var(--4px) var(--8px) var(--5px);
    border: var(--3px) solid var(--tab-focus-color);
  }
}

.pill .link {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 var(--15px);
  font-weight: var(--fontWeightLight);
  border: var(--1px) solid var(--blue-gray-150);
  border-radius: var(--15px);
  background-color: var(--blue-gray-50);

  /* same hover effect as labels */
  &:hover:not(.active) {
    color: var(--blue-850);
    border-color: var(--blue-500);
    background-color: var(--blue-100);
  }

  &.active {
    --tab-focus-color: var(--blue-200);

    color: white;
    border-color: transparent;
    background-color: var(--blue-600);
  }

  &:focus-visible {
    box-shadow: inset 0 0 0 var(--2px) var(--tab-focus-color);
  }
}

.icon {
  position: absolute;
  top: calc(-1 * var(--4px));
  margin-left: var(--2px);
  font-size: var(--10px);
}

.error {
  composes: icon;
  color: var(--red-700);
}

.warning {
  composes: icon;
  color: var(--orange-700);
}

.counter {
  position: absolute;
  top: 0;
  right: unset;
  left: calc(100% - var(--16px));
  font-size: var(--10px);
}

/* Sliding underline */
.underline {
  position: absolute;
  bottom: 0;
  left: 0;
  width: var(--1px);
  height: var(--3px);
  background-color: var(--blue-600);
  transform: translateX(0) scaleX(0);
  will-change: transform, width;
  transition: transform 0.25s ease-out, width 0.25s ease-out;
}

/* Temporarily turn off underline animation in Safari 12.1 to prevent lag when switch the tab */
/* TODO: check next version of safari */
@media not all and (resolution >= 0.001dpcm) {
  @media {
    .underline {
      transition: none;
    }
  }
}

/* To sync color change with underline animation */
.primary .link {
  will-change: color;
  transition: color 0.15s ease-out;
}
