/**
 * Copyright 2022 Illumio, Inc. All Rights Reserved.
 */

/** Layout **/
.landing {
  composes: containerWidth from 'utils.css';
  flex: 1 0 auto;
  align-items: center;
  margin-top: calc(var(--offset-medium) * -1);
  margin-bottom: calc(var(--offset-medium) * -2);
}

.tilesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: calc(var(--offset-large) * 5);
}

.svg {
  width: 100%;
  height: 100%;
}

.resultsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin: calc(var(--offset-large) * 5);
}

.instructions {
  composes: bold from 'utils.css';
  font-size: var(--16px);
  color: var(--lightning--blue-800);
}

.tileLayout {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(var(--70px), 1fr));
  grid-auto-rows: 1fr;
  justify-content: center;
  max-width: calc(var(--100px) * 8);
  margin: auto;
  padding: var(--20px);
}

/** Tile Styling **/
.tile {
  --card-background-color: --lightning--blue-gray-25;

  width: calc(10 * var(--55px));
  height: calc(10 * var(--25px));
  max-height: calc(var(--100px) * 6);
  padding: 0;
  border: 0.5px solid var(--gray-200);
  border-radius: var(--20px) var(--20px) 0 0;
  background-color: var(--lightning--blue-gray-25);
}

.tileImage {
  --card-background-color: --lightning--blue-gray-25;

  width: 100%;
  height: 100%;
  border-radius: var(--20px) var(--20px) 0 0;
  background-color: var(--lightning--blue-gray-25);
}

.tileDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(var(--70px) * 2);
  padding-top: var(--20px);
  font-size: var(--13px);
  text-overflow: ellipsis;
  border: solid 1px var(--lightning--blue-gray-200);
  border-radius: 0 0 var(--20px) var(--20px);
  background-color: white;
}
