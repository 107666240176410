/**
 * Copyright 2020 Illumio, Inc. All Rights Reserved.
 */

.ellipsis {
  position: relative;
  display: inline-block;
  width: var(--80px);
  height: var(--80px);
  margin: auto;
}

.ellipsis div {
  position: absolute;
  top: var(--33px);
  width: var(--13px);
  height: var(--13px);
  border-radius: 50%;
  background: var(--gray-200);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.ellipsis div:nth-child(1) {
  left: var(--8px);
  animation: ellipsis1 0.6s infinite;
}

.ellipsis div:nth-child(2) {
  left: var(--8px);
  animation: ellipsis2 0.6s infinite;
}

.ellipsis div:nth-child(3) {
  left: var(--32px);
  animation: ellipsis2 0.6s infinite;
}

.ellipsis div:nth-child(4) {
  left: var(--56px);
  animation: ellipsis3 0.6s infinite;
}

@keyframes ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(var(--24px), 0);
  }
}
