/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */

:root {
  --card-padding: var(--offset-medium);
  --card-border-radius: var(--2px);
}

.card {
  position: relative;
  padding: var(--card-padding);
  border: var(--1px) solid transparent;
  border-radius: var(--card-border-radius);
  background-color: var(--card-background-color, inherit);
}

.primary {
  --card-background-color: var(--gray-50);

  composes: card;
}

.secondary {
  --card-background-color: var(--white);

  composes: card;
}

.withBorder {
  border: var(--1px) solid var(--gray-100);
}

.withCloseIcon {
  padding-right: calc(var(--card-padding) + 1em);
}

.close-icon {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: calc(var(--card-padding) / 2);
  padding: calc(var(--card-padding) / 2);
  cursor: pointer;
  user-select: none;

  &:not(:hover) {
    color: var(--gray-500);
  }

  .close-svg {
    transform: scale(0.75);
  }

  @nest html[data-theme='lightning'] & {
    right: var(--10px);

    &:not(:hover) {
      color: var(--lightning--blue-gray-500);
    }

    .close-svg {
      transform: scale(1);
    }
  }
}

/* Utility class to make item 100% of the card width, expanding beyond paddings */
.cardWidth {
  width: calc(100% + 2 * var(--card-padding));
  margin-left: calc(-1 * var(--card-padding));
}

/* Utility class to make item 100% of the card width, plus overlapping borders */
.cardWidthBordersOverlap {
  width: calc(100% + var(--card-padding) * 2 + var(--2px));
  margin-left: calc(-1 * var(--card-padding) - var(--1px));
}
