/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */

:root {
  --help-header-background-color: var(--blue-gray-700);
}

.draggingArea {
  --help-popup-width: 360px;
  --help-popup-height: 600px;
  --help-draggingArea-header-offset: 2px;
  --help-draggingArea-bottom-offset: 5px;
  --help-draggingArea-horizontal-offset: 15px;

  @media (height <= 657px) {
    --help-draggingArea-header-offset: 6px;
    --help-draggingArea-bottom-offset: 3px;
    --help-popup-height: calc(
      100vh - var(--header-height) - var(--help-draggingArea-header-offset) - var(--help-draggingArea-bottom-offset)
    );
  }
}

.draggingArea {
  position: fixed;
  z-index: var(--shadow-depth-3z); /* Make it below global sticky Header, to allow header menu to cover helppopup */
  inset: calc(var(--header-height) + var(--help-draggingArea-header-offset)) var(--help-draggingArea-horizontal-offset)
    var(--help-draggingArea-bottom-offset) var(--help-draggingArea-horizontal-offset);
  pointer-events: none;
}

.draggable {
  position: absolute;
  display: flex;
  right: 0; /* Help should be in right bottom corner on open */
  bottom: 0;
  width: var(--help-popup-width);
  height: var(--help-popup-height);
  overflow: hidden;
  pointer-events: auto;
  border-radius: var(--2px);
  box-shadow: var(--1px) var(--2px) var(--1px) var(--2px) rgb(var(--black-rgb) / 0.25);
}

.body {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: var(--white);

  /* When helppopup is in separate poppedout window, it should take height of window to make cards scrollable but title/footer fixed */
  &.standalone {
    position: fixed;
    height: 100%;
  }
}

/* Title section */
.titlebar {
  display: flex;
  z-index: var(--shadow-depth-3z);
  flex: 0 0 var(--40px);
  align-items: stretch;
  color: var(--white);
  user-select: none;
  background-color: var(--help-header-background-color);
  transition: var(--shadow-depth-transition-out);
}

.titlebarShadow {
  box-shadow: var(--shadow-depth-3);
}

.grab {
  display: flex;
  align-items: center;
  width: var(--20px);
  color: var(--blue-gray-400);
}

.grab-icon {
  transform: translateX(var(--2px)) scale(0.75);
}

.title {
  composes: bold from 'utils.css';
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  padding: 0 var(--10px) 0 0;
  font-size: var(--18px);
}

.draggable .title {
  cursor: move;
}

.icon {
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-start;
  align-items: center;
  padding: 0 var(--10px);
  font-size: var(--12px);
  cursor: pointer;
}

.expand {
  composes: icon;
}

.collapse {
  composes: icon;
}

.close {
  composes: icon;
}

/* Content section */
.cards {
  flex: 1 1 auto;
  padding: 0 var(--10px);
  overflow-x: hidden; /* Cards are not scrollable horizontally, but hide it anyway to avoid placeholder if user's OS always show scroll */
  overflow-y: scroll; /* Cards should be vertically scrollable by itself, title and footer are fixed  */
  overscroll-behavior-y: none; /* Prevent page scrolling on cards scrolling */
}

.card {
  padding-left: var(--10px);

  &:not(:last-of-type) {
    border-bottom: var(--1px) solid var(--blue-gray-100);
  }

  &:first-child .cardTitle {
    padding-top: var(--10px); /* "16px" if consider line heigh */
  }
}

.cardTitle {
  composes: bold from 'utils.css';
  padding: var(--4px) 0 0; /* "10px" if consider line heigh */
  font-size: var(--16px);
  color: var(--orange-600);
}

.cardSubtitle {
  padding-right: var(--10px);
  color: var(--gray-800);
}

.customCardSubtitle {
  composes: cardSubtitle;
  padding-bottom: var(--10px);
}

.cardLink {
  padding: var(--1px) 0 var(--4px);
  color: var(--blue-800);
  text-align: right;
}

.cardLink-link {
  color: inherit;
}

.cardLink-icon {
  margin-left: var(--5px);
  font-size: var(--11px);
}

/* Footer section */
.footer {
  display: flex;
  flex: 0 0 var(--40px);
  align-items: stretch;
  padding-left: var(--15px);
  user-select: none;
  background-color: var(--blue-gray-50);
  transition: var(--shadow-depth-transition-out);
}

.footerShadow {
  box-shadow: var(--shadow-depth-2up);
}

.footer-link {
  composes: bold from 'utils.css';
  display: flex;
  align-items: center;
  padding: 0 var(--5px);
  font-size: var(--16px);
  color: var(--blue-800);
}

.footer-icon {
  margin-left: var(--10px);
  font-size: var(--13px);
}
